import React from 'react';
import classes from './LoadingAnimation.module.scss';

const LoadingAnimation = () => (
  <div className={classes.loadingContainer}>
    <div className={classes.circle} />
    <div className={classes.circle} />
  </div>
);

LoadingAnimation.propTypes = {};

export default LoadingAnimation;
