export const BOOKMARK_SCROLL_POSITION = 'app/BOOKMARK_SCROLL_POSITION';
export const SELECT_CONVERSATION_ID = 'app/SELECT_CONVERSATION_ID';
export const SET_CHAT_MODE = 'app/SET_CHAT_MODE';
export const CHAT_SEARCH_FORM = 'chatSearch';
export const CHAT_SEARCH_FORM_FOCUSED = 'CHAT_SEARCH_FORM_FOCUSED';
export const CHAT_MESSAGE_RECEIVED = 'CHAT_MESSAGE_RECEIVED';
export const SET_OVERLAY_ICON = 'SET_OVERLAY_ICON';
export const DESKTOP_CONVERSATIONS_READ = 'DESKTOP_CONVERSATIONS_READ';
export const DEF_CONVERSATION_MODE = 'new';
export const DELETE_CHAT_MODAL = 'DELETE_CHAT_MODAL';
