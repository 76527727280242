import { lsGet, LsKey, lsSet } from 'utils/localStorageUtils';
import { bgOptType } from 'reducers/vbbSettingsSlice';

export function readFromLocalStorage() {
  const bgArray = lsGet(LsKey.VbbImages) || '[]';
  const bgDefault = lsGet(LsKey.VbbDefault) || '';
  return {
    [LsKey.VbbDefault]: bgDefault,
    [LsKey.VbbImages]: JSON.parse(bgArray)
  };
}

export function writeToLocalStorage(valuesToSave: { [LsKey.VbbDefault]?: string; [LsKey.VbbImages]?: bgOptType[] }) {
  Object.keys(valuesToSave).forEach((valueKey) => {
    lsSet(
      LsKey[valueKey],
      LsKey[valueKey] === LsKey.VbbDefault ? valuesToSave[valueKey] : JSON.stringify(valuesToSave[valueKey])
    );
  });
}
