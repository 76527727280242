import {
  CONTACTS_FILTER_CHANGE,
  SET_DELETING_STATE,
  SET_DELETING_ERROR,
  CONTACTS_FILTER_OPTION_ALL,
  MEETINGS_FILTER_OPTION_ALL,
  SET_LOADING_MEETINGS,
  SET_LOADING_ROOMS,
  SET_LOADING_USERS,
  SET_INITIAL_ROOMS_LOADED,
  SET_INITIAL_USERS_LOADED,
  SET_INITIAL_MEETINGS_LOADED
} from 'constants/Contact';
import { nucleusConstants } from '@lifesize/nucleus';
import _get from 'lodash/get';

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [SET_INITIAL_ROOMS_LOADED]: (state, action) => ({
    ...state,
    initialRoomsLoaded: action.payload
  }),

  [SET_INITIAL_USERS_LOADED]: (state, action) => ({
    ...state,
    initialUsersLoaded: action.payload
  }),

  [SET_INITIAL_MEETINGS_LOADED]: (state, action) => ({
    ...state,
    initialMeetingsLoaded: action.payload
  }),

  [SET_LOADING_USERS]: (state, action) => ({
    ...state,
    loadingUsers: action.payload
  }),

  [SET_LOADING_ROOMS]: (state, action) => ({
    ...state,
    loadingRooms: action.payload
  }),

  [SET_LOADING_MEETINGS]: (state, action) => ({
    ...state,
    loadingMeetings: action.payload
  }),

  // Clears the selected contact when the route changes
  [CONTACTS_FILTER_CHANGE]: (state, action) => {
    /* eslint-disable no-case-declarations */
    switch (action.payload.type) {
      case 'contacts':
      case 'meetings':
      case 'roomSystems':
        const nextState = Object.assign({}, state);
        nextState.filters[action.payload.type] = action.payload.value;
        return nextState;
      default:
        return state;
    }
    /* eslint-enable no-case-declarations */
  },

  [SET_DELETING_STATE]: (state, action) => {
    const nextState = Object.assign({}, state);
    nextState.deletingContact = action.payload;
    nextState.deletingError = null;
    nextState.selectedContact = null;
    return nextState;
  },

  [SET_DELETING_ERROR]: (state, action) => {
    const nextState = Object.assign({}, state);
    nextState.deletingContact = false;
    nextState.deletingError = _get(action, 'payload.reason') || true;
    return nextState;
  },

  [nucleusConstants.SAVE_CONTACT_BEGIN]: (state) => {
    const nextState = Object.assign({}, state);
    nextState.savingContact = true;
    return nextState;
  },

  [nucleusConstants.SAVE_CONTACT_SUCCESS]: (state) => {
    const nextState = Object.assign({}, state);
    nextState.savingContact = false;
    return nextState;
  },

  [nucleusConstants.SAVE_CONTACT_ERROR]: (state) => {
    const nextState = Object.assign({}, state);
    nextState.savingContact = false;
    return nextState;
  }
};

// ------------------------------------
// Reducer
// ------------------------------------

const initialState = {
  deletingContact: false,
  deletingError: null,
  filters: {
    contacts: CONTACTS_FILTER_OPTION_ALL,
    meetings: MEETINGS_FILTER_OPTION_ALL
  },
  savingContact: false,
  scrollToSelected: false,
  selectedContact: null,
  selectedContactId: null,
  loadingMeetings: false,
  loadingRooms: false,
  loadingUsers: false,
  initialRoomsLoaded: false,
  initialMeetingsLoaded: false,
  initialUsersLoaded: false
};

export default function contactsReducer(state = initialState, action = {}) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
