import { nucleusConstants } from '@lifesize/nucleus';
import {
  TRACK_AUDIO_CALL,
  TRACK_VIDEO_CALL,
  AUDIO_UNMUTE_BY_USER,
  WEB_CONFERENCE_DISABLED,
  WEB_CONFERENCE_ENABLED
} from 'constants/Call';

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [nucleusConstants.GALAXY_ACTION_CALL_CONNECTED]: (state, action) => {
    const nextState = Object.assign({}, state);
    if (state.callId === action.payload) {
      nextState.callId = null;
      nextState.isAnswering = false;
    }

    return nextState;
  },
  [TRACK_VIDEO_CALL]: (state) => {
    const nextState = Object.assign({}, state);
    nextState.hasVideo = true;
    return nextState;
  },
  [TRACK_AUDIO_CALL]: (state) => {
    const nextState = Object.assign({}, state);
    nextState.hasVideo = false;
    return nextState;
  },
  [AUDIO_UNMUTE_BY_USER]: (state, action) => {
    const nextState = Object.assign({}, state);
    nextState.audioUnmuteByUser = action.payload.audioUnmuteByUser;
    return nextState;
  },
  [nucleusConstants.GALAXY_ACTION_END]: (state) => {
    return {
      ...state,
      isWebConference: false
    };
  },
  [nucleusConstants.GALAXY_ACTION_FAILED]: (state) => {
    return {
      ...state,
      isWebConference: false
    };
  },
  [WEB_CONFERENCE_ENABLED]: (state) => {
    return {
      ...state,
      isWebConference: true
    };
  },
  [WEB_CONFERENCE_DISABLED]: (state) => {
    return {
      ...state,
      isWebConference: false
    };
  }
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  callId: null,
  isAnswering: false,
  audioUnmuteByUser: false,
  isWebConference: false
};
export default function incomingCallReducer(state = initialState, action = {}) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
