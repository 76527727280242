import { actionTypes } from 'redux-form';
import { nucleusConstants as c } from '@lifesize/nucleus';
import _concat from 'lodash/concat';
import _map from 'lodash/map';
import { createLogger } from 'redux-logger';
import _constant from 'lodash/constant';
import _has from 'lodash/has';
import _update from 'lodash/update';

// Do not log these actions
const ignoredActions = [c.CLOCK_TICK_SEC, c.CLOCK_TICK_MIN, c.AUDIO_LEVEL_UPDATE, c.MEDIA_DEVICES_UPDATED];

// Log these actions, but mask the user text to logger.
// Hides sensitive information displayed to client.
const maskedCustomActions = [c.CHAT_MESSAGE, c.ADD_MESSAGE];

// Combination of custom masked actions and all @@redux-form actions
const filteredPayloadActions = _concat(
  maskedCustomActions,
  _map(actionTypes, (val) => val)
);

const isIncludedAction = (action) => !ignoredActions.includes(action.type);

const isFilteredAction = (action) => filteredPayloadActions.includes(action.type);

const isFilteredCustomAction = (action) => maskedCustomActions.includes(action.type);

// Compares dispatched action to list of actions
// that should be suppressed in logs
export const ignoreFilter = (getState, action) =>
  !!(process.env.REACT_APP_STAGE === 'local' || localStorage.getItem('enableLogging')) && isIncludedAction(action);

// If body text exists, returns the matching path of the action payload
// attribute. Returns an empty array if no matches found
const pathMatcher = (action) => {
  const paths = ['[payload][message][body]', '[payload][response][message][body]', '[payload][chatMessage][body]'];
  return paths.filter((path) => _has(action, path));
};

// If enabled, masks or removes payload information from dispatched action.
// Compares against custom actions to mask payload text (payload is object)
// Compares against @@redux-form actions to remove payload text (payload is
// string)
// Note: Only called if the action is not ignored by
// the predicate function of redux-logger
export const maskFilter = (action) => {
  if (!action.payload || !isFilteredAction(action)) {
    return action;
  }
  if (isFilteredCustomAction(action)) {
    const pathMatch = pathMatcher(action);
    if (pathMatch.length) {
      // Mask text only in specified custom actions
      return _update(action, pathMatch[0], _constant(''));
    }
    // No message text found
    return action;
  }

  // @@redux-form actions have a string value alone for payload - remove it.
  // eslint-disable-next-line no-unused-vars
  const { payload, ...loggedAction } = action;
  return loggedAction;
};

const loggerMiddleware = createLogger({
  predicate: ignoreFilter,
  collapsed: true,
  actionTransformer: (action) => maskFilter(action)
});

export default loggerMiddleware;
