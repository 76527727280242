/* globals gapi */
import _get from 'lodash/get';
import { addDaysToCurrentDate } from 'utils/calendar-utils';

// Array of API discovery doc URLs for APIs used by the quickstart
const DISCOVERY_DOCS = ['https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest'];

let initialized = false;

/**
 *  Called when the signed in status changes, to update the UI
 *  appropriately. After a sign-in, the API is called.
 */

/**
 *  Sign in the user upon button click.
 */
export function googleAuthorize() {
  gapi.auth2.getAuthInstance().signIn();
}

/**
 *  Sign out the user upon button click.
 */
export async function googleSignout() {
  return new Promise((resolve, reject) => {
    if (!gapi || !_get(gapi, 'auth2.getAuthInstance')) {
      resolve();
      return;
    }
    const auth2 = gapi.auth2.getAuthInstance();
    if (!auth2) {
      resolve();
      return;
    }
    auth2.disconnect();
    auth2.signOut().then(
      () => {
        resolve();
      },
      (error) => {
        reject(error);
      }
    );
  });
}

/**
 * Gets user profile information. Only available after login.
 */
export function getEmail() {
  const profile = gapi.auth2.getAuthInstance().currentUser.get().getBasicProfile();
  return profile.getEmail();
}

/**
 * List all calendar events. Options are from the Calendar API found at
 * https://developers.google.com/calendar/v3/reference/events/list
 */
export function listEvents(listOptions) {
  const now = new Date();
  const defaultOptions = {
    calendarId: 'primary',
    timeMin: now.toISOString(),
    timeMax: addDaysToCurrentDate(7, now).toISOString(),
    showDeleted: false,
    singleEvents: true,
    orderBy: 'startTime',
    maxItems: 100
  };

  return new Promise((resolve, reject) => {
    gapi.client.calendar.events
      .list({
        ...defaultOptions,
        ...listOptions
      })
      .then(
        (response) => {
          resolve(_get(response, 'result.items'));
        },
        (error) => {
          reject(error);
        }
      );
  });
}

/**
 * Post a new calendar event. Options are from the Calendar API found at
 * https://developers.google.com/calendar/v3/reference/events/insert
 */
export function generateGCalEvent(eventOptions) {
  const defaultOptions = {
    calendarId: 'primary',
    resource: eventOptions
  };

  return new Promise((resolve, reject) => {
    gapi.client.calendar.events
      .insert({
        ...defaultOptions
      })
      .then(
        (response) => {
          resolve(_get(response, 'result'));
        },
        (error) => {
          reject(error);
        }
      );
  });
}

export async function generateGCalInvite(invitation, start, end) {
  const googleEvent = await generateGCalEvent({
    summary: invitation.subject,
    description: invitation.body,
    start,
    end
  });
  if (googleEvent.htmlLink) {
    window.open(googleEvent.htmlLink, '_blank');
  }
}

/**
 *  Initializes the API client library and sets up sign-in state
 *  listeners.
 */
function initClient(options, resolve, reject) {
  return gapi.client
    .init({
      apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
      discoveryDocs: DISCOVERY_DOCS,
      ...options
    })
    .then(
      () => {
        resolve();
      },
      (error) => {
        reject(JSON.stringify(error, null, 2));
      }
    );
}

export function observeAuthStatus(observer) {
  gapi.auth2.getAuthInstance().isSignedIn.listen(observer);
  observer(gapi.auth2.getAuthInstance().isSignedIn.get());
}

export async function reloadAuthResponse() {
  return gapi.auth2.getAuthInstance().currentUser.get().reloadAuthResponse();
}

/**
 *  On load, called to load the auth2 library and API client library.
 */
export function initializeGoogleApi(options = {}) {
  return new Promise((resolve, reject) => {
    if (initialized) reject('initialized');
    initialized = true;
    gapi.load('client:auth2', {
      callback: () => {
        initClient(options, resolve, reject);
      },
      onerror: () => {
        // Handle loading error.
        reject('Google API client failed to load!');
      },
      timeout: 5000, // 5 seconds.
      ontimeout: () => {
        // Handle timeout.
        reject('Google API client could not load in a timely manner!');
      }
    });
  });
}

export function reset() {
  initialized = false;
}

export default {
  initializeGoogleApi,
  listEvents,
  getEmail,
  googleAuthorize,
  googleSignout,
  observeAuthStatus,
  reset
};
