import { localizationSelectors, nucleusConstants } from '@lifesize/nucleus';
import { changeLocale } from 'actions/I18n';
import { LOCALE_SETTING } from 'constants/UserSettings';
import _get from 'lodash/get';

const i18nMiddleware = (store) => (next) => (action) => {
  const result = next(action);
  if (
    action.type === nucleusConstants.GET_CURRENT_USER_SETTINGS_SUCCESS ||
    action.type === nucleusConstants.INIT_NUCLEUS
  ) {
    const state = store.getState();
    const userSettingsLanguageId = _get(state, `nucleus.userSettings.${LOCALE_SETTING}`);
    const languageId = _get(localizationSelectors.localization(state), 'languageId');
    if (userSettingsLanguageId !== languageId) {
      store.dispatch(changeLocale(userSettingsLanguageId));
    }
  }
  return result;
};

export default i18nMiddleware;
