import azureApi from 'api/azure-api';
import {
  CALENDAR_API_SIGNED_IN,
  CALENDAR_API_SIGNED_OUT,
  CALENDAR_API_ERROR,
  CALENDAR_LIST_EVENTS,
  CALENDAR_USER,
  CALENDAR_SIGN_IN_START,
  CALENDAR_LOAD_AUTH,
  CALENDAR_EXTERNAL_AUTH,
  START_CALENDAR_NOTIFICATIONS,
  SET_CALENDAR_NOTIFICATION,
  DELETE_CALENDAR_NOTIFICATION,
  CLEAR_CALENDAR_NOTIFICATION,
  SET_UPCOMING_NOTIFICATION,
  REMOVE_UPCOMING_NOTIFICATION,
  INITIALIZE_OUTLOOK_OAUTH,
  REFRESH_CALENDARS,
  CALENDAR_SELECT_EVENT,
  RETRIEVING_CALENDAR_INFO,
  calendars
} from 'constants/Calendar';
import calendarSelectors from 'selectors/calendar';
import { environmentSelectors, currentUserSelectors, localizationSelectors } from '@lifesize/nucleus';
import _get from 'lodash/get';
import { logger } from 'utils/logger';

export function calendarSignedIn(token, calendarId) {
  return {
    type: CALENDAR_API_SIGNED_IN,
    payload: {
      token,
      calendarId
    }
  };
}

export const calendarExternalAuth = (auth, calendarId) => async (dispatch, getState) => {
  return dispatch({
    type: CALENDAR_EXTERNAL_AUTH,
    payload: {
      auth,
      calendarId,
      userId: currentUserSelectors.myInfo(getState()).id
    }
  });
};

export function startNotifications() {
  return {
    type: START_CALENDAR_NOTIFICATIONS
  };
}

export function refreshCalendars() {
  return {
    type: REFRESH_CALENDARS
  };
}

export function retrievingCalendarInfo(calendarId) {
  return {
    type: RETRIEVING_CALENDAR_INFO,
    payload: calendarId
  };
}

export function loadCalendarAuth(calendarId, auth) {
  return {
    type: CALENDAR_LOAD_AUTH,
    payload: {
      calendarId,
      auth
    }
  };
}

export const calendarSignedOut = (calendarId) => async (dispatch, getState) => {
  return dispatch({
    type: CALENDAR_API_SIGNED_OUT,
    payload: {
      calendarId,
      userId: currentUserSelectors.myInfo(getState()).id
    }
  });
};

export function calendarApiError(error, calendarId) {
  return {
    type: CALENDAR_API_ERROR,
    payload: {
      error,
      calendarId
    }
  };
}

export const calendarListEvents = (events, calendarId) => async (dispatch, getState) => {
  const lastUpdated = new Date();
  const timeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit'
  };
  const languageId = _get(localizationSelectors.localization(getState()), 'languageId');
  const updatedTime = lastUpdated.toLocaleTimeString(languageId, timeFormatOptions);

  return dispatch({
    type: CALENDAR_LIST_EVENTS,
    payload: {
      events,
      calendarId,
      updatedTime
    }
  });
};

export function calendarSignInStart(calendarId) {
  return {
    type: CALENDAR_SIGN_IN_START,
    payload: calendarId
  };
}

export function selectCalendarEvent(calendarId) {
  return {
    type: CALENDAR_SELECT_EVENT,
    payload: { id: calendarId }
  };
}

export function resetCalendarEventSelection() {
  return {
    type: CALENDAR_SELECT_EVENT,
    payload: null
  };
}

export function calendarUser(user, calendarId) {
  return {
    type: CALENDAR_USER,
    payload: {
      user,
      calendarId
    }
  };
}

export const removeUpcomingNotification = (id) => ({
  type: REMOVE_UPCOMING_NOTIFICATION,
  payload: id
});

export const initializeOutlookOauth = () => ({
  type: INITIALIZE_OUTLOOK_OAUTH
});

export const updateUpcomingNotification = (event, timeUntil) => async (dispatch, getState) => {
  const state = getState();
  const allEvents = calendarSelectors.lifesizeMeetingsSelector(state);
  if (allEvents.find((existingEvent) => existingEvent.id === event.id)) {
    return dispatch({
      type: SET_UPCOMING_NOTIFICATION,
      payload: {
        id: event.id,
        timeUntil
      }
    });
  }

  return dispatch(removeUpcomingNotification(event.id));
};

export const outlookListEventsAndDispatch = (token) => async (dispatch, getState) => {
  const state = getState();
  const getEvents = async (apiToken) => {
    const results = await azureApi.listEvents(apiToken);
    dispatch(calendarListEvents(results.value, calendars.outlook));
    const user = await azureApi.getUser(apiToken);
    if (_get(user, 'userPrincipalName')) {
      dispatch(calendarUser(user.userPrincipalName, calendars.outlook));
    }
  };

  try {
    await getEvents(token);
  } catch (e) {
    const isDesktop = environmentSelectors.platform(state).isDesktop;
    if (isDesktop) {
      logger.debug(`getEventsFailed: ${JSON.stringify(e)}`);
      return;
    }
    try {
      const newAuth = await azureApi.getAccessToken();
      const newToken = _get(newAuth, 'accessToken');
      if (newToken) {
        dispatch(calendarSignedIn(newToken, calendars.outlook));
        await getEvents(newToken);
      } else {
        dispatch(calendarApiError('Unable to retrieve token', calendars.outlook));
      }
    } catch (error) {
      dispatch(calendarApiError(error, calendars.outlook));
    }
  }
};

export const outlookAuthorize = () => async (dispatch, getState) => {
  try {
    const isDesktop = environmentSelectors.platform(getState()).isDesktop;
    dispatch(calendarSignInStart(calendars.outlook));
    if (isDesktop) {
      dispatch(initializeOutlookOauth());
      return;
    }
    const auth = await azureApi.login();
    const token = _get(auth, 'accessToken');
    if (token) {
      dispatch(calendarSignedIn(token, calendars.outlook));
      dispatch(outlookListEventsAndDispatch(token));
    }
  } catch (error) {
    dispatch(calendarApiError(error, calendars.outlook));
  }
};

export const outlookSignout = () => async (dispatch, getState) => {
  const isDesktop = environmentSelectors.platform(getState()).isDesktop;
  if (!isDesktop) {
    azureApi.logout();
  }
  dispatch(calendarSignedOut(calendars.outlook));
};

/**
 * Adds a single non-overlay notification
 * @return {Object} Action object with a type of SET_CALENDAR_NOTIFICATION
 */
export function addCalendarNotification(event) {
  return {
    type: SET_CALENDAR_NOTIFICATION,
    payload: event
  };
}

/**
 * Function that will create an action to remove a notification
 * @return {Object}  Action object with type of DELETE_CALENDAR_NOTIFICATION.
 */
export function deleteCalendarNotification(id) {
  return {
    type: DELETE_CALENDAR_NOTIFICATION,
    payload: id
  };
}

/**
 * Function that will create an action to remove a notification from the cached list
 * @return {Object}  Action object with type of CLEAR_CALENDAR_NOTIFICATION.
 */
export function clearCalendarNotification(id) {
  return {
    type: CLEAR_CALENDAR_NOTIFICATION,
    payload: id
  };
}

export default {
  calendarSignedIn,
  calendarExternalAuth,
  startNotifications,
  calendarSignedOut,
  calendarApiError,
  calendarListEvents,
  calendarSignInStart,
  calendarUser,
  outlookListEventsAndDispatch,
  outlookAuthorize,
  outlookSignout,
  addCalendarNotification,
  deleteCalendarNotification,
  clearCalendarNotification,
  refreshCalendars,
  updateUpcomingNotification,
  removeUpcomingNotification,
  selectCalendarEvent,
  resetCalendarEventSelection,
  loadCalendarAuth
};
