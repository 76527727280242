export const FULL_SCREEN_MODAL_ACTION_OPEN = 'FULL_SCREEN_MODAL_ACTION_OPEN';
export const FULL_SCREEN_MODAL_ACTION_CLOSE = 'FULL_SCREEN_MODAL_ACTION_CLOSE';
export const FULL_SCREEN_MODAL_SUBCOMPONENT_DEFAULT = 'FULL_SCREEN_MODAL_SUBCOMPONENT_DEFAULT';

export const internalModals = {
  STARTING_CALL_MODAL: 'STARTING_CALL_MODAL',
  DROPPED_CALL_MODAL: 'DROPPED_CALL_MODAL',
  OFFLINE_MODAL: 'OFFLINE_MODAL',
  RELOAD_MODAL: 'RELOAD_MODAL',
  JOIN_MODAL: 'JOIN_MODAL'
};
