import _omit from 'lodash/omit';
import { FULL_SCREEN_MODAL_ACTION_OPEN, FULL_SCREEN_MODAL_ACTION_CLOSE } from 'constants/FullScreenModal';
import {
  CONTACT_MODAL,
  CONTACT_FORM_ERROR,
  CLEAR_CONTACT_ERROR_STATE,
  SELECT_MODAL_CONTACT,
  LECTURER_SEARCH_FORM_FOCUSED,
  LECTURER_SELECT,
  LECTURER_UNSELECT,
  MODERATOR_SEARCH_FORM_FOCUSED,
  MODERATOR_SELECT,
  MODERATOR_UNSELECT,
  MODERATOR_RESET
} from 'constants/ContactModal';

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [FULL_SCREEN_MODAL_ACTION_OPEN]: (state, action) => {
    const subComponentType = action.payload.subComponentType;
    if (!subComponentType || subComponentType !== CONTACT_MODAL) return state;
    const nextState = Object.assign({}, state);
    nextState.contactObj = action.payload.modalProps.contact;
    nextState.isDelete = Boolean(action.payload.modalProps.isDelete);
    nextState.isCreate = Boolean(action.payload.modalProps.isCreate);
    nextState.isMeeting = Boolean(action.payload.modalProps.isMeeting);
    nextState.contactError = null;
    return nextState;
  },
  [SELECT_MODAL_CONTACT]: (state, action) => {
    const nextState = Object.assign({}, state);
    if (action.payload) {
      nextState.contactObj = action.payload;
    } else {
      nextState.contactObj = null;
    }

    nextState.contactError = null;
    return nextState;
  },
  [CONTACT_FORM_ERROR]: (state, action) => {
    const nextState = Object.assign({}, state);
    nextState.contactError = action.payload.name;
    nextState.existingName = action.payload.existingName;
    return nextState;
  },
  [LECTURER_SELECT]: (state, action) => {
    const nextState = Object.assign({}, state);
    nextState.lecturer = action.payload;
    return nextState;
  },
  [LECTURER_UNSELECT]: (state, action) => {
    const nextState = Object.assign({}, state);
    if (action.payload) {
      nextState.lecturer = {};
      nextState.oldLecturer = {};
    } else {
      if (state.lecturer && Object.keys(state.lecturer).length) {
        nextState.oldLecturer = state.lecturer;
      }
      nextState.lecturer = {};
    }
    return nextState;
  },
  [LECTURER_SEARCH_FORM_FOCUSED]: (state, action) => {
    const nextState = Object.assign({}, state);
    nextState.isLecturerFocused = action.payload;
    return nextState;
  },
  [MODERATOR_SELECT]: (state, action) => {
    const nextState = Object.assign({}, state);
    nextState.moderator = action.payload;
    return nextState;
  },
  [MODERATOR_UNSELECT]: (state) => {
    const nextState = Object.assign({}, state);
    if (state.moderator && Object.keys(state.moderator).length) {
      nextState.oldModerator = state.moderator;
    }
    nextState.moderator = {};
    if (!!state.contactObj) {
      nextState.contactObj = _omit(state.contactObj, 'moderator');
      nextState.contactObj.moderator = {};
    }
    return nextState;
  },
  [MODERATOR_RESET]: (state) => {
    const nextState = Object.assign({}, state);
    nextState.oldModerator = {};
    nextState.moderator = {};
    return nextState;
  },
  [MODERATOR_SEARCH_FORM_FOCUSED]: (state, action) => {
    const nextState = Object.assign({}, state);
    nextState.isModeratorFocused = action.payload;
    return nextState;
  },
  [FULL_SCREEN_MODAL_ACTION_CLOSE]: (state) => {
    const nextState = Object.assign({}, state);
    nextState.oldModerator = {};
    nextState.moderator = {};
    nextState.contactError = null;
    return nextState;
  },
  [CLEAR_CONTACT_ERROR_STATE]: (state) => {
    const nextState = Object.assign({}, state);
    nextState.contactError = null;
    return nextState;
  }
};

// ------------------------------------
// Reducer
// ------------------------------------
export const initialState = {
  isLecturerFocused: false,
  isModeratorFocused: false,
  lecturer: {},
  moderator: {},
  oldModerator: {},
  oldLecturer: {},
  contactObj: {},
  isDelete: false,
  isCreate: false,
  isMeeting: false,
  contactError: null
};

export default function contactModalReducer(state = initialState, action = {}) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
