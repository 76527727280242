import { createSelector } from 'reselect';
import { servicesSelectors, userProfileSelectors } from '@lifesize/nucleus';
import get from 'lodash/get';
import { logger } from 'utils/logger';

export const selectHasAttemptedLogin = (state) => get(state, 'app.jwt.hasAttemptedLogin');
export const selectHasInitializedServices = (state) => get(state, 'app.jwt.hasInitializedServices');
export const selectHasAuthFailure = (state) => get(state, 'app.jwt.authFailure');
export const selectLoginCount = (state) => get(state, 'app.jwt.loginCount');
export const selectCurrentPath = (state) => get(state, 'app.jwt.currentPath', '/');
export const selectPreviousPath = (state) => get(state, 'app.jwt.previousPath');
export const selectRecheckinAfterCall = (state) => get(state, 'app.jwt.reCheckin');

export const selectIsLoggedIn = createSelector(
  servicesSelectors.selectConnected,
  servicesSelectors.selectWillRetry,
  selectHasAttemptedLogin,
  selectHasInitializedServices,
  selectHasAuthFailure,
  userProfileSelectors.selectIsGuest,
  (connected, willRetry, hasMadeWampConnection, successfulServicesConnection, authFailure, isGuest) => {
    if (isGuest && hasMadeWampConnection && !successfulServicesConnection) {
      logger.debug('Guest wamp connected but services not yet ready!', {
        hasMadeWampConnection,
        successfulServicesConnection,
        connected,
        willRetry
      });
      return false;
    }

    // If the services have connected once (on initial app load), then the user has
    // successfully logged in. Any auth failures mean the user is no longer logged in.
    return (successfulServicesConnection || connected || willRetry) && !authFailure;
  }
);
