import {
  BOOKMARK_SCROLL_POSITION,
  SELECT_CONVERSATION_ID,
  SET_CHAT_MODE,
  CHAT_SEARCH_FORM_FOCUSED,
  CHAT_MESSAGE_RECEIVED,
  SET_OVERLAY_ICON,
  DEF_CONVERSATION_MODE
} from 'constants/Chat';
import _merge from 'lodash/merge';
import { nucleusConstants } from '@lifesize/nucleus';

// ------------------------------------
// Constants
// ------------------------------------
const defSelectedConversationContactDialString = localStorage.getItem('lastConversationContactDialString');

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  conversationMode: DEF_CONVERSATION_MODE,
  scrollBookmarks: {},
  selectedConversationContactDialString: defSelectedConversationContactDialString,
  isFocused: false,
  isGettingMessages: false,
  isLoading: false,
  hasNewChat: false,
  unreadChatCount: 0,
  dataURL: null
};

export default function (state = initialState, action) {
  switch (action.type) {
    case BOOKMARK_SCROLL_POSITION: {
      const scrollBookmarks = _merge({}, state.scrollBookmarks, {
        [action.payload.conversationContactDialString]: action.payload.settings
      });

      return {
        ...state,
        scrollBookmarks
      };
    }
    case SELECT_CONVERSATION_ID: {
      localStorage.setItem('lastConversationContactDialString', action.payload.selectedConversationContactDialString);
      return {
        ...state,
        ...action.payload
      };
    }
    case SET_CHAT_MODE:
      return {
        ...state,
        ...action.payload
      };
    case CHAT_SEARCH_FORM_FOCUSED: {
      const nextState = Object.assign({}, state);
      nextState.isFocused = action.payload;
      return nextState;
    }
    case CHAT_MESSAGE_RECEIVED: {
      const nextState = Object.assign({}, state);
      nextState.hasNewChat = false;
      return nextState;
    }
    case SET_OVERLAY_ICON: {
      const nextState = Object.assign({}, state);
      nextState.dataURL = action.payload.dataURL;
      nextState.unreadChatCount = action.payload.unreadChatCount;
      return nextState;
    }
    case nucleusConstants.CONVERSATION_LOADING_BEGIN: {
      const nextState = Object.assign({}, state);
      nextState.isLoading = true;
      return nextState;
    }
    case nucleusConstants.CONVERSATION_LOADING_FINISHED: {
      const nextState = Object.assign({}, state);
      /* during the initial load, GET_MORE_MESSAGES (and subsequently the addition of the messages)
       * is called immediately before CONVERSATION_LOADING_FINISHED, so the isLoading state should
       * persist until the last message is loaded (marked by CONVERSATION_REQUEST_FINISHED) */
      nextState.isLoading = nextState.isGettingMessages;
      return nextState;
    }
    case nucleusConstants.GET_MORE_MESSAGES: {
      const nextState = Object.assign({}, state);
      nextState.isGettingMessages = true;
      return nextState;
    }
    case nucleusConstants.CONVERSATION_REQUEST_FINISHED: {
      const nextState = Object.assign({}, state);
      nextState.isGettingMessages = false;
      nextState.isLoading = false;
      return nextState;
    }
    case nucleusConstants.ADD_MESSAGE: {
      const nextState = Object.assign({}, state);
      nextState.hasNewChat = true;
      return nextState;
    }

    default:
      return state;
  }
}
