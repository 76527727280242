import React from 'react';
import { NavLink } from 'react-router-dom';
import { routes } from 'constants/Application';
import { nucleusConstants } from '@lifesize/nucleus';
import { toast } from 'react-toastify';
import { defineMessages } from 'react-intl';
import _get from 'lodash/get';
import _find from 'lodash/find';
import _pull from 'lodash/pull';
import { logger } from 'utils/logger';
import { outlookListEventsAndDispatch, retrievingCalendarInfo, outlookAuthorize } from 'actions/Calendar';
import {
  calendars,
  CALENDAR_LIST_EVENTS,
  START_CALENDAR_NOTIFICATIONS,
  REFRESH_CALENDARS,
  DELETE_CALENDAR_NOTIFICATION,
  CALENDAR_API_SIGNED_OUT,
  CALENDAR_API_ERROR,
  CALENDAR_SIGN_IN_START,
  OUTLOOK_INVALID_GRANT_ERROR,
  OUTLOOK_LOGIN_REQUIRED_ERROR,
  OUTLOOK_INTERACTION_REQUIRED
} from 'constants/Calendar';
import { selectors } from '@lifesize/clients.sdk';
import { googleListEventsAndDispatch, initializeGoogleApp } from 'utils/GoogleCalendarUtils';
import { reloadAuthResponse } from 'api/google-api';
import { initializeAzureApp } from 'utils/AzureCalendarUtils';
import { checkEvents, handleJoin, handleClose } from 'utils/CalendarNotificationUtils';
import classes from 'styles/toastify.module.scss';

const outlookApiReconnectionRequiredErrors = [OUTLOOK_LOGIN_REQUIRED_ERROR, OUTLOOK_INTERACTION_REQUIRED];

const shouldShowToast = (action) => {
  const isNotInActiveCall = selectors.callState() === nucleusConstants.CALL_STATE_DISCONNECTED;
  const isOutlookCalendar = _get(action, 'payload.calendarId') === calendars.outlook;
  const isInvalidGrantError = _get(action, 'payload.error') === OUTLOOK_INVALID_GRANT_ERROR;
  const isInListOfReconnectionErrors =
    outlookApiReconnectionRequiredErrors.indexOf(_get(action, 'payload.error.errorCode')) !== -1;

  return isNotInActiveCall && isOutlookCalendar && (isInvalidGrantError || isInListOfReconnectionErrors);
};

const timersGroupByCalendarId = {
  [calendars.outlook]: [],
  [calendars.google]: []
};

const activeDesktopNotifications = [];
const clearToastActions = [
  CALENDAR_SIGN_IN_START,
  nucleusConstants.GALAXY_ACTION_INCOMING_CALL,
  nucleusConstants.GALAXY_ACTION_BEGIN_CALL
];

let toastId = '';

const messages = defineMessages({
  outlookCalendarDisconnected: {
    id: 'toast.outlook.calendar.disconnected',
    defaultMessage: 'Your Outlook Calendar has been disconnected'
  },
  reconnect: {
    id: 'toast.reconnect',
    defaultMessage: 'Reconnect'
  }
});

const calendarMiddleware = (store) => (next) => (action) => {
  const state = store.getState();

  if (clearToastActions.includes(action.type) && toast.isActive(toastId)) {
    toast.dismiss(toastId);
  }

  switch (action.type) {
    case START_CALENDAR_NOTIFICATIONS:
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.register(`${process.env.PUBLIC_URL}/workers/notificationWorker.js`).then(
          (registration) => {
            window.notificationServiceRegistration = registration;

            checkEvents(store);
            navigator.serviceWorker.addEventListener('message', (event) => {
              switch (_get(event, 'data.type')) {
                case 'join':
                  handleJoin(store, event.data.extension);
                  break;
                case 'close':
                  handleClose(store, event.data.id);
                  break;
                default:
                  break;
              }
            });
          },
          (error) => {
            logger.error(`Navigator registration error: ${error}`);
          }
        );
      }
      break;

    case DELETE_CALENDAR_NOTIFICATION:
      if (_get(window, 'notificationServiceRegistration.active.postMessage', false)) {
        window.notificationServiceRegistration.active.postMessage({
          type: 'close',
          id: action.payload
        });
      }

      const relatedNotification = _find(
        activeDesktopNotifications,
        (notification) => notification.data.id === action.payload
      );
      if (relatedNotification) {
        _pull(activeDesktopNotifications, relatedNotification);
        relatedNotification.close();
      }
      break;

    case REFRESH_CALENDARS:
      const googleSignedIn = _get(state, 'app.calendar.google.calendarSignedIn');
      if (googleSignedIn) {
        store.dispatch(retrievingCalendarInfo(calendars.google));
        googleListEventsAndDispatch(store);
      }

      const outlookSignedIn = _get(state, 'app.calendar.outlook.calendarSignedIn');
      if (outlookSignedIn) {
        const outlookToken = _get(state, 'app.calendar.outlook.calendarToken');
        store.dispatch(retrievingCalendarInfo(calendars.outlook));
        store.dispatch(outlookListEventsAndDispatch(outlookToken));
      }
      break;

    case CALENDAR_LIST_EVENTS:
      if (_get(state, 'app.calendar.notificationsStarted')) {
        // so that store is updated with calendar events.
        setTimeout(() => checkEvents(store), 10);
      }
      break;

    case nucleusConstants.INITIALIZE_SERVICES_SUCCESS:
      initializeAzureApp(store);
      initializeGoogleApp(store);
      break;

    case nucleusConstants.LOGOUT_BEGIN:
      Object.keys(calendars).forEach((calendar) => {
        localStorage.setItem(`calendar:${calendar}`, JSON.stringify({}));
      });
      break;

    case CALENDAR_API_SIGNED_OUT:
      const calendarType = _get(action, 'payload.calendarId');
      timersGroupByCalendarId[calendarType].forEach((timer) => clearTimeout(timer));
      break;

    case CALENDAR_API_ERROR:
      if (action?.payload?.calendarId === calendars.google) {
        try {
          reloadAuthResponse();
        } catch (error) {
          logger.error(`Unable to reload google auth: ${error}`);
        }
      }
      if (shouldShowToast(action)) {
        toastId = toast.error(_get(state, ['app', 'i18n', 'messages', messages.outlookCalendarDisconnected.id]), {
          className: classes.toastify,
          bodyClassName: classes.toastifyBody,
          toastId: 'calendar_invalid_grant_error_toast',
          position: 'top-center',
          autoClose: false,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: false,
          progress: 0,
          closeButton: (
            <NavLink to={routes.upcoming} exact>
              <div
                className="reconnect"
                onClick={() => {
                  toast.dismiss(toastId);
                  store.dispatch(outlookAuthorize());
                }}
              >
                {' '}
                {_get(state, ['app', 'i18n', 'messages', messages.reconnect.id])}
              </div>
            </NavLink>
          )
        });
      }
      break;

    default:
      break;
  }
  return next(action);
};

export default calendarMiddleware;
