import get from 'lodash/get';
import {
  START_CALL_SELECT,
  START_CALL_SELECT_CUSTOM,
  START_CALL_FORM_FOCUSED,
  CALL_ONE_TIME
} from 'constants/StartCallModal';
import { nucleusConstants } from '@lifesize/nucleus';

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [START_CALL_SELECT]: (state, action) => {
    const nextState = Object.assign({}, state);
    nextState.contactUuid = get(action, 'payload.uuid');
    nextState.customContact = undefined;
    nextState.callOneTime = false;
    return nextState;
  },
  [START_CALL_SELECT_CUSTOM]: (state, action) => {
    const nextState = Object.assign({}, state);
    nextState.contactUuid = undefined;
    nextState.customContact = get(action, 'payload');
    nextState.callOneTime = false;
    return nextState;
  },
  [START_CALL_FORM_FOCUSED]: (state, action) => {
    const nextState = Object.assign({}, state);
    nextState.isFocused = action.payload;
    return nextState;
  },
  [CALL_ONE_TIME]: (state) => {
    const nextState = Object.assign({}, state);
    nextState.callOneTime = true;
    return nextState;
  },
  [nucleusConstants.GALAXY_ACTION_CALL_CONNECTED]: (state) => {
    const nextState = Object.assign({}, state);
    nextState.contactUuid = undefined;
    nextState.customContact = undefined;
    return nextState;
  }
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = { isFocused: false, callOneTime: false };
export default function startCallModalReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
