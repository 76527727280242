export const FEATURE_FLAGS_INITIALIZED = 'FEATURE_FLAGS_INITIALIZED';
export const FLAG_1080P_RESOLUTION = 'WEB_1080Resolution';
export const FLAG_4K_RESOLUTION = 'WEB_4kResolution';
export const FLAG_AUDIO_PRESENTATION = 'WEB_EnablePresentationAudio';
export const FLAG_DEFAULT_TO_LOW_RES = 'WEB_defaultToLowRes';
export const FLAG_ENABLE_ADVANCED_SETTINGS = 'WEB_enableAdvancedSettings';
export const FLAG_ENABLE_CALL_QUALITY = 'WEB_enableCallQualityNotification';
export const FLAG_ENABLE_IE11 = 'WEB_enableIE11';
export const FLAG_ENABLE_ONE_TIME_MEETINGS_WEBAPP = 'WEB_enableOneTimeMeetings';
export const FLAG_ENABLE_LIVE_CAPTIONS = 'WEB_EnableLiveCaptions';
export const FLAG_ENABLE_PROFILE_EDIT = 'WEB_enableProfileNameEdit';
export const FLAG_ENABLE_PROFILE_PICTURE = 'WEB_enableProfilePicture';
export const FLAG_FAVORITE_ONE_TIME_MEETINGS = 'WEB_favoriteOneTimeMeetings';
export const FLAG_GUEST_URL = 'WEB_GuestURL';
export const FLAG_IN_CALL_COLLABORATION = 'WEB_EnableInCallCollaboration';
export const FLAG_OTM_CHAT = 'WEB_enableOneTimeMeetingChat';
export const FLAG_PARTICIPANT_SEARCH = 'WEB_ParticipantSearch';
export const FLAG_POPOUT_PRESENTATIONS = 'WEB_popoutPresentations';
export const FLAG_PRESENTATION_RESTRICTION = 'WEB_RestrictPresentation';
export const FLAG_RAISE_HAND = 'WEB_RaiseHand';
export const FLAG_SFU_COMPOSITE_SWITCH = 'WEB_SFU_CompositedLayoutToggle';
export const FLAG_UPGRADE_LINK = 'WEB_upgradeLink';
export const FLAG_VARIABLE_RESOLUTION = 'WEB_variableResolution';
export const FLAG_VIRTUAL_BACKGROUND = 'WEB_VirtualBackground';
export const SET_FEATURE_FLAG = 'SET_FEATURE_FLAG';
export const WEB_RemoteModerators = 'WEB_RemoteModerators';
export const FLAG_GOOGLE_SCHEDULE_INTEGRATION = 'WEB_ScheduleGoogleCalendar';
export const FLAG_OUTLOOK_SCHEDULE_INTEGRATION = 'WEB_ScheduleOutlookCalendar';
export const FEATURE_FLAG_MULTIPLE_MODERATORS = 'ADMIN_MultiModerator';
export const FLAG_SPEAKING_WHILE_MUTED = 'WEB_SpeakingWhileMutedAlert';
export const FEATURE_FLAG_WAITING_ROOM = 'WEB_WaitingRoom2';
export const FEATURE_FLAG_KIOSK_AGENT_ROLE = 'WEB_kioskAgentRole';
export const FEATURE_FLAG_ENABLE_CHAT_DELETION = 'WEB_EnableClearChatHistory';
