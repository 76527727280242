import { ADD_FAVORITE_TOGGLE, FAVORITES_FORM_FOCUSED } from 'constants/Favorites';
import _isBoolean from 'lodash/isBoolean';

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [ADD_FAVORITE_TOGGLE]: (state, action) => {
    const nextState = Object.assign({}, state);
    nextState.isAddVisible = _isBoolean(action.payload) ? action.payload : !nextState.isAddVisible;
    return nextState;
  },
  [FAVORITES_FORM_FOCUSED]: (state, action) => {
    const nextState = Object.assign({}, state);
    nextState.isFocused = action.payload;
    return nextState;
  }
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = { isAddVisible: false, isFocused: false };
export default function favortiesReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
