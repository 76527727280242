import { CHANGE_LOCALE, FETCH_MESSAGES_BEGIN, FETCH_MESSAGES_SUCCESS, FETCH_MESSAGES_FAILURE } from 'constants/I18n';
import { LOCALE_SETTING } from 'constants/UserSettings';
import { localizationActions, userSettingsActions } from '@lifesize/nucleus';
import { localizationUtils } from '@lifesize/clients.localization';
import 'isomorphic-fetch';
import Cookies from 'universal-cookie';
import * as HttpUtils from 'utils/http-utils';
import { logger } from 'utils/logger';
import { getQueryParams, reloadApplication } from 'utils/UrlUtil';
import _get from 'lodash/get';

const cookie = new Cookies();
const localStorageKey = 'locale';

/**
 * Gets the "default" locale by using, in order of precedence:
 *  - The call me page cookie
 *  - The user settings (based on the fact that is saved/restored)
 *  - English
 * @param {Object} [state] The current state
 * @returns {string}
 */
export function getDefaultLanguageId(state) {
  const localStorageLocale = _get(window, 'lifesize.desktopApp') ? localStorage.getItem(localStorageKey) : ''; // only use localStorage for the desktop app
  const callMePageLocale = cookie.get('call-lang');
  const languageParam = getQueryParams().language;
  const paramLocale = languageParam;
  const userSettingsLanguage = _get(state, `nucleus.userSettings['${LOCALE_SETTING}']`);
  return localizationUtils.getLanguageId(
    localStorageLocale || paramLocale || callMePageLocale || userSettingsLanguage || 'en-US'
  );
}

export function fetchMessages(dispatch, languageId) {
  dispatch({
    type: FETCH_MESSAGES_BEGIN,
    payload: languageId
  });

  // Add phantom query to prevent caching of the file
  const now = new Date();
  // If join was the landing page, strip out the extra paths
  const url = `${process.env.PUBLIC_URL}/locales/${languageId}.json?nocache=${now.getTime()}`;

  return fetch(url)
    .then(HttpUtils.checkHttpStatus)
    .then((response) => response.json())
    .then((response) => {
      dispatch({
        type: FETCH_MESSAGES_SUCCESS,
        payload: response
      });
      return Promise.resolve(response);
    })
    .catch((err) => {
      dispatch({
        type: FETCH_MESSAGES_FAILURE,
        payload: {
          message: err.message,
          response: {
            url: url
          }
        }
      });
      return Promise.reject(err);
    });
}

export function changeLocale(languageCode, save = false, reload = false) {
  return (dispatch) => {
    const languageId = localizationUtils.getLanguageId(languageCode);
    return fetchMessages(dispatch, languageId)
      .then((response) => {
        dispatch(localizationActions.setLocalization(languageId));
        if (save) {
          dispatch({
            type: CHANGE_LOCALE,
            payload: {
              messages: response
            }
          });
          if (_get(window, 'lifesize.desktopApp')) {
            localStorage.setItem(localStorageKey, languageId);
          } else {
            // TODO use window.lifesize.apidomain once all hosting is on lifesize.com
            const domain = window.location.hostname.split('.').slice(-2).join('.');
            cookie.remove('call-lang');
            cookie.set('call-lang', languageId, { domain, path: '/', maxAge: 63072000 });
          }
          return dispatch(userSettingsActions.updateCurrentUserSettings({ [LOCALE_SETTING]: languageId })).then(() => {
            if (reload) {
              reloadApplication();
            }
          });
        }

        return Promise.resolve(
          dispatch({
            type: CHANGE_LOCALE,
            payload: {
              messages: response
            }
          })
        );
      })
      .catch(logger.error);
  };
}

export default {
  changeLocale,
  fetchMessages,
  getDefaultLanguageId
};
