import { parse } from 'query-string';
import { ClientTypes } from '@lifesize/constants';
import { initActions, nucleusConstants, networkActions, media } from '@lifesize/nucleus';
import { changeLocale, getDefaultLanguageId } from 'actions/I18n';
import { getDomain, isProduction } from 'utils/UrlUtil';
import { highQualityHardwareConcurrencyLimit } from 'constants/Application';
import {
  VIDEO_PRIMARY,
  VIDEO_SECONDARY,
  VIDEO_FOOTER_PIP,
  VIDEO_PRESENTATION_PIP,
  PREVIEW_VIDEO_ID
} from 'constants/InCall/InCallVideo';
import {
  SIDE_NAV_WIDE_SETTING,
  FIRST_TIME_USER_SETTING,
  LOCALE_SETTING,
  PIP_EXPANDED_SETTING
} from 'constants/UserSettings';
import { set as updateSettings } from 'reducers/mediaSettingsSlice';
import _get from 'lodash/get';
import { getDefaultVideoTrackConstraints } from '../utils/mediaConstraints';
import { readFromLocalStorage } from 'utils/localStorage/mediaSettings';
import { getConstraints } from 'utils/deviceUtils';

// ========================================================
// Initialize Nucleus
// ========================================================

function getClientType() {
  if (window.desktopApp) {
    return navigator.platform === 'Win32' ? ClientTypes.DESKTOP_WINDOWS : ClientTypes.DESKTOP_OSX;
  }
  return ClientTypes.WEBAPP;
}

function getPlatform() {
  if (window.desktopApp) {
    return navigator.platform === 'Win32' ? nucleusConstants.PLATFORM_WINDOWS : nucleusConstants.PLATFORM_OSX;
  }
  return nucleusConstants.PLATFORM_WEB;
}

const getCNUCInitOptions = (store, languageId) => {
  const parsedUrl = parse(window.location.search);
  // Are we online or offline currently
  const enableH264 = localStorage.getItem('enableH264') === 'true' || !!parsedUrl.enableH264;
  const preferH264 = localStorage.getItem('preferH264') === 'true' || parsedUrl.preferH264 === 'true';
  const enablePrimary1080Rx =
    localStorage.getItem('enablePrimary1080Rx') === 'true' || parsedUrl.enablePrimary1080Rx === 'true';
  const enableSecondary1080Rx = true; // Always offer up 1080 presentation receive
  const primaryProfile = localStorage.getItem('primaryProfile') || parsedUrl.primaryProfile || '42e01f';
  const secondaryProfile = localStorage.getItem('secondaryProfile') || parsedUrl.secondaryProfile || '42e01f';
  const maxBandwidth = localStorage.getItem('maxBandwidth') || parsedUrl.maxBandwidth;
  const splitBandwidth = !(localStorage.getItem('splitBandwidth') === 'false' || parsedUrl.splitBandwidth === 'false');
  const updateVideoBandwidth = !(
    localStorage.getItem('updateVideoBandwidth') === 'false' || parsedUrl.updateVideoBandwidth === 'false'
  );
  const supportRelayMode = localStorage.getItem('supportRelayMode') || parsedUrl.supportRelayMode === 'true';
  const enableTransportCC = localStorage.getItem('enableTransportCC') === 'true' || !!parsedUrl.enableTransportCC;

  const directMediaEnabled = localStorage.getItem('directMediaEnabled') === 'true';

  const mixpanelToken = isProduction() ? nucleusConstants.MIXPANEL_PROD : nucleusConstants.MIXPANEL_STAGE;

  const desktopOptions = _get(window, 'desktopApp.nucleusInitOptions') || {};
  const clientInfo = {
    clientType: getClientType(),
    version: process.env.REACT_APP_VERSION
  };

  const defaultToLowRes = navigator.hardwareConcurrency <= highQualityHardwareConcurrencyLimit;

  const webOptions = {
    callHistoryEnabled: true,
    callsEnabled: true,
    gqlChatEnabled: true,
    clientInfo,
    defaultUserSettings: {
      [SIDE_NAV_WIDE_SETTING]: true,
      [FIRST_TIME_USER_SETTING]: true,
      [LOCALE_SETTING]: languageId,
      [PIP_EXPANDED_SETTING]: true
    },
    directMediaEnabled,
    enableH264,
    enablePrimary1080Rx,
    enableSecondary1080Rx,
    enableTransportCC,
    environment: getDomain(),
    languageId,
    loadContactsOnLogin: false,
    localCallOverlay: true, // use local call overlay for badges (lock, recording, participant count) instead of composited overlay
    localNotificationOverlay: true, // use local notifications instead of composited overlay
    localPrimaryElementIds: [VIDEO_FOOTER_PIP, PREVIEW_VIDEO_ID],
    localSecondaryElementIds: [VIDEO_PRESENTATION_PIP],
    maxBandwidth,
    platform: getPlatform(),
    preferH264,
    primaryProfile,
    remotePrimaryElementIds: [VIDEO_PRIMARY],
    remoteSecondaryElementIds: [VIDEO_SECONDARY],
    secondaryProfile,
    splitBandwidth,
    supportRelayMode,
    supportV3: true,
    trackingApiKey: mixpanelToken,
    trackingProperties: {
      webclientVersion: process.env.REACT_APP_VERSION
    },
    updateVideoBandwidth,
    version: process.env.REACT_APP_VERSION,
    defaultVideoConstraints: getDefaultVideoTrackConstraints(defaultToLowRes),
    ...desktopOptions
  };
  return webOptions;
};

async function initializeNucleus(store) {
  const dispatch = store.dispatch;
  if (!dispatch) throw new Error('No dispatch initialized');

  const state = store.getState();
  const languageId = getDefaultLanguageId(state);

  const webOptions = getCNUCInitOptions(store, languageId);

  const finishInitialization = async () => {
    await dispatch(initActions.initialize(webOptions));
    const mediaSettings = readFromLocalStorage();
    await media.configure({ defaultConstraints: getConstraints(mediaSettings) });
    dispatch(updateSettings(mediaSettings));
    await dispatch(changeLocale(languageId)); // Load language files before rendering occurs avoiding re-renders, default message loads, and errors in the console.
    dispatch(navigator && navigator.onLine ? networkActions.online() : networkActions.offline());
  };

  await finishInitialization();
}

export default initializeNucleus;
