import intl from 'react-intl-universal';
import Logger from 'js-logger';
import { localizationConstants, localizationUtils } from '@lifesize/clients.localization';
import { Localization } from '@lifesize/clients.localization';
import { lsGet, LsKey, lsSet } from 'utils/localStorageUtils';

const DEFAULT_LOCALE = localizationConstants.defaultLanguageId;

/*
 if user has already selected a language,then read it from local storage
 if not, return navigator language.
 if navigator language is not supported then fall back to en-US
*/
const initLanguageId = lsGet(LsKey.Locale) || navigator.language || DEFAULT_LOCALE;
const localization = new Localization({ id: initLanguageId, excludedIds: ['ru-RU'] });
// Note: these locales are compliant with the ids in localizationConstants.supportedLanguageList
const locales = {
  'cs-CZ': require('@lifesize/strings/guest/cs.json'),
  'de-DE': require('@lifesize/strings/guest/de.json'),
  'en-US': require('@lifesize/strings/guest/en.json'),
  'es-ES': require('@lifesize/strings/guest/es.json'),
  'fi-FI': require('@lifesize/strings/guest/fi.json'),
  'fr-FR': require('@lifesize/strings/guest/fr.json'),
  'it-IT': require('@lifesize/strings/guest/it.json'),
  'ja-JP': require('@lifesize/strings/guest/ja.json'),
  'ko-KR': require('@lifesize/strings/guest/ko.json'),
  'nl-NL': require('@lifesize/strings/guest/nl.json'),
  'no-NO': require('@lifesize/strings/guest/no.json'),
  'pl-PL': require('@lifesize/strings/guest/pl.json'),
  'pt-BR': require('@lifesize/strings/guest/pt.json'),
  'ru-RU': require('@lifesize/strings/guest/ru-RU.json'),
  'sv-SE': require('@lifesize/strings/guest/sv.json'),
  'zh-Hans': require('@lifesize/strings/guest/zh.json'),
  'zh-Hant': require('@lifesize/strings/guest/zh-Hant.json')
};

const changeLanguage = (language: any) => {
  lsSet(LsKey.Locale, language.id);
};

const getCookieLink = (): string => {
  return localization.cookieCompliance;
};

const getCurrentLanguageId = (): string => {
  return localization.languageId || DEFAULT_LOCALE;
};

const getHelpLink = (): string => {
  return localization.helpPageGuest;
};

const getAfterCallLink = (): string => {
  return localization.afterCallGuest;
};

const getLegacyLanguageId = (): string => {
  return localizationUtils.getLegacyLanguageId(getCurrentLanguageId()) || 'en';
};

const getPrivacyLink = (): string => {
  return localization.privacyPolicy;
};

const getTosLink = (): string => {
  return localization.termsOfService;
};

const getLanguageOptions = () => {
  return localization.languageOptions;
};

const loadGuestLocales = async (locale: string) => {
  const appLocale = locale || DEFAULT_LOCALE;
  new Promise(async (resolve) => {
    await intl.init({
      currentLocale: appLocale,
      locales,
      fallbackLocale: DEFAULT_LOCALE
    });
    Logger.info('Language initialized to: ' + appLocale);
    Logger.info('Language fallback initialized to: ' + DEFAULT_LOCALE);
    resolve(true);
  });
};

export {
  changeLanguage,
  getAfterCallLink,
  getCookieLink,
  getCurrentLanguageId,
  getHelpLink,
  getLanguageOptions,
  getLegacyLanguageId,
  getPrivacyLink,
  getTosLink,
  loadGuestLocales
};
