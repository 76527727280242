import { nucleusConstants } from '@lifesize/nucleus';
import { reconnectLogin } from 'actions/Auth.js';
import logger from 'utils/logger';

const connectivityMiddleware = (store) => (next) => async (action) => {
  next(action);
  switch (action.type) {
    case nucleusConstants.NETWORK_ONLINE:
      // Wait for confirmation of completion to ensure other middlewares don't try to log us out
      try {
        await store.dispatch(reconnectLogin());
      } catch (e) {
        logger.error('reconnectLogin() failed:', e);
      }
      break;
    default:
      break;
  }
};

export default connectivityMiddleware;
