export const CALL_DETAILS_DRAWER = 'CALL_DETAILS_DRAWER';
export const CHAT_DRAWER = 'CHAT_DRAWER';
export const COLLABORATION_DRAWER = 'COLLABORATION_DRAWER';
export const IN_CALL_DRAWER_ACTION_CLOSE = 'IN_CALL_DRAWER_ACTION_CLOSE';
export const IN_CALL_DRAWER_ACTION_OPEN = 'IN_CALL_DRAWER_ACTION_OPEN';
export const IN_CALL_DRAWER_ACTION_TOGGLE = 'IN_CALL_DRAWER_ACTION_TOGGLE';
export const IN_CALL_DRAWER_CHAT_SCROLL_POSITION = 'IN_CALL_DRAWER_CHAT_SCROLL_POSITION';
export const PARTICIPANTS_DRAWER = 'PARTICIPANTS_DRAWER';
export const REMOTE_GROUP_UUID_REQUEST = 'REMOTE_GROUP_UUID_REQUEST';
export const REMOTE_GROUP_UUID_SUCCESS = 'REMOTE_GROUP_UUID_SUCCESS';
export const REMOTE_GROUP_UUID_FAILURE = 'REMOTE_GROUP_UUID_FAILURE';
