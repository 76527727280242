import { createAsyncThunk } from '@reduxjs/toolkit';
import { media, signaling } from '@lifesize/clients.sdk';
import { vbbManager } from 'utils/vbbManager';
import { featureSelectors } from '@lifesize/nucleus';
import { FLAG_VIRTUAL_BACKGROUND } from 'constants/FeatureFlags';
import Logger from 'js-logger';
import { getConstraints } from 'utils/deviceUtils';
import { vbbSettingsSelector, isAcquiringSelector } from 'selectors/media';
import { isCallDisconnected } from 'utils/call-utils';
import { isMobile, isChrome } from 'utils/browser-utils';

const MAX_ACQUIRING_WAIT_ITERATION_LOOPS = 10;
const ACQUIRING_WAIT = 500; // time between attempts to stop webcam when busy acquiring

const acquiringDelay = (delay) => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(), delay);
  });
};

export const acquireWebcam = createAsyncThunk('acquire-webcam', async (input, thunkApi) => {
  const state = thunkApi.getState();
  const mediaSettings = state?.app?.mediaSettings;
  const vbbFeatureEnabled = featureSelectors.getFeatureFlag(state)(FLAG_VIRTUAL_BACKGROUND);
  const { selection: bgSelection, enabled: vbbEnabled } = vbbSettingsSelector(state);
  const backgroundIsSelected = bgSelection && bgSelection.id !== 'none';
  const defaultAudioMute = mediaSettings?.localAudioMuted;
  const defaultVideoMute = mediaSettings?.localVideoMuted;

  if (vbbFeatureEnabled && vbbEnabled && backgroundIsSelected && !defaultVideoMute && !isMobile() && isChrome()) {
    Logger.debug('attempting to capture stream & apply VBB', bgSelection);
    await vbbManager.enableVbb(thunkApi.dispatch, mediaSettings, bgSelection);
  } else {
    const constraints = input || getConstraints(mediaSettings);
    Logger.info('attempting to capture stream', constraints);
    await media.acquireWebcam(constraints);
    await media.setVideoMute(defaultVideoMute);
  }
  await media.setAudioMute(defaultAudioMute);
});

export const stopWebcam = createAsyncThunk('stop-webcam', async (input, thunkApi) => {
  const callState = signaling.selectors.call();
  if (isCallDisconnected(callState)) {
    try {
      // Cleanup only if out-of-call so that we don't bork existing stream
      let isAcquiring;
      let loopCount = 0;
      do {
        loopCount += 1;
        const state = thunkApi.getState();
        isAcquiring = isAcquiringSelector(state);
        if (isAcquiring) {
          // delay
          Logger.info('Delay stopping webcam because in the middle of acquiring');
          await acquiringDelay(ACQUIRING_WAIT);
        } else {
          const mediaSettings = state?.app?.mediaSettings;
          media.stopWebcam();
          // If VBB is not enabled, this is a no-op, so no harm calling it.
          // NOTE: the mediaSettings param here is ignored, since we do not re-acquire camera (due to second param)
          vbbManager.pauseVbb(mediaSettings, false);
        }
      } while (isAcquiring && loopCount < MAX_ACQUIRING_WAIT_ITERATION_LOOPS);
      if (isAcquiring && loopCount === MAX_ACQUIRING_WAIT_ITERATION_LOOPS) {
        Logger.info('Exhausted waiting period to stop...attempting to stop now');
        media.stopWebcam();
        // If VBB is not enabled, this is a no-op, so no harm calling it.
        // NOTE: the mediaSettings param here is ignored, since we do not re-acquire camera (due to second param)
        vbbManager.pauseVbb({}, false);
      }
    } catch (ex) {
      Logger.info('error stopping webcam', ex);
      throw ex;
    }
  } else {
    Logger.info('not releasing webcam due to call not being disconnected.  Call state: ', callState);
  }
});

export const setVideoMute = createAsyncThunk('set-video-mute', async (mute, thunkApi) => {
  const state = thunkApi.getState();
  const mediaSettings = state?.app?.mediaSettings;
  const vbbFeatureEnabled = featureSelectors.getFeatureFlag(state)(FLAG_VIRTUAL_BACKGROUND);
  const { selection: bgSelection, enabled: vbbEnabled } = vbbSettingsSelector(state);
  const backgroundIsSelected = bgSelection && bgSelection.id !== 'none';
  Logger.debug('set video mute:', mute);
  await media.setVideoMute(mute);
  if (vbbFeatureEnabled && vbbEnabled && backgroundIsSelected && !isMobile() && isChrome()) {
    if (mute) {
      Logger.debug('pausing VBB', bgSelection);
      await vbbManager.pauseVbb(mediaSettings, false);
    } else {
      Logger.debug('applying VBB', bgSelection);
      await vbbManager.enableVbb(thunkApi.dispatch, mediaSettings, bgSelection);
    }
  }
});
