import { FIRST_TIME_USER_SETTING } from 'constants/UserSettings';
import {
  SHOW_ONBOARDING,
  HIDE_ONBOARDING,
  DISABLE_ONBOARDING,
  SHOW_PERMISSION_PAGE,
  HIDE_PERMISSION_PAGE
} from 'constants/Onboarding';
import { userSettingsActions } from '@lifesize/nucleus';

let requestingPermission = false;

export function disableOnboarding() {
  return {
    type: DISABLE_ONBOARDING
  };
}

export function showOnboarding() {
  return {
    type: SHOW_ONBOARDING
  };
}

export function delayShowPermission() {
  requestingPermission = true;
  return (dispatch) => {
    setTimeout(() => {
      if (requestingPermission) {
        dispatch({ type: SHOW_PERMISSION_PAGE });
      }
    }, 3000);
  };
}

export function hidePermission() {
  requestingPermission = false;
  return {
    type: HIDE_PERMISSION_PAGE
  };
}

export function hideOnboarding() {
  return (dispatch) => {
    dispatch(userSettingsActions.updateCurrentUserSettings({ [FIRST_TIME_USER_SETTING]: false }));
    dispatch({
      type: HIDE_ONBOARDING
    });
  };
}

export default {
  showOnboarding,
  hideOnboarding,
  disableOnboarding,
  delayShowPermission,
  hidePermission
};
