import { createSlice } from '@reduxjs/toolkit';
import { lsGet, LsKey } from 'utils/localStorageUtils';
import { FLAG_GUEST_URL } from 'constants/FeatureFlags';
import { getExtension } from 'utils/guest-utils';

const initialState = {
  name: lsGet(LsKey.Name) || '',
  email: lsGet(LsKey.Email) || '',
  isEnabled: false,
  extension: getExtension(),
  notYouClicked: false,
  callAttempted: false,
  isKiosk: false,
  kioskId: '',
  extensionValidated: false,
  bypassSplash: window.location.href.includes('bypassSplash=true'),
  compliance: {
    acceptedTos: lsGet(LsKey.TosAccepted) || '',
    acceptedCookie: lsGet(LsKey.CookiesAccepted) || ''
  }
};

const guestFlowSlice = createSlice({
  name: 'guestFlow',
  initialState,
  reducers: {
    setGuestFlowEnabled(state, action) {
      return {
        ...state,
        isEnabled: action.payload?.[FLAG_GUEST_URL] || false
      };
    },
    acceptCookie(state, action) {
      return {
        ...state,
        compliance: {
          ...state.compliance,
          acceptedCookie: action.payload
        }
      };
    },
    acceptTOS(state, action) {
      return {
        ...state,
        compliance: {
          ...state.compliance,
          acceptedTos: action.payload
        }
      };
    },
    resetGuestFlowState(state) {
      return {
        ...state,
        notYouClicked: true,
        name: '',
        email: '',
        compliance: {
          acceptedTos: '',
          acceptedCookie: ''
        }
      };
    },
    setCallAttempted(state, action) {
      return {
        ...state,
        callAttempted: action.payload
      };
    },
    setBypassSplash(state, action) {
      return {
        ...state,
        bypassSplash: action.payload
      };
    },
    resetNotYouClicked(state, action) {
      return {
        ...state,
        notYouClicked: false
      };
    },
    setExtensionValidated(state, action) {
      return {
        ...state,
        extensionValidated: action.payload
      };
    },
    setExtension(state, action) {
      return {
        ...state,
        extension: action.payload
      };
    },
    setExtensionKiosk(state, action) {
      return {
        ...state,
        ...action.payload
      };
    }
  }
});

export const {
  setGuestFlowEnabled,
  acceptCookie,
  acceptTOS,
  resetGuestFlowState,
  setCallAttempted,
  setBypassSplash,
  resetNotYouClicked,
  setExtensionValidated,
  setExtension,
  setExtensionKiosk
} = guestFlowSlice.actions;
export default guestFlowSlice.reducer;
