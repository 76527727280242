import { Observer } from '@lifesize/web-clients-core';
import _get from 'lodash/get';
import callActions from 'actions/InCall/InCallVideo';
import { signaling } from '@lifesize/clients.sdk';

const data = {
  popoutPresentation: false
};

const popoutPresentationSelector = (state) => _get(state, 'app.inCallVideo.videoLayout.popoutPresentation');

const newStateHandler = (prevState, nextState) => {
  data.popoutPresentation = nextState;
  const oldState = { popoutPresentation: prevState };
  const newState = { popoutPresentation: nextState };
  window.desktopApp.onStateChanged(oldState, newState);
};

export const handlerMethods = [popoutPresentationSelector, data, newStateHandler];
export default Observer.createObserver(...handlerMethods);

export function enableDesktopObservers() {
  signaling.subscribeToCallConnected(callConnectedHandler);
  signaling.subscribeToLocalPresentationStream(localPresentationHandler);
  signaling.subscribeToRemotePresentationStream(remotePresentationHandler);
}

function callConnectedHandler(nextState, prevState) {
  // call disconnected, hide captivo popout.
  if (!nextState && prevState) {
    closeCaptivoPopout();
  }
  window.desktopApp.onStateChanged({ callFullyConnected: prevState }, { callFullyConnected: nextState });
}

function localPresentationHandler(nextState, prevState) {
  const oldState = { isPresenting: prevState !== null };
  const newState = { isPresenting: nextState !== null };
  window.desktopApp.onStateChanged(oldState, newState);
}

function remotePresentationHandler(nextState, prevState) {
  if (prevState && nextState && data.popoutPresentation) {
    window.lifesize.store.dispatch(callActions.togglePopout(false));
    window.lifesize.store.dispatch(callActions.waitingToRepop(true));
  }
  const oldState = { hasIncomingPresentation: prevState !== null };
  const newState = { hasIncomingPresentation: nextState !== null };
  window.desktopApp.onStateChanged(oldState, newState);
}

function closeCaptivoPopout() {
  window.desktopApp.closeCaptivoPopout();
}
