export const CONTACT_MODAL = 'CONTACT_MODAL';
export const CONTACT_FORM_ERROR = 'CONTACT_FORM_ERROR';
export const CLEAR_CONTACT_ERROR_STATE = 'CLEAR_CONTACT_ERROR_STATE';
export const SELECT_MODAL_CONTACT = 'SELECT_MODAL_CONTACT';

export const LECTURER_SEARCH_FORM = 'lecturerSearch';
export const LECTURER_SEARCH_FORM_FOCUSED = 'LECTURER_SEARCH_FORM_FOCUSED';
export const LECTURER_SELECT = 'LECTURER_SELECT';
export const LECTURER_UNSELECT = 'LECTURER_UNSELECT';

export const MODERATOR_SEARCH_FORM = 'moderatorSearch';
export const MODERATOR_SEARCH_FORM_FOCUSED = 'MODERATOR_SEARCH_FORM_FOCUSED';
export const MODERATOR_SELECT = 'MODERATOR_SELECT';
export const MODERATOR_UNSELECT = 'MODERATOR_UNSELECT';
export const MODERATOR_RESET = 'MODERATOR_RESET';
