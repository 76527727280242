import _get from 'lodash/get';
import { nucleusConstants, initActions } from '@lifesize/nucleus';

const userSettingsMiddleware = (store) => (next) => (action) => {
  if (action.type === nucleusConstants.REDUX_STORAGE_LOAD) {
    // client determined manner of setting forceTcpTunneling
    const forceTcpTunneling = _get(action, 'payload.nucleus.userSettings.enableTcpTunneling');
    const maxBandwidth = _get(action, 'payload.nucleus.galaxy.options.maxBandwidth');
    if (maxBandwidth || forceTcpTunneling) {
      const options = {
        maxBandwidth,
        forceTcpTunneling
      };
      store.dispatch(initActions.initialize(options));
    }
  }
  return next(action);
};

export default userSettingsMiddleware;
