import {
  SHOULD_DISPLAY_PRIMARY_VIDEO,
  SHOULD_DISPLAY_SECONDARY_VIDEO,
  TOGGLE_MINIPLAYER_VIDEO,
  TOGGLE_DIAL_PAD,
  TOGGLE_CAMERA_CONTROLS,
  VIDEO_LAYOUT_CHANGE,
  TOGGLE_POPOUT,
  TURTLE_DRAG_START,
  TURTLE_DRAG_STOP,
  WAITING_TO_REPOP,
  miniplayerMode,
  VIDEO_PRIMARY,
  VIDEO_SECONDARY,
  defaultConfig,
  TOGGLE_MINIMIZED
} from 'constants/InCall/InCallVideo';
import { getVideoConfig } from 'actions/InCall/InCallVideo';
import { nucleusConstants } from '@lifesize/nucleus';
import _get from 'lodash/get';

export const initialState = () => ({
  isMinimized: false,
  shouldDisplayPrimaryVideo: true,
  shouldDisplaySecondaryVideo: true,
  playbackBlocked: true,
  showDialPad: false,
  showCameraControls: false,
  slide: null,
  isDragging: false,
  presentationAvailable: false,
  waitingToRepop: false,
  miniplayerMode: miniplayerMode.remote,
  videoLayout: {
    ...defaultConfig,
    popoutPresentation: false,
    isDualStreaming: false,
    container: defaultConfig.defaultContainer,
    leftBounds: defaultConfig.defaultLeftBound,
    rightBounds: defaultConfig.defaultContainer.width - defaultConfig.minVideoWidth - defaultConfig.turtleHalfWidth,
    showTurtle: true,
    splitPosition: defaultConfig.defaultSplit,
    videos: {
      [VIDEO_SECONDARY]: {
        ratio: defaultConfig.defaultAspect
      },
      [VIDEO_PRIMARY]: {
        ratio: defaultConfig.defaultAspect
      }
    }
  }
});

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [nucleusConstants.CONFERENCE_SLIDE_UPDATE]: (state, action) => Object.assign({}, state, { slide: action.payload }),
  [nucleusConstants.VIDEO_PLAYBACK_BLOCKED]: (state) => ({ ...state, playbackBlocked: true }),
  [nucleusConstants.VIDEO_PLAYBACK_STARTED]: (state) => ({ ...state, playbackBlocked: false }),
  [SHOULD_DISPLAY_PRIMARY_VIDEO]: (state, action) => {
    const nextState = Object.assign({}, state);
    nextState.shouldDisplayPrimaryVideo = _get(action, 'payload.shouldDisplay');
    return nextState;
  },
  [SHOULD_DISPLAY_SECONDARY_VIDEO]: (state, action) => {
    const nextState = Object.assign({}, state);
    nextState.shouldDisplaySecondaryVideo = _get(action, 'payload.shouldDisplay');
    return nextState;
  },
  [TURTLE_DRAG_START]: (state) => Object.assign({}, state, { isDragging: true }),
  [TURTLE_DRAG_STOP]: (state) => Object.assign({}, state, { isDragging: false }),
  [nucleusConstants.GALAXY_ACTION_INCOMING_PRESENTATION]: (state) => {
    const nextState = Object.assign({}, state);
    const videoLayout = nextState.videoLayout;
    videoLayout.isDualStreaming = true;
    nextState.presentationAvailable = true;
    videoLayout.videos = getVideoConfig(videoLayout);
    nextState.videoLayout = videoLayout;
    return nextState;
  },
  [nucleusConstants.GALAXY_ACTION_RX_STOPPED_PRESENTATION]: (state) => {
    const nextState = Object.assign({}, state);
    const videoLayout = nextState.videoLayout;
    videoLayout.isDualStreaming = false;
    videoLayout.popoutPresentation = false;
    nextState.presentationAvailable = false;
    videoLayout.videos = getVideoConfig(videoLayout);
    nextState.videoLayout = videoLayout;
    return nextState;
  },
  [nucleusConstants.GALAXY_ACTION_REMOTE_PRESENTATION_STOPPED]: (state) => {
    const nextState = Object.assign({}, state);
    const videoLayout = nextState.videoLayout;
    videoLayout.isDualStreaming = false;
    videoLayout.popoutPresentation = false;
    nextState.presentationAvailable = false;
    videoLayout.videos = getVideoConfig(videoLayout);
    nextState.videoLayout = videoLayout;
    return nextState;
  },
  [nucleusConstants.RTC_REMOTE_STREAM_REMOVED]: () => initialState(),
  [nucleusConstants.GALAXY_ACTION_END]: () => initialState(),
  [nucleusConstants.GALAXY_ACTION_FAILED]: () => initialState(),
  [TOGGLE_DIAL_PAD]: (state) => {
    const nextState = Object.assign({}, state);
    nextState.showDialPad = !state.showDialPad;
    return nextState;
  },
  [TOGGLE_CAMERA_CONTROLS]: (state) => {
    const nextState = Object.assign({}, state);
    nextState.showCameraControls = !state.showCameraControls;
    return nextState;
  },
  [TOGGLE_POPOUT]: (state, action) => {
    const popoutPresentation = !!action.payload;

    return {
      ...state,
      videoLayout: {
        ...state.videoLayout,
        popoutPresentation,
        videos: getVideoConfig({ ...state.videoLayout, popoutPresentation })
      }
    };
  },
  [VIDEO_LAYOUT_CHANGE]: (state, action) => {
    const nextState = Object.assign({}, state);
    nextState.videoLayout = Object.assign(nextState.videoLayout, _get(action, 'payload'));
    return nextState;
  },
  [TOGGLE_MINIPLAYER_VIDEO]: (state) => ({
    ...state,
    miniplayerMode: state.miniplayerMode === miniplayerMode.remote ? miniplayerMode.local : miniplayerMode.remote
  }),
  [WAITING_TO_REPOP]: (state, action) => ({ ...state, waitingToRepop: action.payload }),
  [TOGGLE_MINIMIZED]: (state) => Object.assign({}, state, { isMinimized: !state.isMinimized })
};

// ------------------------------------
// Reducer
// ------------------------------------

export default function inCallVideoReducer(state = initialState(), action = {}) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
