import {
  SETTINGS_TAB_TOGGLE,
  AV_TAB,
  EDIT_PROFILE_NAME,
  SETTINGS_AUDIO_MUTED,
  SETTINGS_VIDEO_MUTED
} from 'constants/SettingsModal';

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [SETTINGS_TAB_TOGGLE]: (state, action) => {
    const nextState = Object.assign({}, state);
    nextState.visibleTab = action.payload;
    return nextState;
  },
  [EDIT_PROFILE_NAME]: (state, action) => {
    const nextState = Object.assign({}, state);
    nextState.isEditingProfileName = action.payload;
    return nextState;
  },
  [SETTINGS_AUDIO_MUTED]: (state, action) => {
    localStorage.setItem('defaultAudioSetting', action.payload);
    return Object.assign({}, state, { defaultAudioSetting: action.payload });
  },
  [SETTINGS_VIDEO_MUTED]: (state, action) => {
    localStorage.setItem('defaultVideoSetting', action.payload);
    return Object.assign({}, state, { defaultVideoSetting: action.payload });
  }
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  visibleTab: AV_TAB,
  isEditingProfileName: false,
  defaultAudioSetting:
    localStorage.getItem('defaultAudioSetting') === null
      ? true
      : localStorage.getItem('defaultAudioSetting') === 'true',
  defaultVideoSetting:
    localStorage.getItem('defaultVideoSetting') === null ? true : localStorage.getItem('defaultVideoSetting') === 'true'
};
export default function SettingsModalReducer(state = initialState, action = {}) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
