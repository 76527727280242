import {
  IN_CALL_DRAWER_ACTION_CLOSE,
  IN_CALL_DRAWER_ACTION_OPEN,
  IN_CALL_DRAWER_ACTION_TOGGLE,
  IN_CALL_DRAWER_CHAT_SCROLL_POSITION,
  REMOTE_GROUP_UUID_REQUEST,
  REMOTE_GROUP_UUID_SUCCESS,
  REMOTE_GROUP_UUID_FAILURE
} from 'constants/InCall/InCallDrawer';

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [IN_CALL_DRAWER_ACTION_CLOSE]: (state) => {
    return {
      ...state,
      subComponentType: null
    };
  },
  [IN_CALL_DRAWER_ACTION_TOGGLE]: (state, action) => {
    if (!action.payload.subComponentType) return state;
    if (action.payload.subComponentType === state.subComponentType) {
      return {
        ...state,
        subComponentType: null
      };
    }
    const payload = action.payload;
    return {
      ...state,
      ...payload
    };
  },
  [IN_CALL_DRAWER_ACTION_OPEN]: (state, action) => {
    if (!action.payload.subComponentType) return state;
    const payload = action.payload;
    return {
      ...state,
      ...payload
    };
  },
  [IN_CALL_DRAWER_CHAT_SCROLL_POSITION]: (state, action) => {
    if (!action.payload.conversationContactDialString) return state;
    return {
      ...state,
      chatScrollBookmark: {
        [action.payload.conversationContactDialString]: action.payload.settings
      }
    };
  },
  [REMOTE_GROUP_UUID_REQUEST]: (state) => {
    return {
      ...state,
      remoteGroupUuid: '',
      remoteGroupUuidError: ''
    };
  },
  [REMOTE_GROUP_UUID_SUCCESS]: (state, action) => {
    return {
      ...state,
      remoteGroupUuid: action.payload
    };
  },
  [REMOTE_GROUP_UUID_FAILURE]: (state, action) => {
    return {
      ...state,
      remoteGroupUuidError: action.payload
    };
  }
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = { chatScrollBookmark: {}, remoteGroupUuid: '', remoteGroupUuidError: '', subComponentType: null };
export default function modalReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
