import { VirtualBackgroundRenderer } from '@lifesize/virtual-background/dist/base';
import Logger from 'js-logger';
import { setEnabled, setEnabling, setBackground, bgSelectionType } from 'reducers/vbbSettingsSlice';
// @ts-ignore
import { media } from '@lifesize/nucleus';
import { MediaConfig, getConstraints } from 'utils/deviceUtils';

// NOTE: below are the render options used for @lifesize/virtual-background@0.2.6
// If you revert the package to that version, it's recommended to use the below settings.
// const defaultRenderOptions = {
//   minBodypixFramePeriod: 500,
//   minFramePeriod: 0,
//   backgroundColor: '#444',
//   backgroundMode: 'contain' // can also be 'fill' and 'cover'
// };

const VBB_INIT_TIMEOUT = 8000; // 8 second timeout

const vbbTimeout = (delay, message = 'Timeout') => {
  return new Promise((resolve, reject) => {
    setTimeout(() => reject(message), delay);
  });
};

class VbbManager {
  private static _instance: VbbManager;

  #vbRenderer?: VirtualBackgroundRenderer;
  #vbTrack?: MediaStreamTrack;
  #initialized = false;
  #processingVbbEnable = false;

  public videoTrackId?: string;

  private constructor() {}

  public static getInstance(): VbbManager {
    if (!this._instance) {
      this._instance = new VbbManager();
    }

    return this._instance;
  }

  private async setBackgroundImage(bgSelection: bgSelectionType) {
    const src = bgSelection.path || bgSelection.dataUrl;

    if (this.#vbRenderer === undefined) {
      Logger.error('VbRenderer is undefined when trying to assign background!');
      return;
    }

    // JEFFTODO: constants, or a better way to do this.
    if (bgSelection.id === 'blur') {
      await this.#vbRenderer.setBackground(5);
      return;
    }

    // convert src (path or dataUrl) to ImageData (there will be a src for all except 'none' && 'blur')
    // note: unfortunately we cannot store ImageData in the redux state (serialization error), so we have to fetch it each time
    if (src) {
      const img = new Image();

      img.onload = () => {
        const canvas = new OffscreenCanvas(img.width, img.height);
        const context = canvas.getContext('2d');

        if (context === null) {
          Logger.error('Could not acquire canvas to set bg image!');
          return;
        }

        context.drawImage(img, 0, 0); // currently, we are drawing the full image (not cropping or resizing), so the width and height are not necessary
        this.#vbRenderer?.setBackground(context.getImageData(0, 0, img.width, img.height));
      };

      img.src = src;
    }
  }

  // This is to be called by the client component after they choose one of the image options
  public async chooseBackgroundSetting(
    dispatch: any,
    mediaSettings: MediaConfig,
    videoMuted: boolean,
    vbbEnabled: boolean,
    bgSelection: bgSelectionType,
    saveAsDefault: boolean
  ) {
    //
    await dispatch(setBackground({ bgSelection, saveAsDefault }));

    // If video is currently muted, then we don't need to do anything more right now.
    if (videoMuted) {
      Logger.debug('Video currently muted, setting value and returning.');
      dispatch(setEnabled(bgSelection.id !== 'none')); // JEFFTODO: clean up that check
      return;
    }

    if (bgSelection.id === 'none') {
      if (vbbEnabled) {
        Logger.debug('Disabling VBB...');
        // Always re-acquire webcam
        await this.disableVbb(dispatch, mediaSettings, true);
      } else {
        Logger.debug('Vbb not enabled, nothing to do.');
      }
      return;
    }

    if (!vbbEnabled) {
      Logger.debug('VBB not yet enabled, enabling!');
      await this.enableVbb(dispatch, mediaSettings, bgSelection);
    } else {
      Logger.debug('VBB already enabled, updating background image.');
      await this.updateVbb(bgSelection);
    }
  }

  public async initializeVbb() {
    if (!this.#initialized) {
      Logger.debug('Initializing...');
      // model file is copied from vbb/dist folder.
      // VBB lib requires model files to be hosted on webclient before VBB is initialized.
      await VirtualBackgroundRenderer.setup({
        modelUrl: `${
          process.env.NODE_ENV === 'production' ? process.env.PUBLIC_URL : window.location.origin
        }/vb-w.4d22ecba.model`,
        simdModelUrl: `${
          process.env.NODE_ENV === 'production' ? process.env.PUBLIC_URL : window.location.origin
        }/vb-s.42dd7cc2.model`
      });
      this.#initialized = true;
      Logger.debug('Init done.');
    }
  }

  public getCurrentVideoTrackId() {
    return this.#vbTrack?.id;
  }

  public setVbbEnabledWithoutStarting(dispatch: any) {
    // This is similar to if you called "enableVbb()" and immediately called "pauseVbb()"
    dispatch(setEnabled(true));
  }

  private async setupVbb(mediaSettings, renderOptions) {
    await this.initializeVbb();

    // Reaquire at a lower resolution
    // If we haven't got an active vbRenderer, reacquire camera as normal.
    if (!this.#vbRenderer) {
      await this.reAcquireCamera({ ...mediaSettings, resolution: '360p' });
    } else {
      // If renderer exists, do via "pause" action to ensure proper disposal of previous stream
      // (which will dispose of old device if we're now on a different device)
      await this.pauseVbb({ ...mediaSettings, resolution: '360p' }, true);
    }
    const localPrimaryStream = media.getPrimaryStream();

    // Create the new stream
    Logger.debug('Getting video tracks.');
    this.#vbTrack = localPrimaryStream.getVideoTracks()[0];
    const originalLabel = this.#vbTrack?.label;

    if (this.#vbTrack === undefined) {
      Logger.error('Received an undefined track!', this.#vbTrack);
      return;
    }

    // Create renderer
    Logger.debug('Creating renderer.');
    this.#vbRenderer = new VirtualBackgroundRenderer(this.#vbTrack);
    // issues can happen if you don't get the track right after making the vbRenderer
    // NOTE: This new video track /will have a different device label than the original one!/
    //       That means anything that tries to match devies by the label will fail, e.g. camera picker.
    Logger.debug('Getting VBB video track.');
    const newVideo = await this.#vbRenderer.getTrack();

    Logger.debug('tracks retrieved', this.#vbTrack, this.#vbRenderer);

    // Use any new VBB Render options if provided.
    if (renderOptions) {
      this.#vbRenderer.setOptions(renderOptions);
    }

    return {
      localPrimaryStream,
      originalLabel,
      newVideo
    };
  }

  public async enableVbb(dispatch: any, mediaSettings: MediaConfig, bgSelection: bgSelectionType, renderOptions?: any) {
    try {
      // TODO: if vbb already enabled, do we do it again?
      if (this.#processingVbbEnable) {
        Logger.warn('Already processing an enableVbb event; skipping');
        return;
      }

      // Below, we set the value in the store as well as a local flag.
      // We do this since other calls to enableVbb may happen at the same time.
      this.#processingVbbEnable = true;
      dispatch(setEnabling(true)); // So other things in the store are aware of it

      // @ts-ignore
      const { localPrimaryStream, originalLabel, newVideo } = await Promise.race([
        this.setupVbb(mediaSettings, renderOptions),
        vbbTimeout(VBB_INIT_TIMEOUT, 'TIMEOUT: Failed to setup VBB track in time')
      ]);

      // Set the new stream
      Logger.debug('removing tracks and setting new');
      localPrimaryStream.removeTrack(this.#vbTrack);

      // Set bg
      await this.setBackgroundImage(bgSelection);

      Logger.debug('gonna add track', newVideo);
      localPrimaryStream.addTrack(newVideo);

      // Notify clients.sdk
      Logger.debug('notifying of stream change');
      await media.setStream(localPrimaryStream, originalLabel);

      // Keep track (har har) of the track id
      this.videoTrackId = localPrimaryStream?.getVideoTracks()?.[0]?.id;

      dispatch(setEnabled(true));
      dispatch(setEnabling(false));
      this.#processingVbbEnable = false;
    } catch (err) {
      Logger.error('Error encountered, reverting', err);
      await this.disableVbb(dispatch, mediaSettings);
      await dispatch(setBackground({ bgSelection: { id: 'none' }, saveAsDefault: true }));
      await dispatch(setEnabling(false));
      this.#processingVbbEnable = false;
    }
  }

  // Will update the existing background selection live
  public async updateVbb(bgSelection: bgSelectionType) {
    Logger.debug('Setting background image...', bgSelection.id);
    await this.setBackgroundImage(bgSelection);
    Logger.debug('Background now set.');
  }

  public async disableVbb(dispatch: any, mediaSettings: MediaConfig, reInitCamera = true) {
    try {
      await this.pauseVbb(mediaSettings, reInitCamera);
    } catch (pauseErr) {
      Logger.error('pausing VBB failed', pauseErr);
    }
    dispatch(setEnabled(false));
  }

  public async pauseVbb(mediaSettings: MediaConfig, reInitCamera = true) {
    // Do nothing if never enabled
    if (!this.#initialized || !this.#vbRenderer) return;

    try {
      Logger.debug('destroying existing renderer');
      await this.#vbRenderer.destroy();

      // Release the video track to turn off camera light
      if (this.#vbTrack) {
        this.#vbTrack.stop();
      }
      this.#vbRenderer._streamIn?.getTracks()?.[0]?.stop();
      this.#vbRenderer._trackIn?.stop();
      this.#vbRenderer._streamIn = null;
      this.#vbRenderer._trackIn = null;
      this.#vbTrack = undefined;
    } catch (e) {
      Logger.warn('attempting to pause vbb threw exception, may be ok', e);
    }
    if (reInitCamera) {
      await this.reAcquireCamera(mediaSettings);
    }
  }

  public async teardownVbb(mediaSettings: MediaConfig, reInitCamera = true) {
    // Do nothing if never enabled
    if (!this.#initialized) return;

    Logger.debug('Tearing down VbbManager.');
    await VirtualBackgroundRenderer.teardown();

    if (reInitCamera) {
      await this.reAcquireCamera(mediaSettings);
    }

    // Release the video track to turn off camera light
    if (this.#vbTrack) {
      this.#vbTrack.stop();
    }

    this.#vbTrack = undefined;
    this.#initialized = false;
  }

  public async handleCameraChange(
    dispatch: any,
    videoMuted: boolean,
    vbbEnabled: boolean,
    bgSelection: bgSelectionType,
    mediaSettings: any
  ) {
    // If video muted, we don't need to do anything
    if (!vbbEnabled || videoMuted) return;

    // Otherwise, video unmuted and vbb enabled - let's reacquire camera.
    Logger.debug('Enabling vbb on video unmute...', bgSelection.id);
    await this.enableVbb(dispatch, mediaSettings, bgSelection);
  }

  private async reAcquireCamera(mediaSettings: MediaConfig) {
    Logger.debug('reacquiring camera', media, mediaSettings);
    await media.acquireWebcam(getConstraints(mediaSettings));
  }
}

const vbbInstance = VbbManager.getInstance();

export { vbbInstance as vbbManager };
