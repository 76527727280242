import { isWindows81 } from './browser-utils';
import { highQualityHardwareConcurrencyLimit } from 'constants/Application';
import { signaling } from '@lifesize/clients.sdk';
import { featureSelectors } from '@lifesize/nucleus';
import { FLAG_AUDIO_PRESENTATION } from 'constants/FeatureFlags';

export const configureSignalingReceiveStreams = () => {
  const receiveStreams = getMaximumReceiveStreams();
  signaling.configure({ receiveStreams });
};

export const configureAudioInPresentation = (state) => {
  const audioInPresentation =
    localStorage.getItem('audioInPresentation') === 'true' ||
    featureSelectors.getFeatureFlag(state)(FLAG_AUDIO_PRESENTATION);
  signaling.configure({ audioInPresentation });
};

export function getMaximumReceiveStreams() {
  // local storage override
  const receiveStreams = parseInt(localStorage.getItem('receiveStreams') || '');
  if (receiveStreams) return receiveStreams;

  if (navigator.hardwareConcurrency <= highQualityHardwareConcurrencyLimit) {
    return 1;
  }

  return 9;
}

export function getPresentationMaxFrameRate() {
  // Set max presentation framerate to 30 for CPUs with more than 4 virtual cores.
  if (navigator.hardwareConcurrency <= highQualityHardwareConcurrencyLimit) {
    return 5; // Set max presentation framerate to 5 for CPUs with 4 virtual cores or less
  }
  return 30;
}

export function getCameraMaxFrameRate() {
  if (navigator.hardwareConcurrency <= highQualityHardwareConcurrencyLimit) {
    return 24;
  }
  return 30;
}

export function getRtcPeerConnectionOptionalConstraints() {
  const highEncodeUsageThresholdPercent =
    navigator.hardwareConcurrency <= highQualityHardwareConcurrencyLimit ? 40 : 60;
  const lowEncodeUsageThresholdPercent = (highEncodeUsageThresholdPercent - 1) / 2;
  return [
    { googCpuOveruseDetection: true },
    { googCpuOveruseEncodeUsage: true },
    { googCpuUnderuseThreshold: lowEncodeUsageThresholdPercent },
    { googCpuOveruseThreshold: highEncodeUsageThresholdPercent }
  ];
}

export function getChromeDisplayConstraints(): MediaStreamConstraints {
  return {
    audio: false,
    video: {
      width: { max: 1920, ideal: 1920 },
      height: { max: 1080, ideal: 1080 },
      frameRate: { max: getPresentationMaxFrameRate() }
    }
  };
}

export function getChromeDesktopConstraints(sourceId, includeAudio = false) {
  let audio: any = false;
  if (includeAudio) {
    audio = {
      mandatory: {
        chromeMediaSource: 'desktop'
      }
    };
  }
  return {
    audio,
    video: {
      mandatory: {
        chromeMediaSource: 'desktop',
        chromeMediaSourceId: sourceId,
        maxWidth: 1920,
        maxHeight: 1080,
        maxFrameRate: getPresentationMaxFrameRate()
      }
    }
  };
}

// eslint-disable-next-line no-undef
export function getDefaultVideoTrackConstraints(defaultToLowRes): MediaTrackConstraints {
  // Note: don't define framerate in the default constraints here or the user will not be able to persists any setting
  // of 'Auto fps'
  // For Windows 8.1 do not use ideal constraints as this break some of the old generic camera drivers
  if (isWindows81()) {
    if (defaultToLowRes) {
      return {
        // 360p - Auto fps
        width: { max: 640, min: 320 },
        height: { max: 360, min: 180 },
        frameRate: { max: getCameraMaxFrameRate() }
      };
    }
    return {
      width: { max: 1280, min: 320 },
      height: { max: 720, min: 240 },
      frameRate: { max: getCameraMaxFrameRate() }
    };
  }
  if (defaultToLowRes) {
    return {
      // 360p - Auto fps
      width: { ideal: 640, max: 640, min: 320 },
      height: { ideal: 360, max: 360, min: 180 },
      frameRate: { max: getCameraMaxFrameRate() }
    };
  }
  // For everything else use 720p as the default
  return {
    width: { ideal: 1280, max: 1280, min: 320 },
    height: { ideal: 720, max: 720, min: 240 },
    frameRate: { max: getCameraMaxFrameRate() }
  };
}
