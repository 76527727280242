import {
  featureFlagServiceApiKey,
  Extension,
  fetchExtensionsUrl,
  fetchFeatureFlagServiceUrl,
  otherWaysUrl,
  webAppUrl,
  desktopLauncherUrl
} from 'constants/Guest';
import intl from 'react-intl-universal';
import { isChrome, isMobile } from 'utils/browserDetection';
import { isIosMobile, isSafari } from 'utils/browser-utils';
import { getAfterCallLink, getLegacyLanguageId } from 'utils/guest-localeUtil';

const getExtension = () => {
  //Check the url for extension I.E. guest.lifesizebeta.com/123456, guest.lifesizebeta.com/?ext=123456, https://webmodule.lifesizecloud.com/2.6.3-79085/?action=join&extension=8763915&name=My+Name
  const href = window.location.href.includes('reroute')
    ? window.location.href.replace('#reroute', '')
    : window.location.href;
  const url = new URL(href);
  const extensionPath = url.pathname.match(/\/(\d+)/i);
  const extensionFromPath = extensionPath ? extensionPath[1] : '';
  return url.searchParams.get('extension') || url.searchParams.get('ext') || extensionFromPath || '';
};

const getKioskId = () => {
  // to process the URL Parameter from Kiosk Route
  const href = window.location.href.includes('reroute')
    ? window.location.href.replace('#reroute', '')
    : window.location.href;
  const url = new URL(href);
  return url.searchParams.get('id');
};

const getName = () => {
  //Check the url for name I.E. lifesizebeta.com/join/123456?name=guestName
  const url = new URL(window.location.href);
  return url.searchParams.get('name') || '';
};

const getDesktopJoinUrl = (extension, name) => {
  const newURL = new URL(window.location.pathname, window.location.origin);
  newURL.searchParams.set('action', 'join');
  newURL.searchParams.set('extension', extension);
  if (name) {
    newURL.searchParams.set('name', name);
  }
  return newURL.href;
};

const isExtensionValid = async (extension: string) => {
  const url = `${fetchExtensionsUrl}${extension}`;
  const response: Extension = {
    error: null,
    isValid: false,
    groupUUID: null,
    value: extension
  };
  if (!extension) return response;
  try {
    const data = await fetch(url);
    const dataJSON = await data.json();
    response.isValid = !!dataJSON?.valid || false;
    response.groupUUID = dataJSON?.id || null;
    return response;
  } catch (error) {
    response.error = error;
    return response;
  }
};

const getFeatureFlags = async (featureFlags: string) => {
  const response = {
    error: null,
    featureFlags: null
  };
  const url = `${fetchFeatureFlagServiceUrl}${featureFlags}`;
  try {
    const data = await fetch(url, {
      headers: {
        'x-api-key': featureFlagServiceApiKey
      }
    });
    response.featureFlags = await data.json();
    return response;
  } catch (error) {
    response.error = error;
    return response;
  }
};

const validateName = (newValue: string): string => {
  return !newValue || newValue.length === 0 || (!!newValue && newValue.length < 33) ? '' : intl.get('nameLengthError');
};

const validateEmail = (newValue: string): string => {
  if (
    !newValue ||
    newValue.length === 0 ||
    (!!newValue && /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,5})+$/.test(newValue))
  ) {
    return '';
  }
  return intl.get('invalidEmailError');
};

const getJoinUrl = (extension: string | null): string => {
  const locale = getLegacyLanguageId();
  if (isMobile() || isIosMobile()) {
    // If mobile, route to other ways to call
    // If no extension provided, give user more options with downloads page
    return extension ? `${otherWaysUrl}otherways/${extension}` : `${otherWaysUrl}downloads`;
  }
  if (isChrome()) {
    return extension ? `${webAppUrl}join/${extension}&lang=${locale}` : `${webAppUrl}&lang=${locale}`;
  }
  if (isSafari()) {
    return extension
      ? `${desktopLauncherUrl}?ext=${extension}&forceDownloadOnFail=true`
      : `${desktopLauncherUrl}?forceDownloadOnFail=true`;
  }
  // If desktop, route to desktop launcher (for all non-chrome browser)
  return extension ? `${desktopLauncherUrl}?ext=${extension}` : desktopLauncherUrl;
};

const isKioskRoute = () => {
  const isKiosk = window?.location?.href.includes('isKiosk');
  return !!isKiosk;
};

// Added hash check to enable guest flow in branch deployment.
const isGuestRoute = () =>
  window?.location?.pathname.split('/').indexOf('guest') >= 0 ||
  (window.location.hash.includes('reroute') && window.location.hash.includes('/guest'));

const getCurrentLanguage = () => {
  let currentLangauge;
  const currentURL = window.location.href.split('/');
  currentLangauge = currentURL[currentURL.length - 1];
  return currentLangauge;
};

const navigateToTrialPage = () => {
  return new Promise((resolve) => {
    window.onbeforeunload = (e: BeforeUnloadEvent) => {
      // Guarantee the browser unload by removing the returnValue property of the event
      delete e['returnValue'];
      resolve();
    };
    // if (lsGet(LsKey.SkipTrialNavigationForTest)) window.location.assign(window.location.href);
    window.location.assign(getAfterCallLink());
  });
};

export {
  getDesktopJoinUrl,
  getExtension,
  getFeatureFlags,
  getJoinUrl,
  getName,
  isExtensionValid,
  isGuestRoute,
  isKioskRoute,
  navigateToTrialPage,
  validateEmail,
  validateName,
  getKioskId,
  getCurrentLanguage
};
