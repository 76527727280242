import { nucleusConstants, authActions, currentUserSelectors /*, servicesActions */ } from '@lifesize/nucleus';
import { loadUrl, getLogoutDomain } from 'utils/UrlUtil';
import { googleSignout } from 'api/google-api';
import { logout as azureLogout } from 'api/azure-api';
import lsConfig from 'utils/global-config';
import { calendarSignedOut } from 'actions/Calendar';
import { calendars } from 'constants/Calendar';
import _get from 'lodash/get';
import logger from 'utils/logger';
import get from 'lodash/get';
import Ecommerce from 'utils/ecommerce';
import { setShouldDisplayUpgradeLink } from 'actions/FlagsActions';
import analytics from 'utils/analytics';
import { signaling } from '@lifesize/clients.sdk';
import { accountSelectors } from '@lifesize/nucleus';
// import { RECHECKIN_AFTER_CALL } from 'constants/Auth';

const authMiddleware = (store) => (next) => async (action) => {
  const result = next(action);
  const state = store.getState();
  const isDesktop = !!_get(window, ['lifesize', 'desktopApp']);

  // Clear out URI's a-token after login for webclient
  if (action.type === nucleusConstants.INITIALIZE_SERVICES_SUCCESS && !isDesktop) {
    const uri = new URL(window.location.href);
    uri && uri.searchParams && uri.searchParams.delete('a');
    window.history.replaceState({}, document.title, uri);
  }

  /* PT: Temporarily disabled to prevent ddos'ing ourselves
  if (action.type === nucleusConstants.ACCOUNT_SETTING_UPDATED) {
    if (signaling.selectors.callConnected()) {
      // If call active, set flag for use in call-end observer, so we don't interrupt ongoing call
      store.dispatch({ type: RECHECKIN_AFTER_CALL, payload: true });
    } else {
      // Otherwise, perform the recheckin immediately
      store.dispatch(servicesActions.accountRecheckin());
    }
  }
  */

  if (action.type === nucleusConstants.LOGOUT_FINISHED) {
    if (!isDesktop) {
      try {
        const googleAuthorized = _get(state, 'app.calendar.google.calendarSignedIn');
        const outlookAuthorized = _get(state, 'app.calendar.outlook.calendarSignedIn');
        if (googleAuthorized) {
          await googleSignout(store.getState());
        }

        if (outlookAuthorized) {
          /*
             To keep logout experience smooth,
             Close the popout window which appears when logging out of app & outlook.
          */
          store.dispatch(calendarSignedOut(calendars.outlook));
          const outlookLogoutWindow = azureLogout();
          setTimeout(() => {
            // Give microsoft a chance to perform their logout.
            outlookLogoutWindow.close();
            lsConfig.skipOnBeforeUnload();
            loadUrl(getLogoutDomain());
          }, 1000);
        } else {
          lsConfig.skipOnBeforeUnload();
          loadUrl(getLogoutDomain());
        }
      } catch (e) {
        logger.error(`Calendar logout error: ${e}`);
      }
    }
  } else if (action.type === nucleusConstants.INITIALIZE_SERVICES_SUCCESS) {
    const profile = get(state, 'nucleus.userProfile');
    const email = get(currentUserSelectors.myInfo(state), 'email');
    const isGuest = get(currentUserSelectors.myInfo(state), 'isGuest');
    Ecommerce.displayUpgradeButton(state).then((displayUpgradeButton) => {
      // Fire and forget
      store.dispatch(setShouldDisplayUpgradeLink(displayUpgradeButton));
      analytics.sendProfile(profile, displayUpgradeButton);
    });
    if (!isGuest && !!email) {
      store.dispatch(authActions.ssoEmailCheck(email));
    }

    const useVP8 = accountSelectors.getAccountSettings(state)?.sfuSettings?.useVP8;
    if (useVP8) {
      const sigConfig = { sdpOptions: { preferH264: false, preferredProfile: null } };
      signaling.configure(sigConfig);
    }
  } else if (
    isDesktop &&
    action.type === nucleusConstants.LOGIN_FAILURE &&
    _get(action, 'payload.payload.type') === nucleusConstants.UNAUTHORIZED
  ) {
    store.dispatch(authActions.logout());
  }
  return result;
};

export default authMiddleware;
