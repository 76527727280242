import Logger from 'js-logger';

interface TimeoutEvent {
  key: string;
  event: Function;
  timeout: number;
  onOnline?: Function;
  handle?: any;
}

class ConnectionEventManager {
  private static _instance: ConnectionEventManager;
  #timeouts: Array<TimeoutEvent>;

  private constructor() {
    this.#timeouts = [];
  }

  public static getInstance(): ConnectionEventManager {
    if (!this._instance) {
      this._instance = new ConnectionEventManager();
    }

    return this._instance;
  }

  public networkOfflineEvent() {
    // Set all the timeouts
    let updatedTimeouts: Array<TimeoutEvent> = [];
    for (const t of this.#timeouts) {
      // Set timeout and register for nonzero timeouts
      if (t.timeout) {
        const handle = setTimeout(t.event, t.timeout);

        updatedTimeouts.push({ ...t, handle });
      } else {
        // Otherwise, re-add and execute now
        updatedTimeouts.push(t);
        try {
          t.event();
        } catch (e) {
          Logger.warn('Error thrown while attempting to execute offline handler', e, t);
        }
      }
    }

    this.#timeouts = updatedTimeouts;
  }

  public networkOnlineEvent() {
    // When network comes back online, clear all pending timeouts.
    let updatedTimeouts: Array<TimeoutEvent> = [];
    for (const t of this.#timeouts) {
      if (t.handle) {
        clearTimeout(t.handle);
      }

      if (t.onOnline) {
        try {
          t.onOnline();
        } catch (e) {
          Logger.warn('Error thrown while attempting to execute online handler', e, t);
        }
      }

      updatedTimeouts.push({ ...t, handle: null });
    }

    this.#timeouts = updatedTimeouts;
  }

  public registerOfflineEvent(key: string, event: Function, timeout: number, onOnline?: Function) {
    // TODO: check key for dupes on registration
    // Store in our list of timeouts
    this.#timeouts.push({ key, event, timeout, onOnline });
  }

  public deRegisterOfflineEvent(key) {
    const oldTimeout = this.#timeouts.find((t) => t.key === key);

    if (!oldTimeout) {
      return;
    }

    if (oldTimeout.handle) {
      clearTimeout(oldTimeout.handle);
    }

    this.#timeouts = this.#timeouts.filter((t) => t.key !== key);
  }
}

const connectionEventManagerInstance = ConnectionEventManager.getInstance();

export { connectionEventManagerInstance as connectionEventManager };
