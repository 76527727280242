import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import jwt from 'reducers/Auth';
import header from 'reducers/Header';
import i18n from 'reducers/I18n';
import modal from 'reducers/FullScreenModal';
import inCallModal from 'reducers/InCallModal';
import chat from 'reducers/Chat';
import scheduleModal from 'reducers/ScheduleModal';
import contacts from 'reducers/Contact';
import contactModal from 'reducers/ContactModal';
import dropdown from 'reducers/Dropdown';
import startCallModal from 'reducers/StartCallModal';
import inCallDrawer from 'reducers/InCall/InCallDrawer';
import inCallVideo from 'reducers/InCall/InCallVideo';
import addParticipantDrawer from 'reducers/AddParticipantDrawer';
import call from 'reducers/Call';
import settingsModal from 'reducers/SettingsModal';
import favorites from 'reducers/Favorites';
import avSettings from 'reducers/AvSettingsModal';
import notification from 'reducers/Notification';
import onboarding from 'reducers/Onboarding';
import calendar from 'reducers/Calendar';
import joinModal from 'reducers/JoinModal';
import flags from 'reducers/FlagsReducer';
import media from 'reducers/Media';
import miniPlayer from 'reducers/MiniPlayer';
import vbbReducer from 'reducers/vbbSettingsSlice';
import mediaSettingsReducer from 'reducers/mediaSettingsSlice';
import selfViewReducer from 'reducers/selfViewSlice';
import guestFlow from 'reducers/guestFlowSlice';
import { PASSCODE_FORM, INVALID_PASSCODE } from 'constants/PasscodeModal';

const rootReducer = combineReducers({
  // Add sync reducers here
  form: formReducer.plugin({
    [PASSCODE_FORM]: (state, action) => {
      switch (action.type) {
        case INVALID_PASSCODE:
          return {
            ...state,
            values: {
              ...state.values,
              passcode: undefined, // <----- clear passcode value
              numTries: state.values.numTries + 1
            },
            fields: {
              ...state.fields,
              passcode: undefined // <----- clear field state, too (touched, etc.)
            }
          };
        default:
          return state;
      }
    }
  }),
  addParticipantDrawer,
  avSettings,
  calendar,
  call,
  chat,
  contactModal,
  contacts,
  dropdown,
  favorites,
  flags,
  guestFlow,
  header,
  i18n,
  inCallDrawer,
  inCallModal,
  inCallVideo,
  joinModal,
  jwt,
  media,
  mediaSettings: mediaSettingsReducer,
  modal,
  notification,
  onboarding,
  scheduleModal,
  selfView: selfViewReducer,
  settingsModal,
  startCallModal,
  vbbSettings: vbbReducer,
  miniPlayer
});
// TODO - Remove nucleus from type definition once it is retired
export type RootState = { app: ReturnType<typeof rootReducer>; nucleus: any };

export default rootReducer;
