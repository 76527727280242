import { UserAgentApplication } from 'msal';
import lsConfig from 'utils/global-config';
import { featureSelectors } from '@lifesize/nucleus';
import { FLAG_OUTLOOK_SCHEDULE_INTEGRATION } from 'constants/FeatureFlags';
import { addDaysToCurrentDate } from 'utils/calendar-utils';
import { openCenteredPopUp } from 'utils/browser-utils';

// App configuration
const graphEndpoint = 'https://graph.microsoft.com/v1.0/me';
const clientId = '1d9c50c4-b7ae-4db9-b83d-49493157e7ac';
const origin = window.location.origin;

let userAgentApplication;
const userAgentOptions = {
  auth: {
    clientId,
    redirectUri: origin,
    post_logout_redirect_uri: origin
  },
  cache: {
    cacheLocation: 'localStorage'
  }
};

export function initialize() {
  /* The oauth process opens the origin site in an iframe
  that needs an initialized client running to catch the oauth request
  and send it back to the main window. See:
  https://github.com/AzureAD/microsoft-authentication-library-for-js/wiki/Public-APIs#acquire-token-apis */
  userAgentApplication = new UserAgentApplication(userAgentOptions);
  userAgentApplication.handleRedirectCallback(() => {});
}

const authenticationRequest = () => {
  const outlookIntegrationFeatureFlag = featureSelectors.getFeatureFlag(window?.lifesize?.store?.getState())(
    FLAG_OUTLOOK_SCHEDULE_INTEGRATION
  );
  const scopes = [
    outlookIntegrationFeatureFlag
      ? 'https://graph.microsoft.com/Calendars.ReadWrite'
      : 'https://graph.microsoft.com/.default'
  ];
  return { scopes, prompt: 'select_account' };
};

export async function getAccessToken() {
  return new Promise((resolve, reject) => {
    userAgentApplication.acquireTokenSilent(authenticationRequest()).then(
      (accessToken) => resolve(accessToken),
      (accessError) => reject(accessError)
    );
  });
}

async function loginPopup() {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    if (!userAgentApplication) {
      await initialize();
    }
    // Clear the cache again to make sure there are no left over tokens.
    await userAgentApplication.clearCache();
    // loginPopup is not a true promise but a "thennable". It cannot be part of an async function.
    userAgentApplication.loginPopup(authenticationRequest()).then(
      async () => {
        try {
          resolve(await getAccessToken());
        } catch (e) {
          reject(e);
        }
      },
      (error) => {
        // Check if Popup is closed by user. not Need to open it one more time.
        if (error.errorMessage === 'User cancelled the flow.') {
          reject(error);
          return;
        }
        userAgentApplication.acquireTokenPopup(authenticationRequest()).then(
          (accessToken) => {
            resolve(accessToken);
          },
          (popupError) => {
            reject(popupError);
          }
        );
        reject(error);
      }
    );
  });
}

export async function login() {
  return loginPopup();
}

async function makeGraphRequest(endpoint, token, parameters = {}) {
  const headers = new Headers();
  const bearer = `Bearer ${token}`;
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  headers.append('Authorization', bearer);
  headers.append('Prefer', `outlook.timezone="${timeZone}"`);
  const options = {
    method: 'GET',
    headers
  };

  const responseUri = new URL(graphEndpoint + endpoint);
  Object.keys(parameters).forEach((parameterName) => {
    responseUri.searchParams.set(parameterName, parameters[parameterName]);
  });

  const response = await fetch(responseUri.toString(), options);
  if (response.ok === false) {
    return Promise.reject(response.statusText);
  }
  return response.json();
}

export async function createCalendarEvent(token, event) {
  const headers = new Headers();
  const bearer = `Bearer ${token}`;
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  headers.append('Authorization', bearer);
  headers.append('Prefer', `outlook.timezone="${timeZone}"`);
  headers.append('Content-Type', 'application/json');
  const options = {
    method: 'POST',
    headers,
    body: JSON.stringify(event)
  };

  const responseUri = new URL(graphEndpoint + '/calendar/events');

  const response = await fetch(responseUri.toString(), options);
  if (response.ok === false) {
    return Promise.reject(response.statusText);
  }
  return response.json();
}

export const generateOutlookInvite = async (invitation, inviteURL, outlookToken, start, end, id) => {
  const outlookEvent = {
    subject: invitation.subject,
    body: {
      contentType: 'text',
      content: invitation.body
    },

    id,
    location: {
      displayName: inviteURL
    }
  };

  const createdOutlookEvent = await createCalendarEvent(outlookToken, outlookEvent);
  if (createdOutlookEvent.webLink) {
    window.open(createdOutlookEvent.webLink, '_blank');
  }
};

export async function getUser(token) {
  return makeGraphRequest('', token);
}

export function logout() {
  lsConfig.skipOnBeforeUnload();

  if (!userAgentApplication) {
    return null;
  }

  const width = 483;
  const height = 578;

  // There is no native logoutPopup right now - this simulates that functionality
  userAgentApplication.clearCache();
  const logoutUrl = `https://login.microsoftonline.com/common/oauth2/v2.0/logout?post_logout_redirect_uri=${origin}`;
  return openCenteredPopUp(logoutUrl, { width, height });
}

export async function listEvents(token) {
  const now = new Date();

  const parameters = {
    top: '100',
    orderBy: 'start/dateTime',
    startDateTime: now.toISOString(),
    endDateTime: addDaysToCurrentDate(7, now).toISOString()
  };

  return makeGraphRequest('/calendar/calendarView', token, parameters);
}

export default {
  initialize,
  listEvents,
  login,
  logout,
  getAccessToken,
  getUser
};

window.listEvents = listEvents;
