import _mapValues from 'lodash/mapValues';
import _template from 'lodash/template';
import _templateSettings from 'lodash/templateSettings';

export const messagesSelector = (state, messages) =>
  _mapValues(messages, (value) => {
    const translatedMessage = state.app.i18n.messages[value.id];
    return translatedMessage ? state.app.i18n.messages[value.id] : value.defaultMessage;
  });

export const formatMessage = (message, values) => {
  if (!values) return message;
  _templateSettings.interpolate = /{([\s\S]+?)}/g;
  const compiled = _template(message);
  return compiled(values);
};

export default { messagesSelector, formatMessage };
