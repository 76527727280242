import { createSelector } from 'reselect';
import { contactSelectors, localizationSelectors } from '@lifesize/nucleus';
import { timeFormatOptions, dateFormatOptions, allInviteUrls } from 'constants/Calendar';
import { getStartTime, getEndTime, getTitle, getLocation, getDescription, getOrganizer } from 'utils/calendar-utils';
import _get from 'lodash/get';
import _orderBy from 'lodash/orderBy';
import _escapeRegExp from 'lodash/escapeRegExp';

const googleEventsSelector = (state) => _get(state, 'app.calendar.google.calendarEvents') || [];
const outlookEventsSelector = (state) => _get(state, 'app.calendar.outlook.calendarEvents') || [];
const upcomingNotificationsSelector = (state) => _get(state, 'app.calendar.upcomingNotifications') || {};
const upcomingNotificationsTotalSelector = (state) =>
  Object.keys(_get(state, 'app.calendar.upcomingNotifications') || {}).length;
const localizationSelector = (state) => _get(localizationSelectors.localization(state), 'languageId');

export const selectedMeetingIdSelector = (state) => _get(state, 'app.calendar.selectedEvent.id');
export const outlookIsRefreshingSelector = (state) => _get(state, 'app.calendar.outlook.isRefreshing');
export const googleIsRefreshingSelector = (state) => _get(state, 'app.calendar.google.isRefreshing');

const allEventsSelector = createSelector(googleEventsSelector, outlookEventsSelector, (googleEvents, outlookEvents) => {
  const mappedGoogleEvents = googleEvents.map((event) => ({ ...event, calendarType: 'google' }));
  const mappedOutlookEvents = outlookEvents.map((event) => ({ ...event, calendarType: 'outlook' }));
  return [...mappedGoogleEvents, ...mappedOutlookEvents];
});

export const upcomingNotificationCountSelector = createSelector(
  upcomingNotificationsSelector,
  allEventsSelector,
  upcomingNotificationsTotalSelector,
  (upcomingNotifications, allEvents, total) => {
    if (total === 0) return 0;
    let notificationCount = 0;
    Object.keys(upcomingNotifications).forEach((upcomingId) => {
      if (allEvents.find((event) => event.id === upcomingId)) {
        notificationCount += 1;
      }
    });
    return notificationCount;
  }
);

const hasInviteUrl = (matchString, url) => {
  const inviteUrlRegExp = new RegExp(`${_escapeRegExp(url)}([0-9]+)`);
  return !!matchString.match(inviteUrlRegExp);
};

export const lifesizeMeetingsSelector = createSelector(
  allEventsSelector,
  localizationSelector,
  (allEvents, languageId) => {
    const lifesizeMeetings = allEvents.filter((event) => {
      const location = getLocation(event);
      const description = getDescription(event);

      return allInviteUrls.find(
        (inviteUrl) => hasInviteUrl(description, inviteUrl) || hasInviteUrl(location, inviteUrl)
      );
    });

    const mappedMeetings = lifesizeMeetings.map((event) => {
      const location = getLocation(event);
      const description = getDescription(event);
      let extension = null;

      const locationInviteUrl = allInviteUrls.find((inviteUrl) => hasInviteUrl(location, inviteUrl));
      const descriptionInviteUrl = allInviteUrls.find((inviteUrl) => hasInviteUrl(description, inviteUrl));
      const contentToMatch = locationInviteUrl ? location : description;
      const inviteUrl = locationInviteUrl || descriptionInviteUrl;
      const stringMatches = [];

      for (let i = 0; i < contentToMatch.length; i += 1) {
        const inviteUrlMatch = contentToMatch.substr(i);
        if (inviteUrlMatch.substr(0, inviteUrl.length) === inviteUrl) {
          const endIndex = inviteUrlMatch.substr(inviteUrl.length + 1).search(/[^0-9]/);
          const stringWithNumbers =
            endIndex === -1 ? inviteUrlMatch : inviteUrlMatch.substr(0, endIndex + inviteUrl.length + 1);
          if (!isNaN(stringWithNumbers[stringWithNumbers.length - 1])) {
            stringMatches.push(stringWithNumbers);
          }
        }
      }

      const stringWithExtension = stringMatches[0];
      if (stringWithExtension) {
        const startOfExtension = stringWithExtension.search(/[0-9]/);
        const endOfExtension = stringWithExtension.substr(startOfExtension).search(/[^0-9]/);
        extension =
          endOfExtension === -1
            ? stringWithExtension.substr(startOfExtension)
            : stringWithExtension.substr(startOfExtension, endOfExtension);
        if (!extension.length) extension = null;
      }

      const title = getTitle(event);

      let startDate;
      let endDate;
      let formattedStartTime;
      let formattedStartDate;
      let formattedEndTime;
      let formattedEndDate;

      if (getStartTime(event)) {
        let rawStartTime = new Date(getStartTime(event));
        if (_get(event, 'start.date')) {
          rawStartTime = new Date(`${event.start.date}T00:00:00`);
        }
        startDate = new Date(rawStartTime);
        formattedStartTime = startDate.toLocaleTimeString(languageId, timeFormatOptions);
        formattedStartDate = startDate.toLocaleDateString(languageId, dateFormatOptions);
      }

      if (getEndTime(event)) {
        let rawEndTime = new Date(getEndTime(event));
        if (_get(event, 'end.date')) {
          rawEndTime = new Date(`${event.end.date}T00:00:00`);
        }
        endDate = new Date(rawEndTime);
        formattedEndTime = endDate.toLocaleTimeString(languageId, timeFormatOptions);
        formattedEndDate = endDate.toLocaleDateString(languageId, dateFormatOptions);
      }

      const organizer = getOrganizer(event);

      const decoration = {
        extension,
        title,
        startDate,
        endDate,
        organizer,
        formattedStartTime,
        formattedEndTime,
        formattedStartDate,
        formattedEndDate,
        inviteUrl
      };

      return { ...event, ...decoration };
    });

    return _orderBy(mappedMeetings, 'startDate');
  }
);

export const getSelectedCalendarContact = createSelector(
  lifesizeMeetingsSelector,
  selectedMeetingIdSelector,
  (state) => contactSelectors.getContactByDialStringGenerator(state),
  (lifesizeMeetings, selectedMeetingId, contactFinder) => {
    if (!selectedMeetingId) return undefined;

    const meeting = lifesizeMeetings.find((c) => c.id === selectedMeetingId);
    if (!meeting) return undefined;
    return contactFinder(meeting.extension);
  }
);

export const isRefreshingCalendars = createSelector(
  googleIsRefreshingSelector,
  outlookIsRefreshingSelector,
  (googleIsRefreshing, outlookIsRefreshing) => googleIsRefreshing || outlookIsRefreshing
);

export default {
  upcomingNotificationCountSelector,
  lifesizeMeetingsSelector,
  getSelectedCalendarContact,
  selectedMeetingIdSelector,
  isRefreshingCalendars
};
