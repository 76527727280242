import {
  CALENDAR_API_SIGNED_IN,
  CALENDAR_API_SIGNED_OUT,
  CALENDAR_API_ERROR,
  CALENDAR_LIST_EVENTS,
  CALENDAR_USER,
  CALENDAR_SIGN_IN_START,
  CALENDAR_LOAD_AUTH,
  CALENDAR_EXTERNAL_AUTH,
  START_CALENDAR_NOTIFICATIONS,
  SET_CALENDAR_NOTIFICATION,
  CLEAR_CALENDAR_NOTIFICATION,
  SET_UPCOMING_NOTIFICATION,
  REMOVE_UPCOMING_NOTIFICATION,
  CALENDAR_SELECT_EVENT,
  RETRIEVING_CALENDAR_INFO,
  calendars
} from 'constants/Calendar';
import _get from 'lodash/get';
import _pickBy from 'lodash/pickBy';

// ------------------------------------
// Action Handlers
// ------------------------------------
export const calendarInitialState = {
  calendarToken: null,
  calendarSigningIn: false,
  calendarSignedIn: false,
  calendarApiError: null,
  calendarUser: null,
  calendarEvents: [],
  eventsLoaded: false,
  calendarAuth: null,
  isRefreshing: false
};

const calendarStates = {};

Object.values(calendars).forEach((calendar) => {
  calendarStates[calendar] = calendarInitialState;
});

Object.values(calendars).forEach((calendar) => {
  calendarStates[calendar] = { ...calendarInitialState };
});

export const initialState = {
  ...calendarStates,
  notificationsStarted: false,
  upcomingNotifications: {},
  selectedEvent: null,
  calendarNotifications: JSON.parse(localStorage.getItem('calendar:notifications') || '[]')
};

const updateCalendarAuthStorage = (payload, update) => {
  if (!payload.userId) return;
  const currentAuth = JSON.parse(localStorage.getItem(`calendar:${payload.calendarId}`));
  let revisedAuth = update;
  if (currentAuth) {
    const userAuthInfo = currentAuth[payload.userId];
    if (userAuthInfo) {
      revisedAuth = { ...currentAuth[payload.userId], ...update };
    }
  }

  if (!update) {
    revisedAuth = null;
  }

  const newAuth = { ...currentAuth, [payload.userId]: revisedAuth };

  localStorage.setItem(`calendar:${payload.calendarId}`, JSON.stringify(newAuth));
};

const updateCalendarState = (state, calendar, newStateKey, newStateValue) => {
  const calendarState = state[calendar];
  const newCalendarState = {
    ...calendarState,
    [newStateKey]: newStateValue
  };

  return {
    ...state,
    [calendar]: newCalendarState
  };
};

const queueCalendarChanges = (state, updates) => {
  let updatedState = { ...state };

  updates.forEach((update) => {
    updatedState = updateCalendarState(updatedState, update.id, update.property, update.value);
  });

  return updatedState;
};

const saveDisplayedNotifications = (notifications) => {
  const strippedNotifications = notifications.map((notification) => ({
    id: notification.id,
    calendarType: notification.calendarType,
    start: notification.start,
    shown: true
  }));
  localStorage.setItem('calendar:notifications', JSON.stringify(strippedNotifications));
};

const ACTION_HANDLERS = {
  [CALENDAR_API_SIGNED_IN]: (state, action) => {
    const updates = [
      { id: action.payload.calendarId, property: 'calendarSignedIn', value: true },
      { id: action.payload.calendarId, property: 'calendarSigningIn', value: false },
      { id: action.payload.calendarId, property: 'calendarToken', value: _get(action, 'payload.token') }
    ];

    return queueCalendarChanges(state, updates);
  },
  [CALENDAR_LOAD_AUTH]: (state, action) => {
    const updates = [
      { id: action.payload.calendarId, property: 'calendarAuth', value: _get(action, 'payload.auth') },
      { id: action.payload.calendarId, property: 'calendarToken', value: _get(action, 'payload.auth.access_token') }
    ];
    return queueCalendarChanges(state, updates);
  },
  [CALENDAR_EXTERNAL_AUTH]: (state, action) => {
    updateCalendarAuthStorage(action.payload, action.payload.auth);
    const updates = [
      { id: action.payload.calendarId, property: 'calendarSignedIn', value: true },
      { id: action.payload.calendarId, property: 'calendarToken', value: _get(action, 'payload.auth.access_token') },
      { id: action.payload.calendarId, property: 'calenderSigningIn', value: false },
      { id: action.payload.calendarId, property: 'calendarAuth', value: _get(action, 'payload.auth') }
    ];

    return queueCalendarChanges(state, updates);
  },
  [CALENDAR_API_SIGNED_OUT]: (state, action) => {
    updateCalendarAuthStorage(action.payload, null);
    return { ...state, selectedEvent: null, [action.payload.calendarId]: calendarInitialState };
  },
  [CALENDAR_API_ERROR]: (state, action) => {
    const updates = [
      { id: action.payload.calendarId, property: 'calendarApiError', value: _get(action, 'payload.error') },
      { id: action.payload.calendarId, property: 'isRefreshing', value: false }
    ];
    return queueCalendarChanges(state, updates);
  },
  [CALENDAR_LIST_EVENTS]: (state, action) => {
    const nextState = { ...state, updatedTime: action.payload.updatedTime };
    const updates = [
      { id: action.payload.calendarId, property: 'calendarEvents', value: action.payload.events },
      { id: action.payload.calendarId, property: 'eventsLoaded', value: true },
      { id: action.payload.calendarId, property: 'isRefreshing', value: false }
    ];
    return queueCalendarChanges(nextState, updates);
  },
  [CALENDAR_USER]: (state, action) =>
    updateCalendarState(state, action.payload.calendarId, 'calendarUser', action.payload.user),
  [CALENDAR_SIGN_IN_START]: (state, action) => updateCalendarState(state, action.payload, 'calendarSigningIn', true),
  [START_CALENDAR_NOTIFICATIONS]: (state) => ({ ...state, notificationsStarted: true }),
  [SET_CALENDAR_NOTIFICATION]: (state, action) => {
    const nextState = { ...state };
    if (nextState.calendarNotifications.find((calendarNotification) => calendarNotification.id === action.payload.id))
      return nextState;
    const newNotification = { ...action.payload, shown: true, calendarType: action.payload.calendarType };
    nextState.calendarNotifications.push(newNotification);
    saveDisplayedNotifications(nextState.calendarNotifications);
    return nextState;
  },
  [CLEAR_CALENDAR_NOTIFICATION]: (state, action) => {
    const nextState = { ...state };
    nextState.calendarNotifications = nextState.calendarNotifications.filter(
      (calendarNotification) => calendarNotification.id !== action.payload
    );
    saveDisplayedNotifications(nextState.calendarNotifications);
    nextState.upcomingNotifications = _pickBy(nextState.upcomingNotifications, (value, key) => key !== action.payload);
    delete nextState.upcomingNotifications[action.payload];
    return nextState;
  },
  [SET_UPCOMING_NOTIFICATION]: (state, action) => {
    const nextState = { ...state };
    nextState.upcomingNotifications[action.payload.id] = action.payload.timeUntil;
    return nextState;
  },
  [REMOVE_UPCOMING_NOTIFICATION]: (state, action) => {
    return {
      ...state,
      upcomingNotifications: _pickBy(state.upcomingNotifications, (value, key) => key !== action.payload)
    };
  },
  [CALENDAR_SELECT_EVENT]: (state, action) => ({ ...state, selectedEvent: action.payload }),
  [RETRIEVING_CALENDAR_INFO]: (state, action) => updateCalendarState(state, action.payload, 'isRefreshing', true)
};

// ------------------------------------
// Reducer
// ------------------------------------
export default function calendarReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
