export const JOIN_MODAL = 'JOIN_MODAL';
export const SELECT_COUNTRY = 'SELECT_COUNTRY';
export const HANDLE_FOCUS = 'HANDLE_FOCUS';
export const HANDLE_BLUR = 'HANDLE_BLUR';
export const SHOW_PSTN = 'SHOW_PSTN';
export const HIDE_PSTN = 'HIDE_PSTN';
export const VIDEO_MUTED = 'VIDEO_MUTED';
export const AUDIO_MUTED = 'AUDIO_MUTED';
export const IS_JOIN_CALL = 'IS_JOIN_CALL';
export const CANCEL_JOIN_CALL = 'CANCEL_JOIN_CALL';
export const RESET_GUEST_CALL = 'RESET_GUEST_CALL';
export const BEGIN_LOGIN = 'BEGIN_LOGIN';
export const END_LOGIN = 'END_LOGIN';

export const JOIN_READY = 'JOIN_READY';
