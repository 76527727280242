// Time in milliseconds (be aware of the fade-in/out animations which are included in the timeout)
export const SHORT_TIMEOUT = 1000;
export const MEDIUM_TIMEOUT = 2000;
export const LONG_TIMEOUT = 3000;
export const VERY_LONG_TIMEOUT = 6000;

export const SET_PILL_NOTIFICATION = 'SET_PILL_NOTIFICATION';
export const DELETE_PILL_NOTIFICATION = 'DELETE_PILL_NOTIFICATION';
export const SET_TOOLTIP_NOTIFICATION = 'SET_TOOLTIP_NOTIFICATION';
export const CLEAR_TOOLTIP_NOTIFICATION = 'CLEAR_TOOLTIP_NOTIFICATION';
export const SET_INCALL_NOTIFICATION = 'SET_INCALL_NOTIFICATION';
export const CLEAR_INCALL_NOTIFICATION = 'CLEAR_INCALL_NOTIFICATION';

// Tooltip Notifications
export const tooltipNotifications = {
  inCallActionBtnRaiseHand: 'inCallActionBtnRaiseHand',
  inCallDrawerBtnRaiseHand: 'inCallDrawerBtnRaiseHand'
};

export const tooltipNotificationTypes = {
  error: 'error',
  notification: 'notification'
};
