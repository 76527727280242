import _get from 'lodash/get';
import { constants as C } from '@lifesize/clients.sdk';
import { contactSelectors, currentUserSelectors, featureSelectors } from '@lifesize/nucleus';

export const gqlChatContactForCall = (state, conferenceInfo = {}, participants = []) => {
  // not fully in a call
  if (!conferenceInfo.id) return null;

  const getContact = contactSelectors.getContactByUuidGenerator(state);
  const currentUser = currentUserSelectors.myInfo(state);

  // use conference UUID in most circumstances, include vmr's uuid for reference
  if (
    conferenceInfo.type === C.GALAXY_CONFERENCE_TYPE_VMR ||
    currentUser.isGuest ||
    conferenceInfo.state === C.GALAXY_CONFERENCE_STATE_ESCALATED
  )
    return { uuid: conferenceInfo.id, vmrUUID: conferenceInfo.uuid };

  // call is not escalated, with a guest, use the conferenceId, with a registered user, use their uuid
  const otherParticipant = participants.find((p) => !p.isSelf);
  if (!otherParticipant) return;
  if (otherParticipant.guest) return { uuid: conferenceInfo.id };

  const contact = getContact(otherParticipant.uuid);
  return { uuid: otherParticipant.uuid, dialString: _get(contact, 'dialString') };
};

export const gqlChatAvailableForCall = (state, conferenceInfo = {}, participants = []) => {
  // not in a call / no chat for account
  if (!featureSelectors.isChatEnabled(state) || !conferenceInfo.id) return false;

  if (conferenceInfo.type === C.GALAXY_CONFERENCE_TYPE_VMR) return true;

  const getContact = contactSelectors.getContactByUuidGenerator(state);

  // We still want to hide chat for room systems and non-chat-enabled devices.
  const otherParticipant = participants.find((p) => !p.isSelf);
  if (!otherParticipant) return false; // no other participant ?
  if (otherParticipant.type !== 'wamp') return false; // non lifesize chat enabled device

  const contact = getContact(otherParticipant.uuid);
  if (_get(contact, 'isRoomSystem')) return false;

  // All other users allowed (regular user, guest, etc.)
  return true;
};

// Determine if the current user can chat with the current contact (out-of-call)
export const currentUserCanChat = (contact, currentUserUuid) => {
  return (
    // We do NOT check currentUserSuperUser here because that would allow non-owners to chat
    !contact?.isMeeting ||
    // We do NOT check contact.moderator.uuid here because we do NOT want to show chat icon for moderators
    contact?.owner?.uuid === currentUserUuid
  );
};
