import { constants } from '@lifesize/clients.sdk';
import * as validator from 'email-validator';
import _get from 'lodash/get';
import { popoutPresentationSelector } from 'selectors/call';
import { callReasons } from '../constants/Guest';

const galaxyDisconnectValidReasons = [
  constants.GALAXY_REASON_HANGUP,
  constants.GALAXY_REASON_HUNGUP,
  constants.GALAXY_EVENT_PARTICIPANT_EJECTED,
  constants.GALAXY_EVENT_CONFERENCE_ENDED,
  constants.GALAXY_EVENT_PARTICIPANT_MIGRATED,
  constants.GALAXY_REASON_NO_MEDIA,
  constants.GALAXY_EVENT_CONFERENCE_LOCKED,
  constants.GALAXY_REASON_NOT_IN_CALL,
  'ivrTimeout',
  'rejectedByIvr'
];

const galaxyValidDisconnectStates = [constants.CALL_STATE_DISCONNECTED, constants.CALL_STATE_FAILED];

export const getInlinePresentation = (state, hasRemotePresentation) => {
  const popoutPresentation = popoutPresentationSelector(state);
  return hasRemotePresentation && !popoutPresentation;
};

export const getCallStatus = (status) => {
  if (['begin', 'calling', 'ringing', 'remote_ringing', 'trying'].includes(status)) return 'calling';
  return status;
};

export function isStartingCall(activeCall) {
  if (!activeCall) return false;
  return (
    !!activeCall.callId &&
    [
      constants.CALL_STATE_CONNECTING,
      constants.CALL_STATE_CALLING,
      constants.CALL_STATE_RINGING,
      constants.CALL_STATE_TRYING,
      constants.CALL_STATE_LOCAL_RINGING
    ].indexOf(activeCall.state) > -1
  );
}

export function isDroppedCall(activeCall) {
  return (
    activeCall &&
    activeCall.state === constants.CALL_STATE_FAILED &&
    activeCall.reason === constants.GALAXY_REASON_NETWORK_ERROR
  );
}

export function isCallConnected(activeCall) {
  if (!activeCall) return false;
  return activeCall.state === constants.CALL_STATE_CONNECTED;
}

export function isCallDisconnected(activeCall) {
  if (!activeCall) return false;
  return activeCall.state === constants.CALL_STATE_DISCONNECTED;
}

export function callDisconnectedWithReason(activeCall) {
  if (!activeCall) return false;

  return !!(
    galaxyValidDisconnectStates.includes(activeCall.state) &&
    activeCall.reason &&
    !galaxyDisconnectValidReasons.includes(activeCall.reason)
  );
}

export function callingError(activeCall) {
  if (!activeCall) return false;
  return activeCall.state === constants.CALL_STATE_DISCONNECTED && activeCall.error;
}

export function getCallType(activeCall) {
  if (!activeCall) return 'user';
  const contact = _get(activeCall, 'remoteContact') || {};
  if (contact.isMeeting) return 'meeting';
  if (contact.isRoomSystem) return 'roomSystem';
  if (contact.isUser) return 'user';
  if (activeCall.isManualDial) return 'manual';
  return 'user';
}

export function checkForCustomEntry(searchString) {
  if (!searchString) return false;
  return (
    validator.validate(searchString) ||
    /* eslint-disable no-useless-escape */
    (searchString.length >= 4 && /(.*[@#\.].*|\d{4,})/.test(searchString))
    /* eslint-enable no-useless-escape */
  );
}

export function isFarEndBusy(activeCall) {
  return isCallDisconnected(activeCall) && callReasons?.busy?.includes(activeCall.reason);
}
