import { constants as c } from '@lifesize/clients.sdk';

export const fetchExtensionsUrl = process.env.REACT_APP_EXTENSION_URL || '';
export const fetchFeatureFlagServiceUrl = process.env.REACT_APP_FEATURE_FLAG_SERVICE_URL || '';
export const featureFlagServiceApiKey = process.env.REACT_APP_FEATURE_FLAG_SERVICE_API_KEY || '';

export const webAppUrl = process.env.REACT_APP_WEB_APP_URL || '';
export const desktopLauncherUrl = process.env.REACT_APP_LAUNCHER_URL || '';
export const otherWaysUrl = process.env.REACT_APP_OTHER_WAYS_URL || '';
export const mobileCustomProtocol = 'lifesize://call/';

export interface Extension {
  error: null | string;
  isValid: boolean;
  groupUUID: null | string;
  value?: string;
}

export const EXTENSION_STATE = {
  VALIDATING: 'VALIDATING',
  INVALID: 'INVALID',
  VALID: 'VALID'
};

export const callReasons = {
  busy: [
    c.GALAXY_REASON_REJECTED,
    c.GALAXY_REASON_BUSY,
    c.GALAXY_REASON_DO_NOT_DISTURB,
    c.GALAXY_EVENT_PARTICIPANT_IGNORED
  ],
  successful: [
    c.GALAXY_REASON_HANGUP,
    c.GALAXY_REASON_HUNGUP,
    c.GALAXY_REASON_DEFAULT,
    c.GALAXY_REASON_NOT_IN_CALL,
    c.GALAXY_REASON_PARTICIPANT_EJECTED
  ],
  unreachable: [c.GALAXY_REASON_UNREACHABLE, c.GALAXY_REASON_TIMED_OUT, c.GALAXY_REASON_CLIENT_EXCEPTION],
  notSupported: [c.GALAXY_REASON_CLIENT_NOT_SUPPORTED]
};

export const callState = {
  calling: [
    c.CALL_STATE_BEGIN,
    c.CALL_STATE_CALLING,
    c.CALL_STATE_CONNECTING,
    c.CALL_STATE_INITIALIZING,
    c.CALL_STATE_RINGING,
    c.CALL_STATE_TRYING
  ],
  disconnected: [c.CALL_STATE_DISCONNECTED]
};
