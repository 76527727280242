import {
  SHOULD_DISPLAY_PRIMARY_VIDEO,
  SHOULD_DISPLAY_SECONDARY_VIDEO,
  TOGGLE_MINIPLAYER_VIDEO,
  TOGGLE_DIAL_PAD,
  TOGGLE_CAMERA_CONTROLS,
  VIDEO_LAYOUT_CHANGE,
  TOGGLE_POPOUT,
  VIDEO_POLLING_STOPPED,
  TURTLE_DRAG_START,
  TURTLE_DRAG_STOP,
  WAITING_TO_REPOP,
  VIDEO_PRIMARY,
  VIDEO_SECONDARY,
  DEFAULT_ASPECT,
  defaultConfig,
  TOGGLE_MINIMIZED
} from 'constants/InCall/InCallVideo';
import { popoutPresentationFeatureSelector } from 'selectors/call';
import _merge from 'lodash/merge';
import _get from 'lodash/get';

const videoPolls = [];

function calcWidthToContain(maxHeight, maxWidth, ratio) {
  return Math.max(0, Math.min(ratio * maxHeight, maxWidth));
}

export function getVideoConfig(
  { popoutPresentation, container, splitPosition, videos, rightBounds },
  turtlePosition,
  isContainerResizing
) {
  const leftContainer = container.width * splitPosition;
  const leftRatio = videos[VIDEO_SECONDARY].ratio;
  let rightContainer = container.width * (1 - splitPosition);
  const rightRatio = videos[VIDEO_PRIMARY].ratio;
  const rightWidth = calcWidthToContain(container.height, rightContainer, rightRatio);
  if (splitPosition === 1) {
    rightContainer = 0;
  }

  if (!popoutPresentation) {
    return {
      container,
      [VIDEO_SECONDARY]: {
        container: leftContainer,
        ratio: leftRatio,
        width: calcWidthToContain(container.height, leftContainer, leftRatio)
      },
      [VIDEO_PRIMARY]: {
        container: rightContainer,
        ratio: rightRatio,
        // account for window resize and turtle drag
        width:
          turtlePosition?.x === rightBounds || (isContainerResizing && videos[VIDEO_PRIMARY].width === 0)
            ? 0
            : rightWidth
      }
    };
  }
  return {
    container,
    [VIDEO_SECONDARY]: {
      ratio: DEFAULT_ASPECT
    },
    [VIDEO_PRIMARY]: {
      container: container.width, // width of video container
      ratio: rightRatio, // aspect ratio of video
      width: calcWidthToContain(container.height, container.width, rightRatio) // width of video
    }
  };
}

/**
 * This tracks the isDualStreaming state and the width of the video if !isDualStreaming
 */
export function onLayoutChange(config = {}) {
  return (dispatch) => {
    return dispatch({
      type: VIDEO_LAYOUT_CHANGE,
      payload: config
    });
  };
}

export function updateVideoRatio(videoId, videoRatio) {
  /* NOTES:
   * - video aspect ratios may vary (they will not necessarily be 16/9)
   * - for single streaming, the footer's edges should align with the video
   *   (unless the video is narrower than the minimum footer width)
   * - for dual streaming, the footer will expand to the edges of the call window
   * - each video should always be as large as possible and contained by its videoContainer
   *   (because IE does not support object-fit, we set the width with js)
   * - we watch for height changes on each video to detect aspect ratio changes
   *   (only height matters since the width is set in the style attribute in order to fill the
   *   container) */

  return (dispatch, getState) => {
    const state = getState();
    const videoElement = document.getElementById(videoId);
    let calculatedRatio = videoRatio;
    if (!calculatedRatio && videoElement) {
      calculatedRatio = videoElement.videoWidth / videoElement.videoHeight;
    }

    const videoLayout = _get(state, 'app.inCallVideo.videoLayout');

    const { videos: videosState } = videoLayout;

    videoLayout.videos = _merge({}, videosState, { [videoId]: { ratio: calculatedRatio } });
    videoLayout.videos = getVideoConfig(videoLayout);

    dispatch(onLayoutChange(videoLayout));
  };
}

export function checkVideoLoaded(videoId) {
  return (dispatch) => {
    const checkPromise = new Promise((resolve, reject) => {
      const videoElement = document.getElementById(videoId);

      // verify it exists, is the correct type, and the intrinsic size variables are available
      if (videoElement && videoElement.nodeName.toLowerCase() === 'object' && videoElement.videoHeight) {
        resolve(dispatch(updateVideoRatio(videoId)));
      } else {
        reject();
      }
    });
    return checkPromise;
  };
}

export function stopPolling(videoId) {
  if (videoPolls[videoId]) {
    clearInterval(videoPolls[videoId]);
    videoPolls[videoId] = 0;

    return {
      type: VIDEO_POLLING_STOPPED,
      payload: videoId
    };
  }

  return {
    type: VIDEO_POLLING_STOPPED,
    payload: false
  };
}

export function startPolling(videoId) {
  return (dispatch, getState) => {
    if (!videoPolls[videoId]) {
      videoPolls[videoId] = setInterval(
        () =>
          dispatch(checkVideoLoaded(videoId))
            .then(dispatch(stopPolling))
            .catch(() => {}),
        250
      );
    }
  };
}

export function toggleMiniPlayerVideo() {
  return {
    type: TOGGLE_MINIPLAYER_VIDEO
  };
}

export function onResizeVideo(videoId, videoSize) {
  return (dispatch, getState) => {
    const videoRatio = videoSize.width / videoSize.height;
    // don't update if the ratio is 0 (if the video is collapsed to the edge) or no change
    const currentRatio = _get(getState(), ['app', 'inCallVideo', 'videoLayout', 'videos', videoId, 'ratio']);
    if (videoRatio && videoRatio !== currentRatio) {
      dispatch(this.updateVideoRatio(videoId, videoRatio));
    }
  };
}

export function handleDrag(event, ui) {
  return (dispatch, getState) => {
    const state = getState();
    const videoLayout = _get(state, 'app.inCallVideo.videoLayout');
    const dragPosition = ui.x / videoLayout.container.width;

    videoLayout.splitPosition = Math.min(dragPosition, 1);
    videoLayout.videos = getVideoConfig(videoLayout, ui);
    dispatch(onLayoutChange(videoLayout));
  };
}

export function turtleDragStart() {
  return {
    type: TURTLE_DRAG_START
  };
}

export function turtleDragStop() {
  return {
    type: TURTLE_DRAG_STOP
  };
}

export function waitingToRepop(isWaiting) {
  return {
    type: WAITING_TO_REPOP,
    payload: isWaiting
  };
}

export function handleResizeContainer(container) {
  return (dispatch, getState) => {
    const state = getState();
    const videoLayout = _get(state, 'app.inCallVideo.videoLayout');

    videoLayout.container = container;
    videoLayout.rightBounds = container.width - defaultConfig.minVideoWidth - defaultConfig.turtleHalfWidth;
    videoLayout.videos = getVideoConfig(videoLayout, {}, true);

    dispatch(onLayoutChange(videoLayout));
  };
}

export function togglePopout(isVisible = true) {
  return (dispatch, getState) => {
    const state = getState();

    if (popoutPresentationFeatureSelector(state)) {
      dispatch({
        type: TOGGLE_POPOUT,
        payload: isVisible
      });
    }
  };
}

/**
 * This will be anded with an as of yet unimplemented properties of the
 * activeCall selector to display a video if and only if they are availabe
 * AND the user wants to display a video.
 * The primary use case will be for the electron app if the user clicks to
 * pop out the video into another window.
 */
export function setShouldDisplayPrimaryVideo(shouldDisplay = true) {
  return {
    type: SHOULD_DISPLAY_PRIMARY_VIDEO,
    payload: { shouldDisplay }
  };
}

/**
 * This will be anded with an as of yet unimplemented properties of the
 * activeCall selector to display a presentation if and only if they are availabe
 * AND the user wants to display a presentation.
 * The primary use case will be for the electron app if the user clicks to
 * pop out the presentation into another window.
 */
export function setShouldDisplaySecondaryVideo(shouldDisplay = true) {
  return {
    type: SHOULD_DISPLAY_SECONDARY_VIDEO,
    payload: { shouldDisplay }
  };
}

export function toggleDialPad() {
  return {
    type: TOGGLE_DIAL_PAD
  };
}

export function toggleCameraControls() {
  return {
    type: TOGGLE_CAMERA_CONTROLS
  };
}

/**
 * This will toggle the video from full screen to minimize video to the
 * side navigation.
 */
export function toggleMinimized() {
  return (dispatch) => {
    return dispatch({
      type: TOGGLE_MINIMIZED
    });
  };
}

export default {
  updateVideoRatio,
  onResizeVideo,
  handleDrag,
  turtleDragStart,
  turtleDragStop,
  handleResizeContainer,
  onLayoutChange,
  setShouldDisplayPrimaryVideo,
  setShouldDisplaySecondaryVideo,
  toggleDialPad,
  toggleCameraControls,
  togglePopout,
  checkVideoLoaded,
  startPolling,
  stopPolling,
  waitingToRepop,
  toggleMiniPlayerVideo,
  getVideoConfig
};
