import { IN_CALL_DRAWER_ACTION_CLOSE } from 'constants/InCall/InCallDrawer';
import {
  ADD_PARTICIPANT_SELECT,
  ADD_PARTICIPANT_FORM_FOCUSED,
  CLEAR_PARTICIPANT_ADD_LIST
} from 'constants/AddParticipantDrawer';

function clearParticipants(state) {
  const nextState = Object.assign({}, state);
  nextState.contact = null;
  return nextState;
}

const ACTION_HANDLERS = {
  [ADD_PARTICIPANT_SELECT]: (state, action) => {
    const nextState = Object.assign({}, state);
    nextState.contact = action.payload;
    return nextState;
  },
  // Once we are only galaxy we should be able to rely more on the observer for all this
  [CLEAR_PARTICIPANT_ADD_LIST]: (state) => clearParticipants(state),
  [IN_CALL_DRAWER_ACTION_CLOSE]: (state) => clearParticipants(state),
  [ADD_PARTICIPANT_FORM_FOCUSED]: (state, action) => {
    return {
      ...state,
      isFocused: action.payload
    };
  }
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = { contact: null, isFocused: false };
export default function addParticipantDrawerReducer(state = initialState, action = {}) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
