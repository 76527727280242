import { SET_UPGRADE_LINK } from 'constants/FlagConstants';
import _get from 'lodash/get';

const ACTION_HANDLERS = {
  [SET_UPGRADE_LINK]: (state, action) => {
    const nextState = Object.assign({}, state);
    nextState.displayUpgradeLink = _get(action, 'payload.displayUpgradeLink');
    return nextState;
  }
};

// ------------------------------------
// Reducer
// ------------------------------------
export const initialState = {
  displayUpgradeLink: false
};

export default function flagsReducer(state = initialState, action = {}) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
