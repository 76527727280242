import _get from 'lodash/get';
import lsConfig from 'utils/global-config';
import { isMobile, isSafari } from './browser-utils';

const LIFESIZE_SUBDOMAINS = {
  LOGIN: 'login',
  LEGACY: 'webapp'
};

const LIFESIZE_DOMAINS = {
  PRODUCTION: 'lifesizecloud.com',
  STAGING: 'lifesizecloudbeta.com',
  STAGING_ALT: 'lifesizebeta.com',
  DEVELOPMENT: 'lifesizeclouddev.com'
};

const adminConsoleUrl = {
  staging: 'https://manage.lifesizebeta.com/',
  production: 'https://manage.lifesize.com/'
};

/**
 * Gets the current lifesize domain API (i.e. lifesizecloud.com or lifesizecloudbeta.com)
 * @returns {string}
 */
export const getDomain = (useAltBeta = false) => {
  const domain = _get(window, 'lifesize.apiDomain', LIFESIZE_DOMAINS.PRODUCTION);
  if (useAltBeta && domain === LIFESIZE_DOMAINS.STAGING) return LIFESIZE_DOMAINS.STAGING_ALT;
  return domain;
};

export const getOrigin = () => {
  const origin = window.location.origin;
  if (window.desktopApp || _get(window, 'lifesize.basename') !== 'false') {
    const versionPath = window.location.pathname.split('/')[1];
    if (!versionPath.length || versionPath === '/' || versionPath === 'auth' || versionPath === 'auth.html')
      return origin;
    return `${origin}/${versionPath}`;
  }
  return origin;
};

// TODO: currently the login service only accepts the two-character language code
export const getLoginDomain = (returnUrl = `${getOrigin()}/auth`, language = 'en') => {
  const domain = getDomain();
  return `https://${LIFESIZE_SUBDOMAINS.LOGIN}.${domain}/ls/login/?source=nucleus.webclient&next=${encodeURIComponent(
    returnUrl
  )}&lang=${language}`;
};

export const getLegacyDomain = () => {
  const domain = getDomain();
  return `https://${LIFESIZE_SUBDOMAINS.LEGACY}.${domain}/#/login/`;
};

export const getLegacyJoinUrl = (extension, guestName = '') => {
  const domain = getDomain();
  return `https://${LIFESIZE_SUBDOMAINS.LEGACY}.${domain}/#/join/?extension=${extension}&name=${guestName}`;
};

export const getLogoutDomain = (returnUrl = getOrigin()) => {
  const domain = getDomain();
  return `https://${LIFESIZE_SUBDOMAINS.LOGIN}.${domain}/ls/logout/?source=nucleus.webclient&next=${encodeURIComponent(
    returnUrl
  )}`;
};

export const getAdminConsoleUrl = () => {
  return isStaging() ? adminConsoleUrl.staging : adminConsoleUrl.production;
};

export const getEcommerceAPIUrl = (jwt) => {
  const domain = getDomain();
  if (jwt) {
    return `https://ecommerce.${domain}/account?jwt=${jwt}`;
  }
  return `https://ecommerce.${domain}/account`;
};

export const getShopUrl = (jwt) => {
  const domain = isStaging() ? 'lifesizebeta.com' : 'lifesize.com';
  if (jwt) {
    return `https://shop.${domain}/?a=${jwt}&next=webapp`;
  }
  return `https://shop.${domain}/?next=webapp`;
};

export const isDevelopment = () => getDomain() === LIFESIZE_DOMAINS.DEVELOPMENT;

export const isStaging = () => getDomain() === LIFESIZE_DOMAINS.STAGING;

export const isProduction = () => getDomain() === LIFESIZE_DOMAINS.PRODUCTION;

export const loadUrl = (domain) => {
  if (!window.preventReload) {
    window.location = domain;
  }

  return domain;
};

/**
 * Function that will reload the application
 */
export function reloadApplication() {
  if (!window.preventReload) {
    window.location.reload(true);
  }
}

export const loadTrialPage = (trialURL) => {
  if (!window.desktopApp) {
    lsConfig.skipOnBeforeUnload();
    loadUrl(trialURL);
  }
  return trialURL;
};

export const getQueryParams = (str) =>
  (str || document.location.search)
    .replace(/(^\?)/, '')
    .split('&')
    .map(
      function (n) {
        // eslint-disable-next-line no-sequences
        return (n = n.split('=')), (this[n[0]] = n[1]), this;
      }.bind({})
    )[0]; //eslint-disable-line

export const redirectToLauncherPage = () => {
  let uri;
  const launcherUrl = process.env.REACT_APP_LAUNCHER_URL || 'https://launch.lifesize.com';
  uri = new URL(launcherUrl);
  if (isSafari() && !isMobile()) {
    uri.searchParams.append('forceDownloadOnFail', 'true');
  }
  window.location.replace(uri.href);
};

// opens a tab/window (based on browser settings).
// If a windowName is provided and already open, it will give focus to the window / tab instead of opening a new one
export const openNewTab = (url, windowName = '_blank') => {
  const newTab = window.open(url, windowName);

  // Check for falsy value for electron to avoid "Cannot read property 'focus' of undefined"
  if (newTab) {
    newTab.focus(); // if the window/tab is already open, it will receive the focus
  }
};

export default {
  loadUrl,
  loadTrialPage,
  getQueryParams,
  getDomain,
  getOrigin,
  getLoginDomain,
  getLegacyDomain,
  getLegacyJoinUrl,
  getLogoutDomain,
  isDevelopment,
  isStaging,
  isProduction,
  redirectToLauncherPage
};
