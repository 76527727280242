import {
  SELECT_COUNTRY,
  HANDLE_FOCUS,
  HANDLE_BLUR,
  SHOW_PSTN,
  HIDE_PSTN,
  VIDEO_MUTED,
  AUDIO_MUTED,
  IS_JOIN_CALL,
  CANCEL_JOIN_CALL,
  RESET_GUEST_CALL,
  BEGIN_LOGIN,
  END_LOGIN
} from 'constants/JoinModal';
import { nucleusConstants } from '@lifesize/nucleus';

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [BEGIN_LOGIN]: (state) => {
    const nextState = Object.assign({}, state);
    nextState.isLoggingIn = true;
    return nextState;
  },
  [END_LOGIN]: (state) => {
    const nextState = Object.assign({}, state);
    nextState.isLoggingIn = false;
    return nextState;
  },
  [nucleusConstants.GALAXY_ACTION_CALL_CONNECTED]: (state) => {
    const nextState = Object.assign({}, state);
    nextState.hasConnected = true;
    return nextState;
  },
  [SELECT_COUNTRY]: (state, action) => {
    const nextState = Object.assign({}, state);
    nextState.selectedCountry = action.payload;
    return nextState;
  },
  [HANDLE_FOCUS]: (state) => {
    const nextState = Object.assign({}, state);
    nextState.isPSTNFocused = true;
    return nextState;
  },
  [nucleusConstants.GALAXY_ACTION_END]: (state) => {
    const nextState = Object.assign({}, state);
    if (nextState.hasConnected) {
      nextState.remoteDisconnect = true;
    }
    return nextState;
  },
  [RESET_GUEST_CALL]: (state) => {
    const nextState = Object.assign({}, state);
    nextState.remoteDisconnect = false;
    return nextState;
  },
  [HANDLE_BLUR]: (state) => {
    const nextState = Object.assign({}, state);
    nextState.isPSTNFocused = false;
    return nextState;
  },
  [SHOW_PSTN]: (state) => {
    const nextState = Object.assign({}, state);
    nextState.isPSTNVisible = true;
    return nextState;
  },
  [HIDE_PSTN]: (state) => {
    const nextState = Object.assign({}, state);
    nextState.isPSTNVisible = false;
    return nextState;
  },
  [IS_JOIN_CALL]: (state) =>
    Object.assign({}, state, {
      isJoinFlowCall: true
    }),
  [CANCEL_JOIN_CALL]: (state) => Object.assign({}, state, { isJoinFlowCall: false }),
  [VIDEO_MUTED]: (state, action) => Object.assign({}, state, { isVideoEnabled: action.payload }),
  [AUDIO_MUTED]: (state, action) => Object.assign({}, state, { isAudioEnabled: action.payload })
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  isLoggingIn: false,
  remoteDisconnect: false,
  selectCountry: {},
  isPSTNFocused: false,
  selectedCountry: false,
  isPSTNVisible: false,
  isAudioEnabled:
    localStorage.getItem('defaultAudioSetting') === null
      ? true
      : localStorage.getItem('defaultAudioSetting') === 'true',
  isVideoEnabled:
    localStorage.getItem('defaultAudioSetting') === null
      ? true
      : localStorage.getItem('defaultVideoSetting') === 'true',
  isJoinFlowCall: false,
  hasConnected: false
};
export default function JoinModalReducer(state = initialState, action = {}) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
