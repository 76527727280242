import { createSelector } from 'reselect';
import { FLAG_POPOUT_PRESENTATIONS } from 'constants/FeatureFlags';
import { featureSelectors } from '@lifesize/nucleus';
import _get from 'lodash/get';

const popoutPresentationFeatureFlag = (state) => featureSelectors.getFeatureFlag(state)(FLAG_POPOUT_PRESENTATIONS);
export const selectIsWebConference = (state) => _get(state, 'app.call.isWebConference');
const popoutPresentationPoppedSelector = (state) => _get(state, 'app.inCallVideo.videoLayout.popoutPresentation');

export const popoutPresentationFeatureSelector = createSelector(
  popoutPresentationFeatureFlag,
  (popoutPresentationFeature, pluginSupported) => popoutPresentationFeature && !pluginSupported
);

export const popoutPresentationSelector = createSelector(
  popoutPresentationFeatureSelector,
  popoutPresentationPoppedSelector,
  (popoutPresentationFeature, presentationIsPopped) =>
    // TODO: PT: HACK: add to sdk / use hooks to get presentation stream info
    popoutPresentationFeature && presentationIsPopped && !!_get(window['StreamManager'], 'Instance.presentationStream')
);
