import {
  SET_PILL_NOTIFICATION,
  DELETE_PILL_NOTIFICATION,
  SET_TOOLTIP_NOTIFICATION,
  CLEAR_TOOLTIP_NOTIFICATION,
  SET_INCALL_NOTIFICATION,
  CLEAR_INCALL_NOTIFICATION
} from 'constants/Notification';

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [SET_PILL_NOTIFICATION]: (state, action) => {
    const nextState = Object.assign({}, state);
    nextState.pill = action.payload.message;
    return nextState;
  },
  [DELETE_PILL_NOTIFICATION]: (state) => {
    const nextState = Object.assign({}, state);
    nextState.pill = null;
    return nextState;
  },
  [SET_TOOLTIP_NOTIFICATION]: (state, action) => {
    return {
      ...state,
      tooltips: { ...state.tooltips, [action.payload.tooltipId]: action.payload.messageObj }
    };
  },
  [CLEAR_TOOLTIP_NOTIFICATION]: (state, action) => {
    return {
      ...state,
      tooltips: { ...state.tooltips, [action.payload.tooltipId]: undefined }
    };
  },
  [SET_INCALL_NOTIFICATION]: (state, action) => {
    return {
      ...state,
      inCall: action.payload.message,
      inCallActive: true
    };
  },
  [CLEAR_INCALL_NOTIFICATION]: (state, action) => {
    return {
      ...state,
      inCallActive: false
    };
  }
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  nextId: 0,
  notifications: [],
  pill: null,
  tooltips: {},
  inCall: null,
  inCallActive: false // the active state is necessary because of the method of showing/hiding the notifications (they need to exist as they are hidden)
};

export default function modalReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
