import { Action } from 'redux';
import { nucleusConstants, servicesSelectors } from '@lifesize/nucleus';
import { loadInitialMeetings, loadInitialRooms, loadInitialUsers, clearDirectoryLoaded } from '../actions/Contact';
import { routes } from 'constants/Application';
import { selectCurrentPath } from 'selectors/auth';

const loadDirectorySelectively = (store: any, path: string) => {
  if (!path) return;
  const state = store.getState();
  const wampConnected = servicesSelectors.selectConnected(state);
  if (!wampConnected) return;
  switch (path) {
    case routes.roomSystems:
      store.dispatch(loadInitialRooms());
      break;
    case routes.contacts:
      store.dispatch(loadInitialUsers());
      break;
    case routes.meetings:
      store.dispatch(loadInitialMeetings());
      break;
  }
};

const contactsMiddleware = (store: any) => (next: (action: Action) => void) => async (action: Action) => {
  const result = next(action);
  switch (action.type) {
    case nucleusConstants.INITIALIZE_SERVICES_SUCCESS:
      // Reset directory "loaded" state
      store.dispatch(clearDirectoryLoaded());
      // Load directory selectively when user is in a directory tab and just finished connecting
      loadDirectorySelectively(store, selectCurrentPath(store.getState()));
      break;
  }
  return result;
};

export default contactsMiddleware;
