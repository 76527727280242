import { conferenceParticipantsSelectors, currentUserSelectors, nucleusConstants } from '@lifesize/nucleus';
import _get from 'lodash/get';
import { setTooltipNotification, clearTooltipNotification } from 'actions/Notification';
import { tooltipNotifications, tooltipNotificationTypes } from 'constants/Notification';

const messages = {
  otherHandRaised: { id: 'notification.tooltip.otherHandRaised', defaultMessage: '{name} has raised their hand' },
  ownHandLoweredByModerator: {
    id: 'notification.tooltip.ownHandLoweredByModerator',
    defaultMessage: 'A moderator has lowered your hand'
  },
  ownHandLoweredBySelf: {
    id: 'notification.tooltip.ownHandLoweredBySelf',
    defaultMessage: 'Your hand has been lowered'
  },
  ownHandLoweredBySelfFailure: {
    id: 'notification.tooltip.ownHandLoweredBySelfFailure',
    defaultMessage: 'An error occurred while lowering your hand',
    messageType: tooltipNotificationTypes.error
  },
  ownHandRaisedBySelf: { id: 'notification.tooltip.ownHandRaisedBySelf', defaultMessage: 'Your hand has been raised' },
  ownHandRaisedBySelfFailure: {
    id: 'notification.tooltip.ownHandRaisedBySelfFailure',
    defaultMessage: 'An error occurred while raising your hand',
    messageType: tooltipNotificationTypes.error
  }
};

const setNotification = (tooltipId, messageObj) => {
  return setTooltipNotification(tooltipId, messageObj);
};

export const clearNotification = (tooltipId) => {
  return clearTooltipNotification(tooltipId);
};

const raisedHandMiddleware = (store) => (next) => (action) => {
  const state = store.getState();
  const myUuid = currentUserSelectors.getUserUUID(state);

  switch (action.type) {
    case nucleusConstants.GALAXY_ACTION_CONFERENCE_ALL_HANDS_LOWERED:
      if (!!conferenceParticipantsSelectors.selectIsCurrentUserHandRaised(state)) {
        store.dispatch(
          setNotification(tooltipNotifications.inCallActionBtnRaiseHand, {
            ...messages.ownHandLoweredByModerator,
            values: { name: _get(action, 'payload.initiatedBy.displayName') }
          })
        );
      }
      break;
    case nucleusConstants.GALAXY_ACTION_CONFERENCE_PARTICIPANT_HAND_LOWERED:
      if (myUuid === _get(action, 'payload.participant.uuid') && myUuid !== _get(action, 'payload.initiatedBy.uuid')) {
        store.dispatch(
          setNotification(tooltipNotifications.inCallActionBtnRaiseHand, {
            ...messages.ownHandLoweredByModerator,
            values: { name: _get(action, 'payload.initiatedBy.displayName') }
          })
        );
      }
      break;
    case nucleusConstants.GALAXY_ACTION_CONFERENCE_PARTICIPANT_HAND_RAISED:
      if (myUuid !== _get(action, 'payload.participant.uuid')) {
        store.dispatch(
          setNotification(tooltipNotifications.inCallDrawerBtnRaiseHand, {
            ...messages.otherHandRaised,
            values: { name: _get(action, 'payload.participant.name') }
          })
        );
      }
      break;
    case nucleusConstants.SELF_LOWER_HAND_FAILURE:
      store.dispatch(
        setNotification(tooltipNotifications.inCallActionBtnRaiseHand, messages.ownHandLoweredBySelfFailure)
      );
      break;
    case nucleusConstants.SELF_LOWER_HAND_SUCCESS:
      store.dispatch(setNotification(tooltipNotifications.inCallActionBtnRaiseHand, messages.ownHandLoweredBySelf));
      break;
    case nucleusConstants.SELF_RAISE_HAND_FAILURE:
      store.dispatch(
        setNotification(tooltipNotifications.inCallActionBtnRaiseHand, messages.ownHandRaisedBySelfFailure)
      );
      break;
    case nucleusConstants.SELF_RAISE_HAND_SUCCESS:
      store.dispatch(setNotification(tooltipNotifications.inCallActionBtnRaiseHand, messages.ownHandRaisedBySelf));
      break;
    default:
      break;
  }
  return next(action);
};

export default raisedHandMiddleware;
