import { RESET_CALL_SETTINGS, TOGGLE_REMEMBER_SETTINGS, TOGGLE_AUDIO_TEST } from 'constants/AvSettingsModal';

const initialState = {
  preserveAvSettings: false,
  rememberSettings: false,
  audioTesting: false
};

const ACTION_HANDLERS = {
  [TOGGLE_REMEMBER_SETTINGS]: (state) => {
    const rememberSettings = !state.rememberSettings;
    return { ...state, rememberSettings };
  },
  [TOGGLE_AUDIO_TEST]: (state, action) => {
    return { ...state, audioTesting: action.payload };
  },
  [RESET_CALL_SETTINGS]: () => initialState
};

export default function AvSettingsModalReducer(state = initialState, action = {}) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
