import { START_ROUTE } from 'constants/StartCallModal';

export const NAVIGATE_TO_TRIAL_PAGE = 'NAVIGATE_TO_TRIAL_PAGE';

export const highQualityHardwareConcurrencyLimit = 4;

export const routes = {
  base: '/',
  home: '/home',
  chat: '/chat',
  contacts: '/contacts',
  meetings: '/meetings',
  roomSystems: '/room-systems',
  upcoming: '/upcoming',
  auth: '/auth',
  startRoute: START_ROUTE,
  join: '/join/:extension/:groupId?',
  desktopLogout: '/desktop-logout'
};
export const publicRoutes = {
  guest: '/guest',
  shareHdmi: '/shareHDMI',
  connectPlusHome: '/connectPlusHome'
};

export const basePath = process.env.REACT_APP_BASEPATH ? `/${process.env.REACT_APP_BASEPATH}` : '';

export default {
  basePath,
  NAVIGATE_TO_TRIAL_PAGE,
  publicRoutes,
  routes
};
