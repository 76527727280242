import { selectors } from '@lifesize/clients.sdk';
import {
  appActions,
  authActions,
  callActions,
  chatActions,
  chatSelectors,
  mediaSelectors,
  streamActions,
  tokenActions
} from '@lifesize/nucleus';
import { media } from '@lifesize/clients.sdk';
import { openFullScreenModal } from 'actions/FullScreenModal';
import { openInCallModal, electronRequestMinimize } from 'actions/InCallModal';
import actions from 'actions/SettingsModal';
import Auth, { reconnectLogin } from 'actions/Auth';
import {
  deleteCalendarNotification,
  calendarListEvents,
  calendarUser,
  calendarExternalAuth,
  outlookListEventsAndDispatch,
  refreshCalendars,
  calendarApiError
} from 'actions/Calendar';
import { audioUnmuteByUser } from 'actions/Call';
import Contact from 'actions/Contact';
import { toggleMiniPlayer } from 'actions/MiniPlayer';
import { routes } from 'constants/Application';
import { AV_SETTINGS } from 'constants/AvSettingsModal';
import { JOIN_MODAL } from 'constants/JoinModal';
import { SETTINGS_MODAL, AV_TAB } from 'constants/SettingsModal';
import { calendars } from 'constants/Calendar';
import _get from 'lodash/get';
import { selectIsLoggedIn } from 'selectors/auth';
import { currentConversationDialStringSelector } from 'selectors/chat';
import { isMiniPlayerOpenSelector } from 'selectors/miniPlayer';
import { logger } from 'utils/logger';
import { getChromeDesktopConstraints } from 'utils/mediaConstraints';

/**
 * Defines methods for the web client API.
 * This is a facade that allows the desktop client (Electron) to call actions into the web app.
 */
export default class Api {
  /**
   * Initializes a new instance of the Api class.
   * @param {object} store The redux store that will be used to dispatch actions.
   */
  constructor(store, signalingStore) {
    this.store = store;
    this.signalingStore = signalingStore;
    this.authActions = authActions;
    this.tokenActions = tokenActions;
  }

  /**
   * Exposes the web client API globally via "window"
   */
  static initialize(store, signalingStore) {
    const api = new Api(store, signalingStore);
    window.webClientApi = api;
    return api;
  }

  getActiveCall() {
    // PT: return additional things needed by desktop
    return selectors.call();
  }

  getLoginInfo() {
    // eslint-disable-line class-methods-use-this
    return { isLoggedIn: selectIsLoggedIn(this.store.getState()) };
  }

  getSelectedVideoDevice() {
    // eslint-disable-line class-methods-use-this
    return mediaSelectors.selectedVideoDevice(this.store.getState());
  }

  /** Signs out the user from the application */
  logout() {
    return this.store.dispatch(Auth.logout());
  }

  /**
   * Starts a video call.
   * @param {object|string} contact The contact or extension to be dialed.
   */
  startVideoCall(contact) {
    return this.store.dispatch(Contact.startVideoCall(contact));
  }

  /** End the current call. */
  endCall() {
    return this.store.dispatch(callActions.end());
  }

  requestMinimize() {
    return this.store.dispatch(electronRequestMinimize());
  }

  /** Toggles on/off audio during a call. */
  toggleAudio() {
    const currentUserParticipant = selectors.currentUserParticipant();
    const isAudioMuted = selectors.isAudioMuted();
    const isModerator = selectors.isCurrentUserModerator();
    const isMutedByServer = _get(currentUserParticipant, 'serverMuteState', false);

    if (isMutedByServer && isModerator) {
      this.store.dispatch(audioUnmuteByUser(true));
      return this.store.dispatch(streamActions.setAudioMuteState(false));
    }
    return this.store.dispatch(streamActions.setAudioMuteState(!isAudioMuted));
  }

  getActivateCallDuration() {
    const call = selectors.call();
    const endTime = _get(call, 'endTime', Date.now());
    const startTime = _get(call, 'startTime', Date.now());
    return endTime - startTime;
  }

  /* Shows AV Settings Modal */
  showAudioVideoSettings() {
    this.store.dispatch(actions.handleChangeTab(AV_TAB));
    if (selectors.callConnected()) {
      return this.store.dispatch(openInCallModal(AV_SETTINGS));
    }
    return this.store.dispatch(openFullScreenModal(SETTINGS_MODAL));
  }

  showJoinFlow(extension) {
    // Don't show JOIN_MODAL when call is connected and notification is clicked.
    if (!selectors.callConnected()) {
      return this.store.dispatch(openFullScreenModal(JOIN_MODAL, { extension, isLoggedIn: true }));
    }

    return null;
  }

  joinCall(extension) {
    this.store.dispatch(callActions.call(extension));
  }

  calendarEmail(type, email) {
    this.store.dispatch(calendarUser(email, type));
  }

  calendarAuth(type, auth) {
    this.store.dispatch(calendarExternalAuth(auth, type));
    if (type === calendars.outlook) {
      this.store.dispatch(outlookListEventsAndDispatch(auth.access_token));
    }
  }

  calendarEvents(type, events) {
    this.store.dispatch(calendarListEvents(events, type));
  }

  closeNotification(id) {
    this.store.dispatch(deleteCalendarNotification(id));
  }

  calendarError(type, error) {
    this.store.dispatch(calendarApiError(error, type));
  }

  /**
   * Starts a screen sharing session.
   * @param {string} sourceId The identifier of a window or screen that will be shared.
   * @param {string} [sourceName] The name of a window or screen that will be shared.
   */
  async startPresentation(sourceId, sourceName = null, audio = false) {
    const constraints = getChromeDesktopConstraints(sourceId, audio);
    try {
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      media.setPresentation(stream);
    } catch (e) {
      logger.error(`Error occurred while getting presentation stream`, e, constraints);
    }
  }

  stopPresentation() {
    return this.store.dispatch(streamActions.stopPresentation());
  }

  foreground() {
    return this.store.dispatch(appActions.foreground());
  }

  background() {
    return this.store.dispatch(appActions.background());
  }

  reconnect() {
    this.store.dispatch(refreshCalendars());
    return this.store.dispatch(reconnectLogin());
  }

  markConversationsRead() {
    const state = this.store.getState();
    const chatWindowOpen = _get(state, 'app.sideNav.routeLoaded') === routes.chat;
    if (chatWindowOpen) {
      const conversationContactDialString = currentConversationDialStringSelector(state);
      const contact = _get(chatSelectors.getChatForWebGenerator(state)(conversationContactDialString), 'contact');
      this.store.dispatch(chatActions.markConversationMessagesAsRead(contact));
    }
  }

  showMiniPlayer() {
    this.store.dispatch(toggleMiniPlayer());
  }

  hideMiniPlayer() {
    const isMiniPlayerOpen = isMiniPlayerOpenSelector(this.store.getState());
    if (isMiniPlayerOpen) {
      this.store.dispatch(toggleMiniPlayer());
    }
  }
}
