import { Analytics } from '@lifesize/web-clients-core';

function sendProfile(userProfile, isFreemiumAdmin) {
  const accountId = userProfile?.groupUuid;
  const role = userProfile?.role;
  const email = userProfile?.email;
  const name = userProfile?.name;
  const id = userProfile?.uuid;
  const user = {
    id,
    accountId,
    role,
    email,
    name,
    isFreemiumAdmin
  };
  const account = { id: accountId };
  Analytics.identify(user, account);
}

export enum TrackEvents {
  CallEnded = 'CallEnded',
  JoinModal = 'JoinModal',
  EnterCall = 'EnterCall',
  Path = 'Path',
  SendChat = 'SendChat',
  VideoSettingsDisplayed = 'VideoSettingsDisplayed',
  VbbOptionLoaded = 'VbbOptionLoaded',
  VbbOptionSelected = 'VbbOptionSelected',
  Call = 'call'
}

export default { sendProfile };
