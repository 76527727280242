import { createSlice } from '@reduxjs/toolkit';

export interface SelfViewState {
  on: boolean;
}

const initialState: SelfViewState = {
  on: true // is the pip visible in the CallLayout (from sdk) when the viewport indicates isMobileView
};

const selfViewSlice = createSlice({
  name: 'selfView',
  initialState,
  reducers: {
    toggleSelfView(state) {
      state.on = !state.on;
    }
  }
});

export const { toggleSelfView } = selfViewSlice.actions;
export default selfViewSlice.reducer;
