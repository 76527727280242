import {
  BOOKMARK_SCROLL_POSITION,
  SELECT_CONVERSATION_ID,
  SET_CHAT_MODE,
  CHAT_SEARCH_FORM_FOCUSED,
  CHAT_MESSAGE_RECEIVED,
  SET_OVERLAY_ICON,
  DESKTOP_CONVERSATIONS_READ
} from 'constants/Chat';

export function bookmarkScrollPosition(conversationContactDialString, settings) {
  return {
    type: BOOKMARK_SCROLL_POSITION,
    payload: {
      conversationContactDialString,
      settings
    }
  };
}

export function desktopMarkConversationMessagesAsRead(contact) {
  return {
    type: DESKTOP_CONVERSATIONS_READ,
    payload: contact
  };
}

export function selectConversationContactDialString(selectedConversationContactDialString) {
  return {
    type: SELECT_CONVERSATION_ID,
    payload: {
      selectedConversationContactDialString
    }
  };
}

export function setChatMode(conversationMode = 'current') {
  return {
    type: SET_CHAT_MODE,
    payload: {
      conversationMode
    }
  };
}

export function setFormFocus(isFocused) {
  return {
    type: CHAT_SEARCH_FORM_FOCUSED,
    payload: isFocused
  };
}

export function messageReceived() {
  return {
    type: CHAT_MESSAGE_RECEIVED,
    payload: false
  };
}

export function setOverlayIcon(dataURL, unreadChatCount) {
  return {
    type: SET_OVERLAY_ICON,
    payload: {
      dataURL,
      unreadChatCount
    }
  };
}

export default {
  bookmarkScrollPosition,
  desktopMarkConversationMessagesAsRead,
  selectConversationContactDialString,
  setChatMode,
  setFormFocus,
  setOverlayIcon
};
