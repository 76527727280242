import _get from 'lodash/get';
import _find from 'lodash/find';
import { chatSelectors, currentUserSelectors } from '@lifesize/nucleus';
import { createSelector } from 'reselect';

const currentUserUuid = (state) => currentUserSelectors.getUserUUID(state);
const allMeetingConversations = (state) => chatSelectors.groupedConversations(state).meetingConversations || [];
const allUserConversations = (state) => chatSelectors.groupedConversations(state).userConversations || [];
const unreadMessageConversations = (state) =>
  chatSelectors.groupedConversationsWithUnread(state).unreadMessageConversations || [];

// Out-of-call meetings for basic users are no longer being shown (CA-2042)
export const meetingConversationsSelector = createSelector(
  currentUserUuid,
  allMeetingConversations,
  (currentUserUuid, meetingConversations) => {
    // Filter for current user === owner; don't show for admins, superusers or moderators of a meeting.
    return meetingConversations.filter((convo) => convo.meeting?.owner?.uuid === currentUserUuid);
  }
);

// Out-of-call meetings for any user type are no longer being counted (CA-2042)
export const unreadConversationCountSelector = createSelector(
  unreadMessageConversations,
  (unreadMessageConversations) => {
    // Filter meetings for current user === moderator or owner; no filter for admins && superusers
    const unreadConversations = unreadMessageConversations.filter((convo) => !convo.contact?.isMeeting);
    return unreadConversations.reduce((accum, convo) => accum + convo.unreadMessageCount, 0);
  }
);

const appUnreadCount = (state) => _get(state, 'app.chat.unreadChatCount');

export const hasUnreadChats = createSelector(
  unreadConversationCountSelector,
  appUnreadCount,
  (nucleusUnread, appUnread) => nucleusUnread <= 99 && nucleusUnread !== appUnread
);

const convDialString = (state) => _get(state, 'app.chat.selectedConversationContactDialString');

export function getNewConversationContactDialString(conversation) {
  if (conversation) {
    return conversation.contact.dialString;
  }
  return undefined;
}

export const currentConversationDialStringSelector = createSelector(
  convDialString,
  meetingConversationsSelector,
  allUserConversations,
  (conversationContactDialString, meetingConversations, userConversations) => {
    let selectedConversationContactDialString = conversationContactDialString;

    /* not all conversations are available out of a call, so if you have chatted in an in-call VMR and view chat out of
     * a call, select the first available conversation */
    const allConversations = [...userConversations, ...meetingConversations];

    if (!_find(allConversations, { contact: { dialString: conversationContactDialString } })) {
      selectedConversationContactDialString = getNewConversationContactDialString(allConversations.shift());
    }

    return selectedConversationContactDialString;
  }
);
