import { AUDIO_UNMUTE_BY_USER, TRACK_AUDIO_CALL, TRACK_VIDEO_CALL } from 'constants/Call';

export function trackVideoCall() {
  return {
    type: TRACK_VIDEO_CALL
  };
}

export function trackAudioCall() {
  return {
    type: TRACK_AUDIO_CALL
  };
}

export function audioUnmuteByUser(isUnmuteInitiatedByUser) {
  return {
    type: AUDIO_UNMUTE_BY_USER,
    payload: {
      audioUnmuteByUser: isUnmuteInitiatedByUser
    }
  };
}

export default {
  trackVideoCall,
  trackAudioCall,
  audioUnmuteByUser
};
