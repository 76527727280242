import Bowser from 'bowser';
import _get from 'lodash/get';
import _memoize from 'lodash/memoize';

const browser = Bowser.getParser(window.navigator.userAgent);

const getBrowser = _memoize(() => {
  const userAgent = _get(navigator, 'userAgent');
  return Bowser.getParser(userAgent);
});

const getOsInfo = _memoize(() => {
  const osVersion = getBrowser().getOS();
  const versionParts = osVersion.version ? osVersion.version.split('.') : [];
  return {
    name: osVersion.name ? osVersion.name.toLowerCase() : '',
    versionName: osVersion.versionName || '',
    major: versionParts && versionParts.length ? parseInt(versionParts[0], 10) : 0,
    minor: versionParts && versionParts.length > 1 ? parseInt(versionParts[1], 10) : 0
  };
});

const getOS = () => {
  return getOsInfo().name;
};

export const isWebAppSupported = () => {
  // console.log(browser.parsedResult);
  const isBrowserSupported = browser.satisfies({
    chrome: '>=73',
    chromium: '>=73',
    edge: '>=76'
  });
  return isBrowserSupported && !isMobile();
};

export const isMobile = () => {
  const platform = browser.getPlatform();
  const type = platform && platform.type ? browser.getPlatform().type.toLowerCase() : null;
  return type === 'mobile' || type === 'tablet' || isIpadMasqueradingAsDesktop();
};

const isIos = () => getOS() === 'ios';

export const isIpad = () => {
  return (
    navigator?.maxTouchPoints > 1 &&
    typeof window?.AuthenticatorAssertionResponsewindow?.AuthenticatorAttestationResponse === 'undefined' &&
    navigator?.plugins?.length === 0
  );
};

export const isIosMobile = _memoize(() => {
  if (isChrome()) {
    return false;
  }
  if (isIos()) {
    return true;
  }
  if (!!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)) {
    return true;
  }
  if (isIpad()) {
    return true;
  }
  return false;
});

export const isSafari = () => {
  return browser.isBrowser('safari');
};

export const isChrome = () => {
  // Note: this has been changed to check for the Chrome Engine rather than the browser specifically (CA-1987)
  return browser.getEngineName() === 'Blink'; // ENGINE_MAP.Blink is importable from bowser but causes tests to fail
};

export const openCenteredPopUp = (url, options) => {
  const popUpWidth = options.width;
  const popUpHeight = options.height;

  const winLeft = window.screenLeft ? window.screenLeft : window.screenX;
  const winTop = window.screenTop ? window.screenTop : window.screenY;
  /*
   * window.innerWidth displays browser window's height and width excluding toolbars
   * using document.documentElement.clientWidth for IE8 and earlier
   */
  const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  const height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
  const left = width / 2 - popUpWidth / 2 + winLeft;
  const top = height / 2 - popUpHeight / 2 + winTop;

  const windowProps = [
    'menubar=0',
    'location=false',
    'toolbar=0',
    'personalbar=0',
    'titlebar=0',
    'status=0',
    `width=${popUpWidth}`,
    `height=${popUpHeight}`,
    `left=${left}`,
    `top=${top}`
  ].join(',');
  return window.open(url, '', windowProps);
};

export const isIpadMasqueradingAsDesktop = () => {
  if (!!navigator.platform && navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1) {
    return true;
  }
  return false;
};

export const isWindows = () => {
  const osInfo = browser?.parseOS();
  return osInfo?.name?.toLocaleLowerCase() === 'windows';
};

export const isWindows81 = () => {
  const osInfo = browser?.parseOS();
  return osInfo?.name?.toLocaleLowerCase() === 'windows' && osInfo?.versionName === '8.1';
};

export const isCatalina = () => {
  const osInfo = browser?.parseOS();
  if (osInfo?.name?.toLocaleLowerCase() !== 'macos') return false;

  const versionInfo = osInfo?.version.split('.');
  if (versionInfo.length !== '3') return false;

  const satisfiesMajor = parseInt(versionInfo[0], 10) >= 10;
  const satisfiesMinor = parseInt(versionInfo[1], 10) >= 15;
  return satisfiesMajor && satisfiesMinor;
};

const getBrowserInfo = _memoize(() => {
  const browserVersion = getBrowser().getBrowser();
  const versionParts = browserVersion.version ? browserVersion.version.split('.') : [];
  return {
    name: browserVersion.name ? browserVersion.name.toLowerCase() : '',
    major: versionParts && versionParts.length ? parseInt(versionParts[0], 10) : 0,
    minor: versionParts && versionParts.length > 1 ? parseInt(versionParts[1], 10) : 0
  };
});
export const isPresentationEnabled = () =>
  !isMobile() && typeof _get(navigator, 'mediaDevices.getDisplayMedia', undefined) === 'function';

export const webRTCSafariWorks = () => {
  const osInfo = getOsInfo();
  const browserInfo = getBrowserInfo();
  return (
    isSafari() &&
    ((isIos() && osInfo.major >= 14) ||
      (isIpad() && browserInfo.major >= 14) ||
      (isIos() && osInfo.major >= 13 && osInfo.minor >= 4) ||
      (isIpad() && browserInfo.major >= 13 && browserInfo.minor >= 1))
  );
};
