import {
  addCalendarNotification,
  deleteCalendarNotification,
  clearCalendarNotification,
  updateUpcomingNotification,
  removeUpcomingNotification,
  selectCalendarEvent
} from 'actions/Calendar';
import { callActions, directoryActions, environmentSelectors, localizationSelectors } from '@lifesize/nucleus';
import * as calendarSelectors from 'selectors/calendar';
import { calendars } from 'constants/Calendar';
import buddyIcon from 'assets/images/buddy_128x128.png';
import callIcon from '@lifesize/ux-assets/images/webclient/PNG/video-grey.png';
import cancelIcon from '@lifesize/ux-assets/images/webclient/PNG/grey-x-thick.png';
import { getStartTime, getEndTime } from 'utils/calendar-utils';
import { defineMessages } from 'react-intl';
import _get from 'lodash/get';
import _find from 'lodash/find';

const removeNotificationTimers = [];
const notificationTimers = [];
const timersGroupByCalendarId = {
  [calendars.outlook]: [],
  [calendars.google]: []
};

const messages = defineMessages({
  join: {
    id: 'notification.meeting.join',
    defaultMessage: 'Join'
  },
  dismiss: {
    id: 'notification.meeting.dismiss',
    defaultMessage: 'Dismiss'
  }
});

export const makeNotification = (title, notificationOptions, isDesktop) => {
  if (isDesktop) {
    return new Notification(title, notificationOptions);
  }

  return window.notificationServiceRegistration.showNotification(title, notificationOptions);
};

export const handleJoin = (store, extension) => {
  store.dispatch(callActions.call(extension));
};

export const handleClose = (store, id) => {
  store.dispatch(deleteCalendarNotification(id));
};

export const queueNotification = (store, event, timeoutLength, timeUntilEnd) => {
  const state = store.getState();
  const meetingNotifications = _get(state, 'app.calendar.calendarNotifications');
  const timeUntils = [...Array(16).keys()];

  timeUntils.some((timeUntil) => {
    // returns a boolean (true if at least one passes, stops iterating when one passes)
    let timeUntilLength = timeoutLength - timeUntil * 60 * 1000; // the number of minutes in milliseconds
    if (Math.sign(timeUntilLength) === -1) timeUntilLength = 0;
    if (timeUntil === 15 && timeUntilLength) {
      // time until the meeting is greater than 15 minutes
      store.dispatch(removeUpcomingNotification(event.id));
    }
    setTimeout(() => {
      store.dispatch(updateUpcomingNotification(event, timeUntil));
    }, timeUntilLength);

    return timeUntilLength === 0;
  });

  setTimeout(() => {
    store.dispatch(removeUpcomingNotification(event.id));
  }, timeUntilEnd);

  if (
    !_find(
      meetingNotifications,
      (meetingNotification) => meetingNotification.id === event.id && meetingNotification.shown
    )
  ) {
    if (!timeoutLength) {
      addHTMLNotification(event, store);
      return;
    }
    const notificationTimer = setTimeout(() => {
      addHTMLNotification(event, store);
    }, timeoutLength);
    notificationTimers.push(notificationTimer);
    timersGroupByCalendarId[event.calendarType].push(notificationTimer);
  }
};

export const checkEvents = (store) => {
  const state = store.getState();

  notificationTimers.forEach((timer) => {
    clearTimeout(timer);
  });

  notificationTimers.length = 0;

  removeNotificationTimers.forEach((timer) => {
    clearTimeout(timer);
  });

  removeNotificationTimers.length = 0;

  const allEvents = calendarSelectors.lifesizeMeetingsSelector(state);

  const currentTime = Date.now();

  const meetingNotifications = _get(state, 'app.calendar.calendarNotifications');

  const selectedEventId = calendarSelectors.selectedMeetingIdSelector(state);
  const existingEvent = _find(allEvents, (apiEvent) => apiEvent.id === selectedEventId);
  if (!existingEvent) {
    store.dispatch(selectCalendarEvent(null));
  }

  meetingNotifications.forEach((event) => {
    const existingNotification = _find(allEvents, (apiEvent) => apiEvent.id === event.id);
    const savedDateTime = getStartTime(event);
    if (!existingNotification || getStartTime(existingNotification) !== savedDateTime) {
      // The event we're currently showing a notification for is gone
      store.dispatch(deleteCalendarNotification(event.id));
      if (_get(state, ['app', 'calendar', event.calendarType, 'eventsLoaded'])) {
        store.dispatch(clearCalendarNotification(event.id));
      }
    }
  });

  const upcomingNotifications = _get(state, 'app.calendar.upcomingNotifications');

  if (upcomingNotifications) {
    Object.keys(upcomingNotifications).forEach((id) => {
      const existingNotification = _find(allEvents, (apiEvent) => apiEvent.id === id);
      if (!existingNotification) {
        // The event we're tracking is gone
        store.dispatch(removeUpcomingNotification(id));
      }
    });
  }

  const extensionsToLoad = [];

  allEvents.forEach((event) => {
    const startTime = getStartTime(event);
    if (event.extension) {
      extensionsToLoad.push(event.extension);
    }
    if (!startTime) return;
    const startTimeMilliseconds = new Date(startTime);
    const eventStartDateTime = _get(event, 'start.dateTime');
    if (!eventStartDateTime) {
      startTimeMilliseconds.setHours(0, 0, 0, 0);
    }
    const endTime = getEndTime(event);

    if (endTime) {
      const endTimeMilliseconds = new Date(endTime);
      const eventEndDateTime = _get(event, 'end.dateTime');
      if (!eventEndDateTime) {
        endTimeMilliseconds.setHours(23, 59, 59, 999);
      }
      if (currentTime <= endTimeMilliseconds) {
        let timeoutLength = startTimeMilliseconds - currentTime;
        if (Math.sign(timeoutLength) === -1) timeoutLength = 0;
        const timeUntilEnd = endTimeMilliseconds - currentTime;
        queueNotification(store, event, timeoutLength, timeUntilEnd);
        return;
      }
    }

    let timeDifference = new Date(startTime) - currentTime;
    if (Math.sign(timeDifference) === -1) timeDifference = 0;
    queueNotification(store, event, timeDifference);
  });

  store.dispatch(directoryActions.loadByExtensions(extensionsToLoad));
};

export const addHTMLNotification = (event, store) => {
  const state = store.getState();
  const inviteURLPattern = event.inviteUrl;
  const extension = event.extension;

  const startTime = new Date(getStartTime(event));
  const endTime = getEndTime(event);
  const timeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit'
  };

  const languageId = _get(localizationSelectors.localization(state), 'languageId'); // BCP 47 language tag

  let formattedTime = startTime.toLocaleTimeString(languageId, timeFormatOptions);
  const eventName = _get(event, 'title');

  // We remove the notification when the meeting is over
  let timeUntilRemove = 0;
  if (endTime) {
    const endDate = new Date(endTime);
    const currentTime = Date.now();

    timeUntilRemove = endDate - currentTime;

    if (timeUntilRemove / 1000 / 60 / 60 < 24) {
      const formattedEnd = endDate.toLocaleTimeString(languageId, timeFormatOptions);
      formattedTime += ` - ${formattedEnd}`;
    }

    if (Math.sign(timeUntilRemove !== -1)) {
      const removeNotificationTimer = setTimeout(() => {
        store.dispatch(deleteCalendarNotification(event.id));
      }, timeUntilRemove);
      removeNotificationTimers.push(removeNotificationTimer);
    }
  }

  const isDesktop = environmentSelectors.platform(state).isDesktop;
  const callUrl = inviteURLPattern + extension;
  const actions = [
    {
      action: 'call-action',
      title: _get(state, ['app', 'i18n', 'messages', messages.join.id]),
      icon: callIcon
    }
  ];

  if (navigator.platform === 'Win32' || isDesktop) {
    actions.push({
      action: 'dismiss-action',
      title: _get(state, ['app', 'i18n', 'messages', messages.dismiss.id]),
      icon: cancelIcon
    });
  }

  const notificationOptions = {
    icon: buddyIcon,
    body: formattedTime,
    tag: extension,
    requireInteraction: true,
    actions,
    data: {
      id: event.id,
      timeout: timeUntilRemove,
      displayImage: callIcon,
      calendarNotification: true,
      strings: {
        join: _get(state, ['app', 'i18n', 'messages', messages.join.id]),
        dismiss: _get(state, ['app', 'i18n', 'messages', messages.dismiss.id])
      },
      callUrl,
      timeUntilRemove
    }
  };

  const title = eventName;

  store.dispatch(addCalendarNotification({ ...event, extension, startTime: getStartTime(event), eventName }));

  // Let's check if the browser supports notifications
  if (typeof Notification === 'undefined' || !window.notificationServiceRegistration) return;

  if (Notification.permission === 'granted') {
    // If it's okay let's create a notification
    makeNotification(title, notificationOptions, isDesktop);
  } else if (Notification.permission !== 'denied') {
    Notification.requestPermission((permission) => {
      // If the user accepts, let's create a notification
      if (permission === 'granted') {
        makeNotification(title, notificationOptions, isDesktop);
      }
    });
  }
};
