import _get from 'lodash/get';
import { failRemoteGroupUuid, setRemoteGroupUuid } from 'actions/InCall/InCallDrawer';
import { REMOTE_GROUP_UUID_REQUEST } from 'constants/InCall/InCallDrawer';
import Logger from 'utils/logger';
import { getDomain } from 'utils/UrlUtil';

const inCallDrawerMiddleware = (store) => (next) => (action) => {
  let extension, url;
  switch (action.type) {
    case REMOTE_GROUP_UUID_REQUEST:
      extension = _get(action, 'payload');
      url = `https://extensions.${getDomain(true)}/valid/${extension}`;
      fetch(url)
        .then((response) => response.json())
        .then((data) => {
          const groupUuid = _get(data, 'id') || '';
          if (groupUuid) {
            store.dispatch(setRemoteGroupUuid(groupUuid));
          } else {
            store.dispatch(failRemoteGroupUuid('ERROR: group UUID not returned'));
          }
        })
        .catch((error) => {
          store.dispatch(failRemoteGroupUuid('ERROR: group UUID fetch error'));
          Logger.error(`Remote Group UUID fetch error ${error}`);
        });
      break;
    default:
      break;
  }
  return next(action);
};

export default inCallDrawerMiddleware;
