import { FULL_SCREEN_MODAL_ACTION_OPEN, FULL_SCREEN_MODAL_ACTION_CLOSE } from 'constants/FullScreenModal';

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [FULL_SCREEN_MODAL_ACTION_CLOSE]: (state) => {
    const nextState = Object.assign({}, state);
    nextState.visibleComponent = '';
    nextState.modalProps = {};
    return nextState;
  },
  [FULL_SCREEN_MODAL_ACTION_OPEN]: (state, action) => {
    const subComponentType = action.payload.subComponentType;
    if (!subComponentType) return state;
    const nextState = Object.assign({}, state);
    nextState.visibleComponent = subComponentType;
    nextState.modalProps = action.payload.modalProps ? action.payload.modalProps : {};
    return nextState;
  }
};

// ------------------------------------
// Reducer
// ------------------------------------

const initialState = {};

export default function modalReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
