import Bowser from 'bowser';
import _get from 'lodash/get';
import _memoize from 'lodash/memoize';
import { isIpad } from './browser-utils';

/** Cached instance of bowser util for re-use */
const getBrowser = _memoize(() => {
  const userAgent = _get(navigator, 'userAgent');
  return Bowser.getParser(userAgent);
});

const getOsInfo = _memoize(() => {
  const osVersion = getBrowser().getOS();
  const versionParts = osVersion.version ? osVersion.version.split('.') : [];
  return {
    name: osVersion.name ? osVersion.name.toLowerCase() : '',
    versionName: osVersion.versionName || '',
    major: versionParts && versionParts.length ? parseInt(versionParts[0], 10) : 0,
    minor: versionParts && versionParts.length > 1 ? parseInt(versionParts[1], 10) : 0
  };
});

const getOS = (): string => {
  return getOsInfo().name;
};

const getEndpointBrowserString = (): string => {
  const browser = getBrowser().getBrowser();
  const os = getOsInfo();
  const ipad = isIpad() ? ' ipad' : '';
  return `${browser.name}/${browser.version} ${os.name}/${os.major}.${os.minor}${ipad}`;
};

/** Returns lower-case string - ex: 'desktop', 'tablet', 'mobile', etc. */
const getType = (): string => {
  return (getBrowser().getPlatform().type || '').toLowerCase();
};

/** Returns lower-case string - ex: 'chrome', 'firefox', etc. */
const getBrowserName = (): string => {
  return (getBrowser().getBrowserName() || '').toLowerCase();
};

/** Returns lower-case string - ex: 'blink', etc. */
const getEngineName = (): string => {
  // @ts-ignore
  return (getBrowser().getEngineName() || '').toLowerCase();
};

const isMobile = _memoize((): boolean => {
  if (getType() === 'mobile' || getType() === 'tablet') {
    return true;
  }
  if (getOS() === 'macos' && getType() === 'desktop') {
    return navigator.maxTouchPoints > 1;
  }
  return false;
});

const isDesktop = _memoize((): boolean => {
  return !isMobile();
});

const isWindows8 = _memoize((): boolean => {
  const hasWin8UserAgent = navigator.userAgent?.toLowerCase().includes('windows nt 6.3');
  return hasWin8UserAgent;
});

const isMac = (): boolean => isDesktop() && getOS() === 'macos';

const isChrome = _memoize((): boolean => getBrowserName() === 'chrome');

const isFireFox = _memoize((): boolean => getBrowserName() === 'firefox');

const isWebkit = _memoize((): boolean => CSS.supports('( -webkit-text-security:disc )'));

const isChromeLikeBrowser = _memoize((): boolean => {
  const browserName = getBrowserName();
  return isDesktop() && (browserName === 'chrome' || (browserName === 'microsoft edge' && getEngineName() === 'blink'));
});

export {
  isDesktop,
  isWindows8,
  isMobile,
  isChrome,
  isFireFox,
  isChromeLikeBrowser,
  getType,
  isWebkit,
  isMac,
  getOsInfo,
  getEndpointBrowserString
};
