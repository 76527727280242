import { isIosMobile, isWindows81 } from './browser-utils';
import { highQualityHardwareConcurrencyLimit } from '../constants/Application';

interface Resolution {
  height: any;
  width: any;
  value: string;
}

export interface StyleConfig {
  containerAspect?: number | undefined;
  height: any; // height (as a percentage of container's width)
  localPipHeight?: number | undefined;
  width: any; // width (as a percentage of container's width)
}

const defaultVideoAspect = 9 / 16; // HD/UHD
const isLowSpecSystemOrSafari =
  !navigator?.hardwareConcurrency || navigator.hardwareConcurrency <= highQualityHardwareConcurrencyLimit;
const defaultResolutionLabel = isLowSpecSystemOrSafari ? '360p' : '720p';

const getResolutionMap = () => {
  // 'default' is the fallback setting in mediaSettingsSlice, so it should always be part of this map
  const res360p = {
    width: { ideal: 640, max: 640, min: 320 },
    height: { ideal: 360, max: 360, min: 180 }
  };
  const res720p = {
    height: { ideal: 720, max: 720, min: 180 },
    width: { ideal: 1280, max: 1280, min: 320 }
  };
  const resolutionMap: Record<string, Resolution> = {
    default: {
      ...res720p,
      value: 'default'
    },
    // '2160p': { height: 2160, width: 3840, value: '2160p' }, // Ultra-High Definition (4K); 3840x2160; 16:9
    '1080p': {
      width: { min: 320, max: 1920, ideal: 1920 },
      height: { min: 180, max: 1080, ideal: 1080 },
      value: '1080p'
    }, // Full High Definition (1080p); 1920x1080; 16:9
    '720p': {
      ...res720p,
      value: '720p'
    }, // High Definition (720p); 1280x720; 16:9
    '360p': {
      ...res360p,
      value: '360p'
    } // Power Saving (360p); 640x360; 16:9
  };
  if (isLowSpecSystemOrSafari) {
    resolutionMap.default = {
      ...res360p,
      value: 'default'
    };
  }
  if (isWindows81()) {
    delete resolutionMap.default.height.ideal;
    delete resolutionMap.default.width.ideal;
    delete resolutionMap['1080p'].height.ideal;
    delete resolutionMap['1080p'].width.ideal;
    delete resolutionMap['720p'].height.ideal;
    delete resolutionMap['720p'].width.ideal;
    delete resolutionMap['360p'].height.ideal;
    delete resolutionMap['360p'].width.ideal;
  }
  return resolutionMap;
};

const resolutionMap = getResolutionMap();

// Remove all the resolutions over 720p for Safari mobile
if (isIosMobile()) {
  const keys = Object.keys(resolutionMap);
  keys.forEach((key) => {
    if (resolutionMap[key].height > 720) {
      delete resolutionMap[key];
    }
  });
}

export { resolutionMap, defaultResolutionLabel, defaultVideoAspect, isLowSpecSystemOrSafari };
