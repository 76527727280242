import _pick from 'lodash/pick';
import { createSlice } from '@reduxjs/toolkit';
import vbb1 from 'assets/images/vbb-1.jpg';
import vbb2 from 'assets/images/vbb-2.jpg';
import vbb3 from 'assets/images/vbb-3.jpg';
import vbb4 from 'assets/images/vbb-4.jpg';
import vbb5 from 'assets/images/vbb-5.jpg';
import { readFromLocalStorage, writeToLocalStorage } from 'utils/localStorage/vbbImages';
import { LsKey } from 'utils/localStorageUtils';

export interface bgOptType {
  // complete set of keys for rendering options in the ui
  content?: any;
  dataUrl?: string; // existence indicates that the image was uploaded (user-provided, can be deleted)
  id: string; // 'none', 'blur' or some other arbitrarily unique id value
  label?: string;
  path?: string; // existance indicates that the image is default (cannot be deleted)
}

export interface bgSelectionType {
  // only the keys needed for the vbb rendering (subset of bgOptType)
  dataUrl?: string; // existence indicates that the image was uploaded (user-provided, can be deleted)
  id: string; // 'none', 'blur', or some other id
  path?: string; // existance indicates that the image is default (cannot be deleted)
}

export interface VbbSettingsSlice {
  drawerIsOpen: boolean;
  enabled: boolean;
  enabling: boolean;
  error: string;
  selection: bgSelectionType;
}

export const baseBgOptions: bgOptType[] = [
  {
    id: 'none',
    label: 'None'
  },
  {
    id: 'blur',
    label: 'Blur'
  }
];

export const defaultBgOptions: bgOptType[] = [
  {
    id: 'vbb-1',
    label: 'Background 1',
    path: vbb1
  },
  {
    id: 'vbb-2',
    label: 'Background 2',
    path: vbb2
  },
  {
    id: 'vbb-3',
    label: 'Background 3',
    path: vbb3
  },
  {
    id: 'vbb-4',
    label: 'Background 4',
    path: vbb4
  },
  {
    id: 'vbb-5',
    label: 'Background 5',
    path: vbb5
  }
];

// retrieve initial selection (make sure it exists)
const { [LsKey.VbbImages]: localBgOptions, [LsKey.VbbDefault]: bgDefault } = readFromLocalStorage();
const allBgOptions = [...baseBgOptions, ...defaultBgOptions, ...localBgOptions];
const existingDefault = allBgOptions.find((opt) => opt.id === bgDefault);
const initialSelection = _pick(!!existingDefault ? existingDefault : baseBgOptions[0], ['dataUrl', 'id', 'path']);

const initialState: VbbSettingsSlice = {
  drawerIsOpen: false,
  enabled: initialSelection.id !== 'none',
  enabling: false,
  error: '',
  selection: initialSelection
};

const vbbSettingsSlice = createSlice({
  name: 'vbbSettings',
  initialState,
  reducers: {
    clearError(state) {
      state.error = '';
    },
    setBackground(state, action) {
      state.selection = action.payload.bgSelection;
      if (action.payload.saveAsDefault) {
        writeToLocalStorage({ [LsKey.VbbDefault]: action.payload.bgSelection.id });
      }
    },
    setEnabled(state, action) {
      state.enabled = action.payload;
    },
    setEnabling(state, action) {
      state.enabling = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    toggleDrawerOpen(state) {
      state.drawerIsOpen = !state.drawerIsOpen;
    }
  }
});

export const {
  clearError,
  setBackground,
  setEnabled,
  setEnabling,
  setError,
  toggleDrawerOpen
} = vbbSettingsSlice.actions;
export default vbbSettingsSlice.reducer;
