import _get from 'lodash/get';
import lsConfig from './global-config';
import { saveAs } from 'file-saver';
import ICalEvent from 'icalevent';

export const getStartTime = (event) => _get(event, 'start.dateTime') || _get(event, 'start.date');
export const getEndTime = (event) => _get(event, 'end.dateTime') || _get(event, 'end.date');
export const getTitle = (event) => event && (event.summary || event.subject);
export const addDaysToCurrentDate = (days, currentDate = new Date()) => {
  const result = new Date(currentDate);
  result.setDate(result.getDate() + days);
  return result;
};

export const getLocation = (event) =>
  _get(event, 'location.displayName') || (typeof event.location === 'string' && event.location) || '';
export const getDescription = (event) =>
  _get(event, 'body.content') || (typeof event.description === 'string' && event.description) || '';
export const getOrganizer = (event) => _get(event, 'organizer.emailAddress.name') || _get(event, 'organizer.email');

export const startOfDay = (date) => new Date(date).setHours(0, 0, 0, 0);

export const endOfDay = (date) => new Date(date).setHours(23, 59, 59, 999);

export const generateICalInvite = async (invitation, id, today, inviteURL, startDate, endDate, title) => {
  let event = new ICalEvent({
    uid: id,
    dtstamp: today,
    offset: new Date().getTimezoneOffset(),
    summary: invitation.subject,
    description: invitation.body.replace(/\n/g, '\\n'),
    location: inviteURL,
    start: startDate,
    end: endDate
  });

  // Download the iCal event
  if (window.desktopApp) {
    window.desktopApp.launchTempFile(title, event.toFile());
    return;
  }

  const blob = new Blob([event.toFile()], { type: 'text/calendar;charset=utf-8' });

  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(blob, title);
    return;
  }

  const element = document.createElement('a');

  element.setAttribute('href', `data:text/calendar;charset=utf-8,${encodeURIComponent(event.toFile())}`);
  element.setAttribute('download', title);

  element.style.display = 'none';
  document.body.appendChild(element);

  lsConfig.skipOnBeforeUnload();

  try {
    element.click();
  } catch (e) {
    // Some browsers have a max file length for href. If so, we use file-saver as a fallback.
    saveAs(blob, title);
  }

  document.body.removeChild(element);
};

export default {
  getStartTime,
  getEndTime,
  startOfDay,
  endOfDay,
  getTitle,
  getLocation,
  getDescription,
  getOrganizer,
  addDaysToCurrentDate
};
