import Bugsnag from 'bugsnag-js';
import { currentUserSelectors } from '@lifesize/nucleus';

const exceptionReporting = (store) => (next) => (action) => {
  try {
    return next(action);
  } catch (err) {
    const userInfo = currentUserSelectors.myInfo(store.getState());
    const metadata = {
      actionType: action.type
    };
    Bugsnag.user = {
      id: userInfo.id,
      name: userInfo.name,
      email: userInfo.email
    };
    Bugsnag.notifyException(err, 'DispatchedActionError', metadata, 'error');
    throw err;
  }
};

export default exceptionReporting;
