import { CHANGE_LOCALE, FETCH_MESSAGES_SUCCESS } from 'constants/I18n';
import 'isomorphic-fetch';
import _get from 'lodash/get';

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [CHANGE_LOCALE]: (state, action) => {
    return {
      ...state,
      messages: _get(action, 'payload.messages')
    };
  },
  [FETCH_MESSAGES_SUCCESS]: (state, action) => {
    const title = _get(action, 'payload.title');
    document.title = title || 'Lifesize';
    return state;
  }
};

// ------------------------------------
// Reducers
// ------------------------------------

const initialState = {
  messages: {}
};
export default function i18nReducer(state = initialState, action = {}) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
