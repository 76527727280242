import {
  SHOW_ONBOARDING,
  HIDE_ONBOARDING,
  DISABLE_ONBOARDING,
  SHOW_PERMISSION_PAGE,
  HIDE_PERMISSION_PAGE
} from 'constants/Onboarding';

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [SHOW_ONBOARDING]: (state) => {
    const nextState = Object.assign({}, state);
    if (!state.disableOnboarding) {
      nextState.isOnboarding = true;
    }
    return nextState;
  },
  [HIDE_ONBOARDING]: (state) => {
    return {
      ...state,
      disableOnboarding: true,
      isOnboarding: false
    };
  },
  [DISABLE_ONBOARDING]: (state) => {
    return {
      ...state,
      disableOnboarding: true
    };
  },
  [SHOW_PERMISSION_PAGE]: (state) => {
    return {
      ...state,
      showPermissions: true
    };
  },
  [HIDE_PERMISSION_PAGE]: (state) => {
    return {
      ...state,
      showPermissions: false
    };
  }
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  isOnboarding: false,
  disableOnboarding: false,
  showPermissions: true
};

export default function OnboardingReducer(state = initialState, action = {}) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
