import { callHistorySelectors } from '@lifesize/nucleus';
import { createSelector } from 'reselect';
import { defineMessages } from 'react-intl';
import _get from 'lodash/get';
import { Observer } from '@lifesize/web-clients-core';
import { ObservableEvent, signaling } from '@lifesize/clients.sdk';
import {
  clearInCallNotification,
  setInCallNotificationWithTimeout,
  setPillNotificationWithTimeout
} from 'actions/Notification';
import { formatMessage, messagesSelector } from 'observers/utils/intl';
import { unreadConversationCountSelector } from 'selectors/chat';

const initialData = {
  messages: {}
};

const messages = defineMessages({
  allModeratorsMuted: {
    id: 'notification.allModeratorsMuted',
    defaultMessage: 'A moderator has muted all participants. As a moderator, you may unmute yourself.'
  },
  call: { id: 'notification.call.title', defaultMessage: 'Incoming Call From' },
  moderatorMutedByModerator: {
    id: 'notification.moderatorMutedByModerator',
    defaultMessage: 'Your microphone has been muted. As a moderator, you may unmute yourself.'
  },
  muteAll: {
    id: 'notification.muteAll',
    defaultMessage: 'The moderator has muted your microphone. You cannot unmute.'
  },
  recordingStarted: {
    id: 'components.incall.footer.notification.recordingStarted',
    defaultMessage: '{name} has started recording.'
  },
  recordingStopped: {
    id: 'components.incall.footer.notification.recordingStopped',
    defaultMessage: '{name} has stopped recording.'
  },
  recordingStartedSelf: {
    id: 'components.incall.footer.notification.recordingStartedSelf',
    defaultMessage: 'You have started recording.'
  },
  autoRecordingStarted: {
    id: 'components.incall.footer.notification.autoRecordingStarted',
    defaultMessage: 'This meeting is being recorded.'
  },
  autoRecordRetrying: {
    id: 'components.incall.footer.notification.autoRecordRetrying',
    defaultMessage: 'An error has occurred and the recording has stopped. Attempting to restart the recording...'
  },
  autoRecordFailed: {
    id: 'components.incall.footer.notification.autoRecordFailed',
    defaultMessage: 'Unable to restart the recording. End the meeting and rejoin to start recording.'
  },
  recordingStoppedSelf: {
    id: 'components.incall.footer.notification.recordingStoppedSelf',
    defaultMessage: 'You have stopped recording.'
  },
  unlockAll: {
    id: 'notification.unlockAll',
    defaultMessage: 'The moderator has unmuted your microphone.'
  },
  unlockWhileMuted: {
    id: 'notification.unlockWhileMuted',
    defaultMessage: 'The moderator has unlocked your microphone. You are still muted.'
  },
  userJoinedMeeting: {
    id: 'notification.userJoinedMeeting',
    defaultMessage: '{name} joined the meeting.'
  },
  userLeftMeeting: {
    id: 'notification.userLeftMeeting',
    defaultMessage: '{name} left the meeting.'
  }
});

function updatePageTitle(missedNotifications) {
  if (missedNotifications) {
    const count = missedNotifications > 99 ? '99+' : missedNotifications;
    document.title = `(${count}) Lifesize`;
  } else {
    document.title = 'Lifesize';
  }
}

const missedCallsSelector = (state) => callHistorySelectors.totalNewMissedCalls(state);
const notificationMessagesSelector = (state) => messagesSelector(state, messages);

const notificationObserverSelector = createSelector(
  notificationMessagesSelector,
  missedCallsSelector,
  unreadConversationCountSelector,
  (translatedMessages, missedCalls, unreadChats) => {
    return {
      messages: translatedMessages,
      missedNotifications: missedCalls + unreadChats
    };
  }
);

export function recordingHandler(nextState, prevState) {
  // check for null/undefined
  if (!nextState || !prevState) return;
  const { dispatch, getState } = window.lifesize.store;
  const callConnected = signaling.selectors.callConnected();
  if (!callConnected) {
    dispatch(clearInCallNotification());
    return;
  }
  const translatedMessages = notificationMessagesSelector(getState());
  // recording started
  if (!prevState.active && nextState.active) {
    const isAutoRecording = signaling.selectors.isAutoRecording();
    let message;
    if (isAutoRecording) {
      message = formatMessage(translatedMessages.autoRecordingStarted);
    } else {
      const uuid = signaling.selectors.currentUser()?.uuid;
      message =
        nextState?.startedBy?.uuid === uuid
          ? translatedMessages.recordingStartedSelf
          : formatMessage(translatedMessages.recordingStarted, {
              name: nextState?.startedBy?.displayName || 'Moderator'
            });
    }
    dispatch(setPillNotificationWithTimeout(message));
    return;
  }
  if (!prevState.autoRecordFailure && nextState.autoRecordFailure) {
    dispatch(setPillNotificationWithTimeout(formatMessage(translatedMessages.autoRecordFailed)));
    return;
  }
  if (prevState.autoRecordAttempt < nextState.autoRecordAttempt) {
    dispatch(setPillNotificationWithTimeout(formatMessage(translatedMessages.autoRecordRetrying)));
    return;
  }
  // recording stopped
  // Not getting data about who stopped the recording.
}

function currentUserParticipantMuteHandler(nextState = {}, prevState = {}) {
  if (
    (!nextState.serverMuteState && prevState.serverMuteState === undefined) ||
    nextState.serverMuteState === undefined ||
    nextState.serverMuteState === prevState.serverMuteState
  ) {
    return;
  }

  const { dispatch, getState } = window.lifesize.store;
  const state = getState();
  const translatedMessages = notificationMessagesSelector(state);
  const userIsModerator = signaling.selectors.isCurrentUserModerator();
  const isAudioUnmutedByUser = state?.app?.call?.audioUnmuteByUser;

  // handle server mute cases
  if (nextState.serverMuteState) {
    if (userIsModerator) {
      // Moderator-specific mute messages, depending on if the mute was specific to the moderator or the meeting
      const muteAll = signaling.selectors.serverAudioMuteState();
      if (muteAll) {
        dispatch(setPillNotificationWithTimeout(translatedMessages.allModeratorsMuted));
      } else {
        dispatch(setPillNotificationWithTimeout(translatedMessages.moderatorMutedByModerator));
      }
    } else {
      // Generic mute all message for non-moderators
      dispatch(setPillNotificationWithTimeout(translatedMessages.muteAll));
    }
    return;
  }
  // handle server unmute cases
  if (signaling.selectors.isAudioMuted() && prevState.serverMuteState && !isAudioUnmutedByUser) {
    // We were muted, but also got server unmuted. Moderator-agnostic.
    dispatch(setPillNotificationWithTimeout(translatedMessages.unlockWhileMuted));
  } else if (!!prevState.serverMuteState !== nextState.serverMuteState) {
    // Either a non-moderator was server unmuted, or a moderator was unmuted and not by themselves
    if (!userIsModerator || isAudioUnmutedByUser) {
      dispatch(setPillNotificationWithTimeout(translatedMessages.unlockAll));
    }
  }
}

function handleParticipantJoined(payload) {
  const { dispatch, getState } = window.lifesize.store;
  const translatedMessages = notificationMessagesSelector(getState());
  const uuidSelf = _get(signaling.selectors.currentUser(), 'uuid');
  const othersJoining = payload.participants.filter((participant) => participant.uuid !== uuidSelf);
  const name = othersJoining[0]?.name; // if there are multiple joiners, just show the first

  if (name) {
    dispatch(setInCallNotificationWithTimeout(formatMessage(translatedMessages.userJoinedMeeting, { name })));
  }
}

function handleParticipantLeft(payload) {
  const { dispatch, getState } = window.lifesize.store;
  const translatedMessages = notificationMessagesSelector(getState());
  const name = payload.participant.name;

  if (name) {
    dispatch(setInCallNotificationWithTimeout(formatMessage(translatedMessages.userLeftMeeting, { name })));
  }
}

export function enableNotificationObservers() {
  signaling.subscribeToConferenceRecording(recordingHandler);
  signaling.subscribeToCurrentUserParticipant(currentUserParticipantMuteHandler);
  signaling.addObserver(
    { id: 'participant-joined', eventType: ObservableEvent.ParticipantJoined },
    handleParticipantJoined
  );
  signaling.addObserver({ id: 'participant-left', eventType: ObservableEvent.ParticipantLeft }, handleParticipantLeft);
}

const handler = (oldData, newData) => {
  if (newData.missedNotifications !== oldData.missedNotifications) {
    updatePageTitle(newData.missedNotifications);
  }
};

export const handlerMethods = [notificationObserverSelector, initialData, handler];
export default Observer.createObserver(...handlerMethods);
