import { currentUserSelectors, environmentSelectors, servicesSelectors } from '@lifesize/nucleus';
import { startNotifications, refreshCalendars, loadCalendarAuth } from 'actions/Calendar';
import { calendars } from 'constants/Calendar';
import _get from 'lodash/get';
import { createSelector } from 'reselect';
import { Observer } from '@lifesize/web-clients-core';

const initialData = {
  isLoggedIn: false,
  localeLoaded: false,
  isGoogleAuthenticated: false,
  isOutlookAuthenticated: false
};

let minuteHand;
const calendarRefreshTime = 15 * 60 * 1000;

const currentUserSelector = (state) => currentUserSelectors.myInfo(state);
const environmentSelector = (state) => environmentSelectors.platform(state);
const localeMessagesSelector = (state) => _get(state, 'app.i18n.messages');
export const isGoogleAuthenticatedSelector = (state) => _get(state, 'app.calendar.google.calendarSignedIn');
export const isOutlookAuthenticatedSelector = (state) => _get(state, 'app.calendar.outlook.calendarSignedIn');

const notificationObserverSelector = createSelector(
  [
    servicesSelectors.selectConnected,
    currentUserSelector,
    environmentSelector,
    localeMessagesSelector,
    isGoogleAuthenticatedSelector,
    isOutlookAuthenticatedSelector
  ],
  (connected, currentUser, environment, localeMessages, isGoogleAuthenticated, isOutlookAuthenticated) => {
    return {
      isLoggedIn: connected,
      userId: currentUser.id,
      localeLoaded: !!Object.keys(localeMessages).length,
      isDesktop: environment.isDesktop,
      isGoogleAuthenticated,
      isOutlookAuthenticated
    };
  }
);

const handler = (oldData, newData, dispatch) => {
  if (!oldData.isLoggedIn && newData.isLoggedIn) {
    Object.values(calendars).forEach((calendar) => {
      const storedAuth = localStorage.getItem(`calendar:${calendar}`);
      const auth = storedAuth ? JSON.parse(storedAuth) : null;
      if (auth) {
        const userAuth = auth[newData.userId];
        dispatch(loadCalendarAuth(calendar, auth[newData.userId]));
        if (newData.isDesktop && userAuth) {
          window.desktopApp.calendarSetCredentials(calendar, userAuth);
        }
      }
    });
  }

  if (
    !minuteHand &&
    newData.isLoggedIn &&
    !!newData.localeLoaded &&
    (newData.isGoogleAuthenticated || newData.isOutlookAuthenticated)
  ) {
    /* Temporary measure to long poll calendar data. Webhook APIs are available, but would depend
      on allowing the client to subscribe to CalendarService channels post-authentication. See
      CS-1879 for more information.
    */
    minuteHand = setInterval(() => {
      window.lifesize.store.dispatch(refreshCalendars());
    }, calendarRefreshTime);
    dispatch(startNotifications());
  }
};

export const handlerMethods = [notificationObserverSelector, initialData, handler];
export default Observer.createObserver(...handlerMethods);
