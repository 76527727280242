export const SCHEDULE_MODAL = 'SCHEDULE_MODAL';
export const SCHEDULE_SELECT = 'SCHEDULE_SELECT';
export const SCHEDULE_FORM = 'scheduleSearch';
export const SCHEDULE_MESSAGE_FORM = 'scheduleMessage';
export const SCHEDULE_RESET = 'SCHEDULE_RESET';
export const SCHEDULE_INCLUDE_MESSAGE = 'SCHEDULE_INCLUDE_MESSAGE';
export const SCHEDULE_FORM_FOCUSED = 'SCHEDULE_FORM_FOCUSED';
export const SAVED_SCHEDULE_MESSAGE = 'customScheduleMessage';
export const SAVED_SCHEDULE_TOGGLE = 'customScheduleToggle';
export const EXTENSION_NOT_FOUND = 'none';
export const SCHEDULE_ONE_TIME = 'SCHEDULE_ONE_TIME';
export const ONE_TIME_MEETING_FORM = 'oneTimeMeetingForm';
export const GUEST_JOIN = 'GUEST_JOIN';
