import { resolutionMap } from './videoResolution';
import _each from 'lodash/each';
import _get from 'lodash/get';
import _pick from 'lodash/pick';
import _throttle from 'lodash/throttle';
import Logger from 'js-logger';
import { getCameraMaxFrameRate } from './mediaConstraints';

export enum AudioType {
  computer = 'computer',
  phone = 'phone'
}

export type MediaConfig = {
  audio: AudioType;
  camera: string; // deviceId
  localAudioMuted: boolean;
  localVideoMuted: boolean;
  microphone: string; // deviceId
  resolution: string;
  speakers: string; // deviceId
};

export const audioOutputOptions = ['computer', 'phone'];

export const persistentKeys = ['camera', 'microphone', 'resolution', 'speakers', 'localAudioMuted', 'localVideoMuted'];

export const getDevicesByKind = (devices: MediaDeviceInfo[], kind: MediaDeviceKind) => {
  if (devices && devices.length) {
    const filterDevices = devices.filter((device: any) => device.label && device.kind === kind);
    const deviceList = filterDevices.map((device) => {
      return {
        label: device.label,
        value: device.deviceId
      };
    });
    if (deviceList) {
      return deviceList;
    }
  }
  return [];
};

// @ts-ignore
export const getConstraints = (config: MediaConfig = {}): MediaStreamConstraints => {
  const height = resolutionMap[config.resolution]
    ? resolutionMap[config.resolution].height
    : resolutionMap.default.height;
  const width = resolutionMap[config.resolution] ? resolutionMap[config.resolution].width : resolutionMap.default.width;

  // add the audio property as a boolean if we don't have a specific deviceId
  const audio = config.microphone ? { deviceId: config.microphone } : { deviceId: 'default' };
  audio['noiseSuppression'] = { ideal: true };

  const video = {
    height,
    width,
    frameRate: { max: getCameraMaxFrameRate() }
  };

  const constraints: any = {
    audio,
    video
  };

  // only include a "deviceId" property if we have a deviceId to request.
  if (config.camera) {
    constraints.video['deviceId'] = config.camera;
  }

  return constraints;
};

export const getMuteSettings = (mediaSettings: MediaConfig) =>
  _pick(mediaSettings, ['localAudioMuted', 'localVideoMuted']);

export const setAudioOutput = _throttle(
  (deviceId: string) => {
    // get all video and audio elements into an array (vs HTMLCollection)
    const elements: HTMLMediaElement[] = [
      ...document.getElementsByTagName('video'),
      ...document.getElementsByTagName('audio')
    ];

    _each(elements, (mediaElt) => {
      if (!_get(mediaElt, 'setSinkId')) {
        Logger.warn('Browser does not support output device selection.');
        return false; // exit iteration
      }

      if (_get(mediaElt, 'sinkId') !== deviceId) {
        mediaElt
          // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
          // @ts-ignore
          .setSinkId(deviceId)
          .then(() => {
            Logger.log(`Success, audio output device attached: ${deviceId}`);
          })
          .catch((error: any) => {
            let errorMessage = error;
            if (error.name === 'SecurityError') {
              errorMessage = `You need to use HTTPS for selecting audio output device: ${error}`;
            }
            Logger.error(errorMessage);
            // Jump back to first output device in the list as it's the default.
            // audioOutputSelect.selectedIndex = 0;
          });
      }
    });
  },
  500,
  { leading: true, trailing: true }
);

export const isWebGL2Supported = () => {
  // Create canvas element. The canvas is not added to the document itself, so it is never displayed
  var canvas = document.createElement('canvas');
  // Get WebGL2RenderingContext from canvas element.
  var gl = canvas.getContext('webgl2');
  return !!(gl && gl instanceof WebGL2RenderingContext);
};
