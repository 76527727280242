import {
  authActions,
  servicesActions,
  tokenActions,
  nucleusConstants,
  networkActions,
  servicesSelectors
} from '@lifesize/nucleus';
import { AUTH_IGNORE_LOGOUT, CSS_FAILURE, PRESERVE_ROUTE, HAS_ATTEMPTED_LOGIN, CURRENT_PATH } from 'constants/Auth';
import { selectHasInitializedServices } from 'selectors/auth';
import { openFullScreenModal } from 'actions/FullScreenModal';
import { internalModals } from 'constants/FullScreenModal';
import { logger } from 'utils/logger';
import get from 'lodash/get';
import { configureAudioInPresentation } from 'utils/mediaConstraints';

let retries = 0;

const retryTimeout = (Math.floor(Math.random() * (75 - 50 + 1)) + 50) * 100;
const retryLimit = 4;

export const login = (token) => async (dispatch, getState) => {
  const url = new URL(document.location.href);
  let jwt = token;
  if (url && url.searchParams) {
    jwt = url.searchParams.get('A') || url.searchParams.get('a') || jwt;
  }
  try {
    await dispatch(servicesActions.disconnect());
    await dispatch(servicesActions.connect(jwt));
    // TODO: PT: this is a hack, let's find a better place to do this audio in presentation check:
    configureAudioInPresentation(getState());
  } catch (error) {
    console.log(error);
    const fatalJwtError = !get(error, 'willRetry') && get(error, 'reason') === nucleusConstants.WAMP_JWT_ERROR_REASON;
    if (!fatalJwtError && retries < retryLimit) {
      retries++;
      setTimeout(() => {
        dispatch(login(token));
      }, retryTimeout * Math.pow(2, retries));
    } else if (!fatalJwtError && retries >= retryLimit) {
      dispatch(networkActions.offline());
      dispatch(hasAttemptedLogin(true));
    } else {
      throw error;
    }
  }
};

export const reconnectLogin = () => async (dispatch, getState) => {
  const state = getState();
  const willRetry = servicesSelectors.selectWillRetry(state);
  const isVerifying = servicesSelectors.selectVerifyingConnection(state);
  const isConnecting = servicesSelectors.selectConnecting(state);

  // If we've never been able to connect before, don't attempt to reconnect
  if (!selectHasInitializedServices(state)) return;

  // Let CNUC handle it if connection attempt in-progress
  if (willRetry || isVerifying || isConnecting) {
    logger.debug(
      'CNUC already handling a connection attempt, abandoning this reconnect attempt',
      JSON.stringify({ willRetry, isVerifying, isConnecting })
    );
    return;
  }

  try {
    await dispatch(servicesActions.connect());
  } catch (e) {
    logger.debug('Exception occurred while attempting to reconnect:', JSON.stringify(e));
    throw e;
  }
};

export const guestLogin = (name, extension, email) => async (dispatch, getState) => {
  try {
    await dispatch(servicesActions.disconnect());
    await dispatch(tokenActions.getGuestToken(name, extension, email));
    await dispatch(servicesActions.connect());
    configureAudioInPresentation(getState());
  } catch (e) {
    dispatch(openFullScreenModal(internalModals.RELOAD_MODAL));
  }
};

export function ignoreNextLogout() {
  return {
    type: AUTH_IGNORE_LOGOUT
  };
}

export function hasAttemptedLogin() {
  return {
    type: HAS_ATTEMPTED_LOGIN
  };
}

export function logout() {
  return (dispatch) => dispatch(authActions.logout());
}

export function cssFailure() {
  return {
    type: CSS_FAILURE
  };
}

export function preserveRoute(route) {
  return {
    type: PRESERVE_ROUTE,
    payload: route
  };
}

export function getLoginObj(sipId) {
  if (localStorage.getItem('loginStore')) {
    const foundUserLogin = JSON.parse(localStorage.getItem('loginStore')).filter((object) => object.sipId === sipId);
    if (foundUserLogin && foundUserLogin.length) {
      return foundUserLogin[0];
    }
  }
  return { sipId, loginCount: 0 };
}

export function setLoginStore(userLoginObj) {
  if (localStorage.getItem('loginStore')) {
    // LoginStore exists
    const loginArray = JSON.parse(localStorage.getItem('loginStore'));
    let foundUser = false;
    loginArray.forEach((loginObject) => {
      if (loginObject.sipId === userLoginObj.sipId) {
        loginObject.loginCount = userLoginObj.loginCount; // eslint-disable-line no-param-reassign
        foundUser = true;
      }
    });
    if (!foundUser) {
      loginArray.push(userLoginObj);
    }
    localStorage.setItem('loginStore', JSON.stringify(loginArray));
  } else {
    localStorage.setItem('loginStore', JSON.stringify([userLoginObj]));
  }
  return false;
}

export function setCurrentPath(path) {
  return {
    type: CURRENT_PATH,
    payload: path
  };
}

export default {
  logout,
  ignoreNextLogout,
  cssFailure,
  preserveRoute,
  getLoginObj,
  setLoginStore,
  guestLogin,
  setCurrentPath
};
