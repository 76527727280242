export const TURTLE_SIZE = 30;
export const TURTLE_HALF_WIDTH = TURTLE_SIZE / 2;
export const TURTLE_Y_OFFSET = 150;
export const MINIMUM_VIDEO_WIDTH = 0; // for dual streaming
export const DEFAULT_ASPECT = 16 / 9;
export const DEFAULT_CONTAINER = { width: 698, height: 393 };
export const DEFAULT_LEFT_BOUND = MINIMUM_VIDEO_WIDTH - TURTLE_HALF_WIDTH;
export const DEFAULT_SPLIT = 0.75; // the dual streaming dividing point as a % of container.width

export const defaultConfig = {
  defaultAspect: DEFAULT_ASPECT,
  defaultContainer: DEFAULT_CONTAINER,
  defaultLeftBound: DEFAULT_LEFT_BOUND,
  defaultSplit: DEFAULT_SPLIT,
  minVideoWidth: MINIMUM_VIDEO_WIDTH,
  turtleHalfWidth: TURTLE_HALF_WIDTH,
  turtleYOffset: TURTLE_Y_OFFSET
};

export const SHOULD_DISPLAY_PRIMARY_VIDEO = 'SHOULD_DISPLAY_PRIMARY_VIDEO';
export const SHOULD_DISPLAY_SECONDARY_VIDEO = 'SHOULD_DISPLAY_SECONDARY_VIDEO';
export const TOGGLE_MINIPLAYER_VIDEO = 'TOGGLE_MINIPLAYER_VIDEO';
export const TOGGLE_DIAL_PAD = 'TOGGLE_DIAL_PAD';
export const TOGGLE_CAMERA_CONTROLS = 'TOGGLE_CAMERA_CONTROLS';
export const VIDEO_LAYOUT_CHANGE = 'VIDEO_LAYOUT_CHANGE';
export const TOGGLE_POPOUT = 'TOGGLE_POPOUT';
export const VIDEO_POLLING_STOPPED = 'VIDEO_POLLING_STOPPED';
export const TURTLE_DRAG_START = 'TURTLE_DRAG_START';
export const TURTLE_DRAG_STOP = 'TURTLE_DRAG_STOP';
export const WAITING_TO_REPOP = 'WAITING_TO_REPOP';
export const popoutMinimumWidth = 620;
export const titleBarHeight = 30;
export const miniplayerMode = {
  local: 'local',
  remote: 'remote'
};
export const VIDEO_PRIMARY = 'videoPrimary';
export const VIDEO_SECONDARY = 'videoSecondary';
export const POPOUT_CONTAINER = 'popoutContainer';
export const CALL_LAYOUT = 'callLayout';

export const VIDEO_FOOTER_PIP = 'videoFooterPip';
export const VIDEO_PRESENTATION_PIP = 'videoPresentationPip';
export const PREVIEW_VIDEO_ID = 'previewVideo';
export const TOGGLE_MINIMIZED = 'TOGGLE_MINIMIZED';

export const WHITE_LIST_LANGUAGE = ['en', 'fr', 'de', 'it', 'es', 'pt', 'ja'];
