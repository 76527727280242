import { isProduction } from 'utils/UrlUtil';
import Bugsnag from 'bugsnag-js';

function initialize() {
  Bugsnag.apiKey = '6a98cee730057ea9739e269f1eb1720b';
  Bugsnag.releaseStage = isProduction() ? 'production' : 'staging';
  Bugsnag.autoNotify = process.env.REACT_APP_STAGE !== 'local';
  Bugsnag.appVersion = process.env.REACT_APP_VERSION;

  if (process.env.REACT_APP_DESKTOP === 'true') {
    Bugsnag.context = '/desktop/webapp';
  }
}

export default { initialize };
