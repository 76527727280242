import * as autobahn from 'autobahn-browser';
import { configureSignalingReceiveStreams, getRtcPeerConnectionOptionalConstraints } from 'utils/mediaConstraints';
import { createReduxStore, createRtcMiddleware } from '@lifesize/nucleus';
import rootReducer from './reducers';
import routingMiddleware from '../middleware/routing';
import raiseHandsMiddleware from '../middleware/raiseHands';
import inCallDrawerMiddleware from '../middleware/inCallDrawer';
import collaborationToolsMiddleware from '../middleware/collaborationTools';
import conferenceAudioMiddleware from '../middleware/conferenceAudio';
import calendarMiddleware from '../middleware/calendar';
import contactMiddleware from '../middleware/contact';
import exceptionReportingMiddleware from '../middleware/exceptionReporting';
import desktopMiddleware from '../middleware/desktop';
import loggerMiddleware from '../middleware/logger';
import authMiddleware from '../middleware/auth';
import searchStringMiddleware from '../middleware/searchString';
import i18nMiddleware from '../middleware/i18n';
import presentationMiddleware from '../middleware/presentation';
import videoMiddleware from '../middleware/video';
import userSettingsMiddleware from '../middleware/userSettings';
import connectivityMiddleware from '../middleware/connectivity';

export default (initialState = {}) => {
  // ======================================================
  // Middleware Configuration
  // ======================================================
  const middleware = [
    routingMiddleware,
    videoMiddleware,
    calendarMiddleware,
    contactMiddleware,
    collaborationToolsMiddleware,
    loggerMiddleware,
    raiseHandsMiddleware,
    inCallDrawerMiddleware,
    searchStringMiddleware,
    i18nMiddleware,
    presentationMiddleware,
    userSettingsMiddleware,
    authMiddleware,
    connectivityMiddleware,
    conferenceAudioMiddleware
  ];
  if (process.env.REACT_APP_STAGE !== 'local') {
    middleware.unshift(exceptionReportingMiddleware);
  }
  if (window.desktopApp) {
    middleware.push(desktopMiddleware);
  }

  // ======================================================
  // Store Enhancers
  // ======================================================
  // const enhancers = [];

  // ======================================================
  // Store Instantiation and HMR Setup
  // ======================================================

  const config = {
    calls: {
      autoResolutionChange: true,
      rtcPeerConnectionOptionalConstraints: getRtcPeerConnectionOptionalConstraints(),
      // Enable Differentiated Services Field Code Point (DSCP)
      rtcPeerConnectionEnableChromiumDscp: true,
      rtcRtpSenderEncodingParams: {
        // A string from the RTCPriorityType enumerated type which indicates the priority
        networkPriority: 'high'
      }
    }
  };

  const store = createReduxStore(rootReducer, middleware, initialState, null, autobahn, createRtcMiddleware(), config);
  configureSignalingReceiveStreams();

  return store;
};
