import { TOGGLE_MINI_PLAYER } from 'constants/MiniPlayer';

const initialState = {
  isMiniPlayerOpen: false
};

const ACTION_HANDLERS = {
  [TOGGLE_MINI_PLAYER]: (state) => {
    const nextState = Object.assign({}, state);
    nextState.isMiniPlayerOpen = !state.isMiniPlayerOpen;
    return nextState;
  }
};

export default function MiniPlayerReducer(state = initialState, action = {}) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
