import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { MemoryRouter, BrowserRouter } from 'react-router-dom';
import 'webrtc-adapter';
import * as Nucleus from '@lifesize/nucleus';
import { redirectToLauncherPage, isProduction } from 'utils/UrlUtil';
import { Analytics, Observer, AnalyticsEnvironment } from '@lifesize/web-clients-core';
import ExceptionReporting from 'utils/ExceptionReporting';
import initializeNucleus from 'utils/init-utils';
import Logger from 'utils/logger';
import Api from './api/Api';
import createStore from './store/createStore';
import calendarObserver from './observers/calendar';
import notificationObserver, { enableNotificationObservers } from './observers/notification';
import permissionsObserver from './observers/permissions';
import desktopObserver, { enableDesktopObservers } from './observers/desktop';
import enableCallObservers from './observers/call';
import { initialize as initializeAzureApi } from './api/azure-api';
import { isWebAppSupported } from 'utils/browser-utils';
import { basePath } from 'constants/Application';
import { connectionEventManager } from 'utils/connectionEventManager';
import { selectors, signaling } from '@lifesize/clients.sdk';
import { getCurrentLanguageId, loadGuestLocales } from 'utils/guest-localeUtil';
import { getExtension, getFeatureFlags, getKioskId, isGuestRoute, isKioskRoute } from 'utils/guest-utils';
import { FLAG_GUEST_URL } from './constants/FeatureFlags';
import { setGuestFlowEnabled, setExtensionKiosk, setExtension } from 'reducers/guestFlowSlice';
import LoadingAnimation from './components/LoadingAnimation/LoadingAnimation';

const AppContainer = React.lazy(() => import('./containers/AppContainer'));

const observables = [permissionsObserver, notificationObserver, calendarObserver];

// set mobile viewport height to account for url bar
// https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
const setVh = () => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
};

window.addEventListener('resize', setVh);
setVh();

if (!window.lifesize) window.lifesize = {};
if (!window.lifesize.apiDomain) window.lifesize.apiDomain = process.env.REACT_APP_API_DOMAIN;
Logger.useDefaults();
Logger.info(`Lifesize Web App v${process.env.REACT_APP_VERSION}`);

const isDesktop = process.env.REACT_APP_DESKTOP === 'true';

// ========================================================
// Determine browser support
// ========================================================
if (!isDesktop && !isWebAppSupported() && !isGuestRoute()) {
  redirectToLauncherPage();
}

// ========================================================
// Developer Tools Setup
// ========================================================
if (process.env.REACT_APP_DEBUG) {
  if (window.devToolsExtension) {
    window.devToolsExtension.open();
  }
} else if (!process.env.REACT_APP_DEBUG) {
  ExceptionReporting.initialize();
}

if (!window.lifesize) window.lifesize = {};
if (!window.lifesize.apiDomain) window.lifesize.apiDomain = process.env.REACT_APP_API_DOMAIN;

const initAnalytics = () => {
  // Don't trigger analytics if iFrame is detected
  if (window.self === window.top) {
    let environment;
    if (process.env.REACT_APP_DEBUG) {
      environment = AnalyticsEnvironment.integration;
    } else if (isProduction()) {
      environment = AnalyticsEnvironment.production;
    } else {
      environment = AnalyticsEnvironment.stage;
    }
    Analytics.init('AP-NOLE8QZH1MTP-2', environment);

    const type = isDesktop ? 'desktop' : 'web';

    Analytics.setContext({
      type,
      version: process.env.REACT_APP_VERSION
    });
  }
};

const initObservables = (store) => {
  enableCallObservers(store);
  enableNotificationObservers();
  Observer.createObservable(store, observables);
};

const initDesktopAPI = (store, signalingStore) => {
  if (isDesktop) {
    Api.initialize(store, signalingStore);
    if (window.desktopApp) {
      enableDesktopObservers();
      observables.push(desktopObserver);
    }
  }
};

const initNetworkEventHandlers = (store) => {
  const onlineNetworkDispatch = () => {
    store.dispatch(Nucleus.networkActions.online());
  };

  const offlineNetworkDispatch = () => {
    store.dispatch(Nucleus.networkActions.offline());
  };

  const offlineInCallTimeout = async () => {
    // If a call is offline for too long, end it since call manager will have ended it
    if (selectors.callConnected()) {
      // Disconnect it
      await signaling.endCall();
    }
  };

  const offlineWhileDialing = async () => {
    // Stop dialing if we lose network
    const callState = selectors.callState();
    // TODO: use constants
    if (callState && callState !== 'disconnected' && callState !== 'connected') {
      // If dialing, end the call
      try {
        await signaling.endCall();
      } catch (e) {
        Logger.error('Error while attempting to end call in offline timeout', e);
      }
    }
  };

  // We want these to fire immediately, so timeout of 0.
  connectionEventManager.registerOfflineEvent('cnucNetworkEvents', offlineNetworkDispatch, 0, onlineNetworkDispatch);
  connectionEventManager.registerOfflineEvent('offlineWhileDialing', offlineWhileDialing, 0);

  // This should be delayed
  // TODO: move timeout to constant
  connectionEventManager.registerOfflineEvent('offlineInCallTimeout', offlineInCallTimeout, 120000);
};

const getStore = async () => {
  const store = await createStore({});
  return store;
};

const render = async (store) => {
  if (window?.frameElement?.id?.includes('microsoft.com')) {
    return;
  }
  /*
    webmodule bucket is not able to handle two level routes. To get around it,
    webmodule is loaded with MemoryRouter,
    so that it will load root route when webmodule is reloaded.
  */

  /**
   * Here is the workaround for Google Translate bug https://bugs.chromium.org/p/chromium/issues/detail?id=872770
   * The hack can be remved ones issue is solved.
   */
  if (typeof Node === 'function' && Node.prototype) {
    const originalRemoveChild = Node.prototype.removeChild;
    Node.prototype.removeChild = function (child) {
      if (child.parentNode !== this) {
        if (console) {
          console.error('Cannot remove a child from a different parent', child, this);
        }
        return child;
      }
      return originalRemoveChild.apply(this, arguments);
    };

    const originalInsertBefore = Node.prototype.insertBefore;
    Node.prototype.insertBefore = function (newNode, referenceNode) {
      if (referenceNode && referenceNode.parentNode !== this) {
        if (console) {
          console.error('Cannot insert before a reference node from a different parent', referenceNode, this);
        }
        return newNode;
      }
      return originalInsertBefore.apply(this, arguments);
    };
  }

  const Router = isDesktop ? MemoryRouter : BrowserRouter;

  // MemoryRouter will work only if it is the root element in the DOM.
  ReactDOM.render(
    <Router basename={basePath}>
      <Suspense fallback={<LoadingAnimation />}>
        <AppContainer store={store} />
      </Suspense>
    </Router>,
    document.getElementById('root')
  );
};

initAnalytics();

getStore().then(async (returnedStore) => {
  const store = returnedStore;
  if (!window.lifesize) window.lifesize = {};
  window.lifesize.store = store;
  window.lifesize.nucleus = Nucleus;
  try {
    initDesktopAPI(store, window.signalingStore);
    initObservables(store);
    initializeAzureApi();
    await initializeNucleus(store);
    initNetworkEventHandlers(store);
    if (isKioskRoute()) {
      const data = await getFeatureFlags(FLAG_GUEST_URL);
      store.dispatch(setGuestFlowEnabled(data?.featureFlags || false));
      store.dispatch(setExtensionKiosk({ isKiosk: true, kioskId: getKioskId() }));
      store.dispatch(setExtension(getExtension()));
      await loadGuestLocales(getCurrentLanguageId());
    } else if (isGuestRoute()) {
      const data = await getFeatureFlags(FLAG_GUEST_URL);
      store.dispatch(setGuestFlowEnabled(data?.featureFlags || false));
      await loadGuestLocales(getCurrentLanguageId());
    }
  } catch (e) {
    Logger.error(`Initialization error ${e}`);
  }
  render(store);
});
