import _get from 'lodash/get';
import { nucleusConstants } from '@lifesize/nucleus';
import { Analytics } from '@lifesize/web-clients-core';
import {
  AUTH_IGNORE_LOGOUT,
  PRESERVE_ROUTE,
  HAS_ATTEMPTED_LOGIN,
  CURRENT_PATH,
  RECHECKIN_AFTER_CALL
} from 'constants/Auth';
import { TrackEvents } from 'utils/analytics';

const ACTION_HANDLERS = {
  [AUTH_IGNORE_LOGOUT]: (state) => Object.assign({}, state, { ignoreLogout: true }),
  [nucleusConstants.AUTH_LOGOUT]: (state) => {
    const nextState = Object.assign({}, state);
    if (!state.ignoreLogout) {
      nextState.guestName = null;
    } else {
      nextState.ignoreLogout = false;
    }
    return nextState;
  },

  [RECHECKIN_AFTER_CALL]: (state, action) => {
    return { ...state, reCheckin: action?.payload };
  },

  [PRESERVE_ROUTE]: (state, action) => {
    const nextState = Object.assign({}, state);
    if (action.payload.indexOf('/auth') !== 0) {
      nextState.lastRoute = action.payload;
    } else {
      nextState.lastRoute = '/';
    }
    return nextState;
  },

  [nucleusConstants.WAMP_CONNECT_ERROR]: (state, action) => {
    const authFailure = _get(action.payload, 'error.details.reason') === nucleusConstants.WAMP_JWT_ERROR_REASON;
    return {
      ...state,
      authFailure
    };
  },

  [nucleusConstants.WAMP_CONNECT_SUCCESS]: (state) => {
    return {
      ...state,
      hasAttemptedLogin: true
    };
  },

  [nucleusConstants.INITIALIZE_SERVICES_SUCCESS]: (state) => {
    return {
      ...state,
      hasInitializedServices: true
    };
  },

  [nucleusConstants.LOGOUT_BEGIN]: (state) => {
    return {
      ...state,
      hasAttemptedLogin: false,
      hasInitializedServices: false
    };
  },

  [nucleusConstants.WAMP_CONNECTION_DISPOSED_JWT_ERROR]: (state) => {
    return {
      ...state,
      authFailure: true
    };
  },

  [HAS_ATTEMPTED_LOGIN]: (state) => {
    return {
      ...state,
      hasAttemptedLogin: true
    };
  },
  [CURRENT_PATH]: (state, action) => {
    Analytics.track(TrackEvents.Path, { path: action.payload, previousPath: state.currentPath });
    return {
      ...state,
      currentPath: action.payload,
      previousPath: state.currentPath
    };
  }
};

// ------------------------------------
// Reducer
// ------------------------------------
export const initialState = {
  loginStore: localStorage.getItem('loginStore') ? localStorage.getItem('loginStore') : [],
  loginCount: 0,
  guestName: null,
  cssFailure: false,
  currentPath: null,
  authFailure: false,
  hasLoggedIn: false,
  hasInitializedServices: false,
  hasAttemptedLogin: false,
  lastRoute: '/'
};

export default function authReducer(state = initialState, action = {}) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
