const globalConfig = (() => {
  // if it already exists, use it
  if (window.lsConfig) {
    return window.lsConfig;
  }
  // otherwise create it
  const settings = {
    ignoreOnBeforeUnload: false,
    ignoreOnBeforeUnloadTimeout: 0
  };

  function resumeOnBeforeUnload() {
    if (!this.settings.ignoreOnBeforeUnloadTimeout) {
      this.settings.ignoreOnBeforeUnload = false;
    }
  }

  function skipOnBeforeUnload() {
    this.settings.ignoreOnBeforeUnload = true;
  }

  function skipTimeOnBeforeUnload(period = 3000) {
    this.settings.ignoreOnBeforeUnload = true;
    this.settings.ignoreOnBeforeUnloadTimeout = setTimeout(() => {
      this.ignoreOnBeforeUnloadTimeout = 0;
      this.settings.ignoreOnBeforeUnload = false;
    }, period);
  }

  const config = {
    settings,
    resumeOnBeforeUnload,
    skipOnBeforeUnload,
    skipTimeOnBeforeUnload
  };

  window.lsConfig = config;
  return window.lsConfig;
})();

export default globalConfig;
