import inCallVideoActions from 'actions/InCall/InCallVideo';
import { VIDEO_PRIMARY } from 'constants/InCall/InCallVideo';
import { nucleusConstants } from '@lifesize/nucleus';

const watchedActions = [];

const videoMiddleware = (store) => (next) => (action) => {
  const result = next(action);
  if (!watchedActions.includes(action.type)) return result;
  switch (action.type) {
    case nucleusConstants.RTC_REMOTE_STREAM_ADDED:
      store.dispatch(inCallVideoActions.startPolling(VIDEO_PRIMARY));
      break;
    case nucleusConstants.RTC_REMOTE_STREAM_REMOVED:
      store.dispatch(inCallVideoActions.stopPolling(VIDEO_PRIMARY));
      break;
    default:
      break;
  }
  return result;
};

export default videoMiddleware;
