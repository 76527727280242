import { createSlice } from '@reduxjs/toolkit';
import { AudioType, MediaConfig } from '../utils/deviceUtils';
import { readFromLocalStorage } from '../utils/localStorage/mediaSettings';
import { resolutionMap } from '../utils/videoResolution';

const localStorageValue = readFromLocalStorage();

const initialState: MediaConfig = {
  camera: '',
  localAudioMuted: false,
  localVideoMuted: false,
  microphone: '',
  speakers: '',
  ...localStorageValue,
  audio: AudioType.computer, // always revert to 'computer' on init (some customers do not offer pstn)
  // guarantee a good value even if our resolutionMap changes
  resolution: (!!resolutionMap[localStorageValue.resolution] && localStorageValue.resolution) || 'default'
};

const mediaSettingsSlice = createSlice({
  name: 'mediaSettings',
  initialState,
  reducers: {
    set(state, action) {
      return {
        ...state,
        ...action.payload
      };
    }
  }
});

export const { set } = mediaSettingsSlice.actions;
export default mediaSettingsSlice.reducer;
