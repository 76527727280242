export const calendars = {
  outlook: 'outlook',
  google: 'google'
};

export const allInviteUrls = [
  'https://call.lifesizecloud.com/',
  'https://call.lifesize.com/',
  'https://call.lifesizecloudbeta.com/',
  'https://call.lifesizebeta.com/',
  'https://call.lifesizebeta.com/extension/',
  'https://call.lifesize.com/extension/',
  'https://call.lifesizecloud.com/extension/',
  'https://call.lifesizecloudbeta.com/extension/',
  'https://guest.lifesize.com/',
  'https://guest.lifesizecloud.com/',
  'https://guest.lifesizebeta.com/',
  'https://guest.lifesizecloudbeta.com/'
];

export const googleDesktopClientId = '1000154309319-4lck2rr3g9ijmeqiqa65dl82270i37u1.apps.googleusercontent.com';
export const googleWebClientId = '1000154309319-0nk0gej442i4jbjmlao6f3j6n3cjinv4.apps.googleusercontent.com';

// Google integration constants

// Authorization scopes required by the Google API; multiple scopes can be
// included, separated by spaces.
export const CALENDAR_READONLY_SCOPE = 'https://www.googleapis.com/auth/calendar.readonly';
export const CALENDAR_EVENTS_SCOPE = 'https://www.googleapis.com/auth/calendar.events';

export const timeFormatOptions = {
  hour: 'numeric',
  minute: '2-digit'
};

export const dateFormatOptions = {
  day: 'numeric',
  month: 'long'
};

export const CALENDAR_DAYS = 7;
export const ONE_DAY = 1000 * 60 * 60 * 24;
export const FIFTEEN_MINUTES = 1000 * 60 * 15;

export const CALENDAR_API_ERROR = 'CALENDAR_API_ERROR';
export const CALENDAR_API_SIGNED_IN = 'CALENDAR_API_SIGNED_IN';
export const CALENDAR_API_SIGNED_OUT = 'CALENDAR_API_SIGNED_OUT';
export const CALENDAR_LOAD_AUTH = 'CALENDAR_LOAD_AUTH';
export const CALENDAR_EXTERNAL_AUTH = 'CALENDAR_EXTERNAL_AUTH';
export const CALENDAR_LIST_EVENTS = 'CALENDAR_LIST_EVENTS';
export const CALENDAR_SIGN_IN_START = 'CALENDAR_SIGN_IN_START';
export const CALENDAR_USER = 'CALENDAR_USER';

export const CLEAR_CALENDAR_NOTIFICATION = 'CLEAR_CALENDAR_NOTIFICATION';
export const DELETE_CALENDAR_NOTIFICATION = 'DELETE_CALENDAR_NOTIFICATION';
export const REFRESH_CALENDARS = 'REFRESH_CALENDARS';
export const REMOVE_UPCOMING_NOTIFICATION = 'REMOVE_UPCOMING_NOTIFICATION';
export const SET_CALENDAR_NOTIFICATION = 'SET_CALENDAR_NOTIFICATION';
export const SET_UPCOMING_NOTIFICATION = 'SET_UPCOMING_NOTIFICATION';
export const START_CALENDAR_NOTIFICATIONS = 'START_CALENDAR_NOTIFICATIONS';
export const RETRIEVING_CALENDAR_INFO = 'RETRIEVING_CALENDAR_INFO';

export const CALENDAR_SELECT_EVENT = 'CALENDAR_SELECT_EVENT';
export const INITIALIZE_OUTLOOK_OAUTH = 'INITIALIZE_OUTLOOK_OAUTH';

export const OUTLOOK_LOGIN_REQUIRED_ERROR = 'login_required';
export const OUTLOOK_INVALID_GRANT_ERROR = 'invalid_grant';
// The below error message is for when all connection to ms has been lost or there was never a connection established.
export const OUTLOOK_USER_LOGIN_ERROR = 'user_login_error';
export const OUTLOOK_INTERACTION_REQUIRED = 'interaction_required';
