import _get from 'lodash/get';
import { WEBAPP_CLOSE_WHITEBOARD_POPOUT, WEBAPP_OPEN_WHITEBOARD_POPOUT } from 'constants/PluginConstants';

if (!window.lifesize) window.lifesize = {};
window.lifesize.whiteboardWindow = null;

const collaborationToolsMiddleware = (store) => (next) => (action) => {
  let features, url;
  switch (action.type) {
    case WEBAPP_OPEN_WHITEBOARD_POPOUT:
      url = _get(action, 'payload.url');
      features = 'menubar=0,location=false,toolbar=0,personalbar=0,titlebar=0,status=0';
      if (!window.lifesize.whiteboardWindow || window.lifesize.whiteboardWindow.closed) {
        window.lifesize.whiteboardWindow = window.open(url, '_blank', features);
      }
      window.lifesize.whiteboardWindow.focus();
      break;
    case WEBAPP_CLOSE_WHITEBOARD_POPOUT:
      if (window.lifesize.whiteboardWindow && !window.lifesize.whiteboardWindow.closed) {
        window.lifesize.whiteboardWindow.close();
      }
      break;
    default:
      break;
  }
  return next(action);
};

export default collaborationToolsMiddleware;
