import { nucleusConstants } from '@lifesize/nucleus';
import { hasUnreadChats, unreadConversationCountSelector } from 'selectors/chat';
import { setOverlayIcon } from 'actions/Chat';
import { openInCallModal, electronSendMinimize } from 'actions/InCallModal';
import { DESKTOP_REQUEST_MINIMIZE, IN_CALL_MODAL_ACTION_CLOSE } from 'constants/InCallModal';
import callActions from 'actions/InCall/InCallVideo';
import { VIDEO_SECONDARY } from 'constants/InCall/InCallVideo';
import Modals from 'constants/Modals';
import _get from 'lodash/get';
import * as objectUtils from 'utils/object-utils';
import { isWindows } from 'utils/browser-utils';
import { selectors } from '@lifesize/clients.sdk';

const unreadChatIcons = {};
let minimizeRequested = false;

export function generateOverlayIcon(iconText) {
  const canvas = document.createElement('canvas');
  canvas.height = 16;
  canvas.width = 16;
  const context = canvas.getContext('2d');
  context.beginPath();
  context.fillStyle = 'red';
  context.strokeStyle = 'black';
  context.font = '12px';
  context.textAlign = 'center';
  context.lineWidth = 2;
  context.arc(8, 8, 8, 0, 2 * Math.PI, false);
  context.fill();
  context.beginPath();
  context.fillStyle = 'white';
  context.fillText(iconText, 7, 11);
  context.fill();

  return canvas.toDataURL('image/png', 0.9);
}

const desktopMiddleware = (store) => (next) => (action) => {
  const result = next(action);
  const state = store.getState();
  if (hasUnreadChats(state)) {
    const unreadChatCount = unreadConversationCountSelector(state);

    if (!unreadChatIcons[unreadChatCount]) {
      let data = null;
      if (unreadChatCount && isWindows()) {
        data = generateOverlayIcon(unreadChatCount.toString());
      }

      unreadChatIcons[unreadChatCount] = data;
    }

    store.dispatch(setOverlayIcon(unreadChatIcons[unreadChatCount], unreadChatCount));
  }

  if (result.type === DESKTOP_REQUEST_MINIMIZE) {
    const callConnected = selectors.callConnected();
    if (callConnected) {
      minimizeRequested = true;
      store.dispatch(openInCallModal(Modals.endCallForAll));
    } else {
      store.dispatch(electronSendMinimize());
    }
  }

  if (result.type === IN_CALL_MODAL_ACTION_CLOSE) {
    if (_get(result, 'payload.endCalled') && minimizeRequested) {
      store.dispatch(electronSendMinimize());
    }
    minimizeRequested = false;
  }

  if (result.type === nucleusConstants.VIDEO_PLAYBACK_STARTED) {
    if (_get(state, 'app.inCallVideo.waitingToRepop') && _get(result, 'payload.id') === VIDEO_SECONDARY) {
      store.dispatch(callActions.waitingToRepop(false));
      store.dispatch(callActions.togglePopout(true));
    }
    // This action includes a DOM element which the IPC can't handle
    return result;
  }

  window.desktopApp.onActionDispatched(objectUtils.decycle(action), store);

  return result;
};

export default desktopMiddleware;
