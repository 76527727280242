import { featureSelectors, nucleusConstants } from '@lifesize/nucleus';
import { media, selectors } from '@lifesize/clients.sdk';
import { SHOW_PRESENTATION_SELECTION } from 'constants/PresentationConstants';
import { popoutPresentationFeatureSelector } from 'selectors/call';
import inCallVideoActions from 'actions/InCall/InCallVideo';
import { TOGGLE_POPOUT } from 'constants/InCall/InCallVideo';
import { getChromeDisplayConstraints } from 'utils/mediaConstraints';
import { logger } from 'utils/logger';
import { isCatalina } from 'utils/browser-utils';
import _get from 'lodash/get';
import { displaySharingSettingsModal } from '../actions/SharingPermissions';
import { FLAG_AUDIO_PRESENTATION } from 'constants/FeatureFlags';
export const extensionEventName = 'requestScreenShareWithSources';
export const extensionElementId = 'ls-extension-installed';
export const extensionUrl = 'https://chrome.google.com/webstore/detail/pkdaplneajgbalnnmaoflooakgijjkhc';

async function showPresentationSelection(store, dispatch) {
  const enableAudioPresentation =
    selectors.isEscalated() && featureSelectors.getFeatureFlag(store.getState())(FLAG_AUDIO_PRESENTATION);

  if (window.desktopApp) {
    return window.desktopApp.showScreenShareSelection(enableAudioPresentation);
  }

  const getDisplayMedia = _get(navigator, 'mediaDevices.getDisplayMedia');

  if (getDisplayMedia) {
    const constraints = getChromeDisplayConstraints();
    // PT: Hack: We also must be escalated for now, to use audio in presentation.
    // This hack can be removed when the remaining clients update to the SDK (instead of CNUC7)

    if (enableAudioPresentation) constraints.audio = { echoCancellation: true };
    else constraints.audio = false;

    try {
      await media.acquirePresentation(constraints);
    } catch (e) {
      logger.error(`Unable to start presentation with getDisplayMedia: ${JSON.stringify(e)}`);
      if (e instanceof DOMException && e.name === 'NotAllowedError' && e.message === 'Permission denied by system') {
        // Permission to access a screen area was denied by the user,
        // or the current browsing instance is not permitted access to screen sharing.;
        if (isCatalina()) {
          // Display instructions for OSX Catalina
          dispatch(displaySharingSettingsModal());
        }
      }
      return logger.error(`Unable to start presentation with getDisplayMedia: ${e}`);
    }
  }

  return null;
}

const presentationMiddleware = (store) => (next) => (action) => {
  const result = next(action);
  const state = store.getState();

  switch (action.type) {
    case SHOW_PRESENTATION_SELECTION:
      showPresentationSelection(store, next);
      break;
    case TOGGLE_POPOUT:
      if (!popoutPresentationFeatureSelector(state)) break;
      // const isPopped = !!action.payload;
      // @ts-ignore
      // if the popout window is closed and there is still a remote presentation stream, it must be reattached
      // PT: TODO
      break;
    case nucleusConstants.GALAXY_ACTION_RX_STOPPED_PRESENTATION:
    case nucleusConstants.RTC_REMOTE_STREAM_REMOVED:
      if (popoutPresentationFeatureSelector(state)) {
        store.dispatch(inCallVideoActions.togglePopout(false));
      }
      break;
    default:
      break;
  }
  return result;
};

export default presentationMiddleware;
