import { environmentSelectors } from '@lifesize/nucleus';
import _get from 'lodash/get';
import { createSelector } from 'reselect';
import { Observer } from '@lifesize/web-clients-core';
import { delayShowPermission, hidePermission } from 'actions/Onboarding';

const initialData = {
  showPermissionPage: false
};

const isDesktopSelector = (state) => process.env.REACT_APP_DESKTOP || environmentSelectors.platform(state).isDesktop;
const isAquiringPrimaryStreamSelector = (state) => _get(state, 'nucleus.media.isAcquiringPrimaryStream');

const permissionsObserverSelector = createSelector(
  [isDesktopSelector, isAquiringPrimaryStreamSelector],
  (isDesktop, isAquiringPrimaryStream) => {
    const showPermissionPage = !isDesktop && isAquiringPrimaryStream;

    return {
      showPermissionPage
    };
  }
);

const handler = (oldData, newData, dispatch) => {
  if (!oldData.showPermissionPage && newData.showPermissionPage) {
    dispatch(delayShowPermission());
  } else if (!newData.showPermissionPage) {
    dispatch(hidePermission());
  }
};

export const handlerMethods = [permissionsObserverSelector, initialData, handler];
export default Observer.createObserver(...handlerMethods);
