import {
  DROPDOWN_NEXT_RESULT,
  DROPDOWN_PREVIOUS_RESULT,
  DROPDOWN_SET_CONTACTS,
  DROPDOWN_CLEAR_CONTACTS
} from 'constants/Dropdown';

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [DROPDOWN_NEXT_RESULT]: (state) => {
    const nextState = Object.assign({}, state);
    if (nextState.selectionIndex < nextState.dropdownLength - 1) {
      nextState.selectionIndex += 1;
    }

    return nextState;
  },
  [DROPDOWN_PREVIOUS_RESULT]: (state) => {
    const nextState = Object.assign({}, state);

    if (nextState.selectionIndex > 0) {
      nextState.selectionIndex -= 1;
    }

    return nextState;
  },
  [DROPDOWN_CLEAR_CONTACTS]: (state) => {
    const nextState = Object.assign({}, state);
    nextState.selectionIndex = -1;
    return nextState;
  },
  [DROPDOWN_SET_CONTACTS]: (state, action) => {
    const nextState = Object.assign({}, state);
    nextState.dropdownLength = action.payload;
    nextState.selectionIndex = -1;
    return nextState;
  }
};

// ------------------------------------
// Reducer
// ------------------------------------
export const initialState = {
  dropdownLength: 0,
  selectionIndex: -1
};
export default function contactModalReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
