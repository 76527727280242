import {
  SETTINGS_TAB_TOGGLE,
  EDIT_PROFILE_NAME,
  SETTINGS_AUDIO_MUTED,
  SETTINGS_VIDEO_MUTED
} from 'constants/SettingsModal';

export function handleChangeTab(tabLabel) {
  return {
    type: SETTINGS_TAB_TOGGLE,
    payload: tabLabel
  };
}

export function toggleEditName(isEditing) {
  return {
    type: EDIT_PROFILE_NAME,
    payload: isEditing
  };
}

export function saveAudioMuted(toggle) {
  return {
    type: SETTINGS_AUDIO_MUTED,
    payload: toggle
  };
}

export function saveVideoMuted(toggle) {
  return {
    type: SETTINGS_VIDEO_MUTED,
    payload: toggle
  };
}

export default {
  handleChangeTab,
  toggleEditName,
  saveAudioMuted,
  saveVideoMuted
};
