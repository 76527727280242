import {
  authActions,
  networkSelectors,
  currentUserSelectors,
  localizationSelectors,
  nucleusConstants,
  servicesSelectors,
  userProfileSelectors
} from '@lifesize/nucleus';
import { openFullScreenModal } from 'actions/FullScreenModal';
import { resetCalendarEventSelection } from 'actions/Calendar';
import { internalModals } from 'constants/FullScreenModal';
import { NAVIGATE_TO_TRIAL_PAGE } from 'constants/Application';
import urlUtils from 'utils/UrlUtil';
import lsConfig from 'utils/global-config';
import _get from 'lodash/get';
import { END_ROUTE_LOADING } from 'constants/SideNav';
import { selectCurrentPath } from 'selectors/auth';

/**
 * The routing middleware is where we should either intercept navigation actions
 * and handle dispatching new actions if necessary (for instance, navigating
 * away from the call route, we need to kill the call.)
 * We also should intercept other actions here that we want to respond to to emit
 * navigation actions.
 */
const routingMiddleware = (store) => (next) => (action) => {
  switch (action.type) {
    case NAVIGATE_TO_TRIAL_PAGE:
      urlUtils.loadTrialPage(action.payload);
      if (window.desktopApp) {
        store.dispatch(authActions.logout());
        window.location.search = '';
      }
      break;
    case END_ROUTE_LOADING:
      store.dispatch(resetCalendarEventSelection());
      break;
    /* eslint-disable no-case-declarations */
    case nucleusConstants.WAMP_CONNECTION_DISPOSED_JWT_ERROR:
    case nucleusConstants.WAMP_CONNECTION_DISPOSED_UNEXPECTED:
      const result = next(action);
      const state = store.getState();
      const willRetry = servicesSelectors.selectWillRetry(state);
      const verifyingConnection = servicesSelectors.selectVerifyingConnection(state);
      const isOnline = networkSelectors.isOnline(state);
      const reload = !currentUserSelectors.isUnauthorized(state) && !willRetry && !verifyingConnection && isOnline;
      const isGuest = userProfileSelectors.selectIsGuest(state);
      const inJoinFlow = _get(state, 'app.joinModal.isLoggingIn') && !isGuest;

      if (reload && !inJoinFlow) {
        let endRoute = _get(state, 'app.jwt.lastRoute');
        if (endRoute === '/') {
          endRoute = selectCurrentPath(store.getState());
        }
        const loginLanguage = _get(localizationSelectors.localization(state), 'language');

        lsConfig.skipOnBeforeUnload();
        if (isGuest) {
          store.dispatch(openFullScreenModal(internalModals.RELOAD_MODAL));
        } else {
          urlUtils.loadUrl(
            urlUtils.getLoginDomain(endRoute ? `${urlUtils.getOrigin()}${endRoute}` : null, loginLanguage)
          );
        }
      }
      return result;
    /* eslint-enable no-case-declarations */
    default:
  }
  return next(action);
};

export default routingMiddleware;
