import { IN_CALL_MODAL_ACTION_CLOSE, IN_CALL_MODAL_ACTION_OPEN, DESKTOP_REQUEST_MINIMIZE } from 'constants/InCallModal';
import { nucleusConstants } from '@lifesize/nucleus';
import _get from 'lodash/get';

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [nucleusConstants.GALAXY_ACTION_END]: (state, action) => {
    const nextState = Object.assign({}, state);
    if (_get(action, 'payload.reason') !== 'redirectToLegacy') {
      // CNUC 'ends' the galaxy call during a redirect - should be safe to remove when PSEMS is gone
      nextState.visibleComponent = '';
      nextState.props = {};
    }
    return nextState;
  },
  [IN_CALL_MODAL_ACTION_CLOSE]: (state) => {
    const nextState = Object.assign({}, state);
    nextState.visibleComponent = '';
    nextState.props = {};
    return nextState;
  },
  [IN_CALL_MODAL_ACTION_OPEN]: (state, action) => {
    const subComponentType = action.payload.subComponentType;
    if (!subComponentType) return state;
    const nextState = Object.assign({}, state);
    nextState.visibleComponent = subComponentType;
    nextState.props = action.payload.props;
    return nextState;
  },
  [DESKTOP_REQUEST_MINIMIZE]: (state) => {
    const nextState = Object.assign({}, state);
    nextState.visibleComponent = '';
    nextState.props = {};
    return nextState;
  }
};

// ------------------------------------
// Reducer
// ------------------------------------

export default function modalReducer(state = {}, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
