import localChatActions from 'actions/Chat';
import { CONTACT_MODAL } from 'constants/ContactModal';
import appCallActions from 'actions/Call';
import { openFullScreenModal } from 'actions/FullScreenModal';
import {
  SCHEDULE_WITH,
  CONTACTS_FILTER_CHANGE,
  SET_DELETING_STATE,
  SET_DELETING_ERROR,
  SET_LOADING_MEETINGS,
  SET_LOADING_ROOMS,
  SET_LOADING_USERS,
  SET_INITIAL_MEETINGS_LOADED,
  SET_INITIAL_ROOMS_LOADED,
  SET_INITIAL_USERS_LOADED
} from 'constants/Contact';
import { callActions, chatActions, directoryActions, currentUserSelectors } from '@lifesize/nucleus';
import _get from 'lodash/get';
import { getConstraints } from 'utils/deviceUtils';
import { media } from '@lifesize/clients.sdk';

const INITIAL_CONTACT_LOAD_PAGE_SIZE = 100;

/**
 * Starting a video call action creator. If any client specific action chaining
 * needs to happen here, we can do it. For now, it is just a wrapper for
 * the nucleus callOutgoing.
 * @param  {object} contact This is usually a contact object, but if it's a
 * manual dial call, it will be a string.
 * @param {object} streamConfig This is the object to pass nucleus that dictates
 * the audio and video stream settings. Default is video and audio true.
 */
export function startVideoCall(contact, streamConfig) {
  return async (dispatch, getState) => {
    const state = getState();
    const mediaSettings = _get(state, 'app.mediaSettings');

    // const newStreamConfig = Object.assign(
    //   {},
    //   streamConfig,
    //   { video: true, audio: streamConfig ? streamConfig.audio : _get(state, 'app.settingsModal.defaultAudioSetting') }
    // );

    // NOTE: there is a side effect somewhere that changes a manual dial string
    // into a broken contact type. Catching that here and passing the string per
    // nucleus' api.
    let tempContact = contact;
    if (!!contact.isManual && !!contact.name) tempContact = contact.name;
    await dispatch(callActions.call(tempContact, getConstraints(mediaSettings)));

    if (mediaSettings.localAudioMuted) {
      await media.setAudioMute(true);
    }
    if (mediaSettings.localVideoMuted) {
      await media.setVideoMute(true);
    }

    dispatch(appCallActions.trackVideoCall());
  };
}

/**
 * Starting an audio call action creator. If any client specific action chaining
 * needs to happen here, we can do it. For now, it is just a wrapper for
 * the nucleus callOutgoing with extra audio only parameters being passed.
 * @param {object} streamConfig a muting state configuration passed in
 * @param  {object} contact This is usually a contact object, but if it's a
 * manual dial call, it will be a string.
 */
export function startAudioCall(contact, streamConfig) {
  return async (dispatch) => {
    await dispatch(startVideoCall(contact));
    await media.setVideoMute(true);
    dispatch(appCallActions.trackAudioCall());
  };
}

export const clearDirectoryLoaded = () => async (dispatch) => {
  dispatch({ type: SET_INITIAL_USERS_LOADED, payload: false });
  dispatch({ type: SET_INITIAL_ROOMS_LOADED, payload: false });
  dispatch({ type: SET_INITIAL_MEETINGS_LOADED, payload: false });
};

export const loadInitialMeetings = () => async (dispatch, getState) => {
  if (_get(getState(), 'app.contacts.initialMeetingsLoaded')) return;
  dispatch({ type: SET_LOADING_MEETINGS, payload: true });
  try {
    await dispatch(directoryActions.fetchNext(['MEETING'], INITIAL_CONTACT_LOAD_PAGE_SIZE));
    dispatch({ type: SET_INITIAL_MEETINGS_LOADED, payload: true });
  } finally {
    dispatch({ type: SET_LOADING_MEETINGS, payload: false });
  }
};

export const loadInitialUsers = () => async (dispatch, getState) => {
  if (_get(getState(), 'app.contacts.initialUsersLoaded')) return;
  dispatch({ type: SET_LOADING_USERS, payload: true });
  try {
    await dispatch(directoryActions.fetchNext(['USER', 'PERSONAL', 'GROUP'], INITIAL_CONTACT_LOAD_PAGE_SIZE));
    dispatch({ type: SET_INITIAL_USERS_LOADED, payload: true });
  } finally {
    dispatch({ type: SET_LOADING_USERS, payload: false });
  }
};

export const loadInitialRooms = () => async (dispatch, getState) => {
  if (_get(getState(), 'app.contacts.initialRoomsLoaded')) return;
  dispatch({ type: SET_LOADING_ROOMS, payload: true });
  try {
    await dispatch(directoryActions.fetchNext(['ROOM'], INITIAL_CONTACT_LOAD_PAGE_SIZE));
    dispatch({ type: SET_INITIAL_ROOMS_LOADED, payload: true });
  } finally {
    dispatch({ type: SET_LOADING_ROOMS, payload: false });
  }
};

export function createContact(contact) {
  return (dispatch) => {
    if (!contact) {
      if (typeof openFullScreenModal !== 'function') return;
      dispatch(openFullScreenModal(CONTACT_MODAL, { isCreate: true }));
    } else {
      if (typeof openFullScreenModal !== 'function') return;
      dispatch(openFullScreenModal(CONTACT_MODAL, { isCreate: true, contact }));
    }
  };
}

export function chatWith(contact) {
  return (dispatch) => {
    dispatch(localChatActions.setChatMode());
    dispatch(
      chatActions.enterConversation(
        contact && contact.isMeeting && !contact.vmrUUID ? { ...contact, vmrUUID: contact.uuid } : contact
      )
    );
    dispatch(localChatActions.selectConversationContactDialString(contact.dialString));
  };
}

export function contactsFilterChange(type, value) {
  return {
    type: CONTACTS_FILTER_CHANGE,
    payload: {
      type,
      value
    }
  };
}

export function scheduleWith(extension) {
  return {
    type: SCHEDULE_WITH,
    payload: {
      extension
    }
  };
}

export function setDeletingState(toggle = false) {
  return {
    type: SET_DELETING_STATE,
    payload: toggle
  };
}

export function setDeletingError(err) {
  return {
    type: SET_DELETING_ERROR,
    payload: err
  };
}

export function loadContactManually(contact) {
  return {
    type: 'CONTACT_MANUAL_LOAD',
    payload: { contact }
  };
}

export const loadMissingActiveMeeting = (store, payload) => {
  if (!payload) return;
  const { uuid, isOneTimeMeeting, moderatorUUID, ownerUUID } = payload;
  if (isOneTimeMeeting) {
    const selfUuid = currentUserSelectors.getUserUUID(store.getState());
    if (selfUuid !== moderatorUUID && selfUuid !== ownerUUID) return;
  }
  store.dispatch(directoryActions.loadContactIfNotPresent(uuid));
};

export default {
  chatWith,
  contactsFilterChange,
  createContact,
  setDeletingError,
  setDeletingState,
  startAudioCall,
  startVideoCall
};
