import { AVAILABILITY_TOGGLE, GLOBAL_SEARCH_FORM_FOCUSED } from 'constants/Header';

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [AVAILABILITY_TOGGLE]: (state) => {
    const nextState = Object.assign({}, state);
    nextState.isAvailabilityToggled = !state.isAvailabilityToggled;
    return nextState;
  },
  [GLOBAL_SEARCH_FORM_FOCUSED]: (state, action) => {
    const nextState = Object.assign({}, state);
    nextState.isFocused = action.payload;
    return nextState;
  }
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = { isAvailabilityToggled: false, isFocused: false };
export default function headerReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
