import { nucleusConstants } from '@lifesize/nucleus';
import _get from 'lodash/get';
import { acquireWebcam } from '../actions/media';

const initialState = {
  isAcquiring: false,
  selectedAudioOutputId: null
};

const ACTION_HANDLERS = {
  [acquireWebcam.pending.type]: (state) => ({ ...state, isAcquiring: true }),
  [acquireWebcam.fulfilled.type]: (state) => ({ ...state, isAcquiring: false }),
  [acquireWebcam.rejected.type]: (state, action) => {
    if (action?.error?.message === 'Could not acquire webcam, already acquiring.') {
      return state;
    }
    return { ...state, isAcquiring: false };
  },
  [nucleusConstants.SET_PRIMARY_AUDIO_OUTPUT_ID]: (state, action) => {
    const nextState = Object.assign({}, state);
    nextState.selectedAudioOutputId = action.payload;
    return nextState;
  },
  [nucleusConstants.ACQUIRE_LOCAL_PRIMARY_STREAM_BEGIN]: (state, action) => {
    const nextState = Object.assign({}, state);
    const selectedOutputId = _get(action, 'payload.primaryAudioOutputId');
    if (selectedOutputId) {
      nextState.selectedAudioOutputId = selectedOutputId;
    }
    return nextState;
  }
};

export default function MediaReducer(state = initialState, action = {}) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
