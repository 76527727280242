export const SCHEDULE_WITH = 'app/SCHEDULE_WITH';
export const CONTACTS_FILTER_CHANGE = 'app/CONTACTS_FILTER_CHANGE';

export const SET_DELETING_STATE = 'SET_DELETING_STATE';
export const SET_DELETING_ERROR = 'SET_DELETING_ERROR';

export const CONTACTS_FILTER_OPTION_ALL = 'app/CONTACTS_FILTER_OPTION_ALL';
export const CONTACTS_FILTER_OPTION_MY_CONTACTS = 'app/CONTACTS_FILTER_OPTION_MY_CONTACTS';

export const MEETINGS_FILTER_OPTION_ALL = 'app/MEETINGS_FILTER_OPTION_ALL';
export const MEETINGS_FILTER_OPTION_ACTIVE = 'app/MEETINGS_FILTER_OPTION_ACTIVE';
export const MEETINGS_FILTER_OPTION_MY_MEETINGS = 'app/MEETINGS_FILTER_OPTION_MY_MEETINGS';

export const SET_LOADING_MEETINGS = 'app/SET_LOADING_MEETINGS';
export const SET_LOADING_USERS = 'app/SET_LOADING_USERS';
export const SET_LOADING_ROOMS = 'app/SET_LOADING_ROOMS';
export const SET_INITIAL_MEETINGS_LOADED = 'app/SET_INITIAL_MEETINGS_LOADED';
export const SET_INITIAL_ROOMS_LOADED = 'app/SET_INITIAL_ROOMS_LOADED';
export const SET_INITIAL_USERS_LOADED = 'app/SET_INITIAL_USERS_LOADED';

export const REACT_INFINITE_LOAD_BEGIN_OFFSET = 2000;

export default {
  SCHEDULE_WITH,
  CONTACTS_FILTER_CHANGE,
  SET_DELETING_STATE,
  SET_DELETING_ERROR,
  CONTACTS_FILTER_OPTION_ALL,
  CONTACTS_FILTER_OPTION_MY_CONTACTS,
  MEETINGS_FILTER_OPTION_ALL,
  MEETINGS_FILTER_OPTION_ACTIVE,
  MEETINGS_FILTER_OPTION_MY_MEETINGS
};
