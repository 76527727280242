/* eslint-disable consistent-return */

// From MDN:
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Errors/Cyclic_object_value#Examples

const getCircularReplacer = () => {
  const seen = new WeakSet();
  return (key, value) => {
    if (typeof value === 'object' && value !== null) {
      if (seen.has(value)) {
        return;
      }
      seen.add(value);
    }
    return value;
  };
};

export function safeStringify(object) {
  return JSON.stringify(object, getCircularReplacer());
}

// Adapted from Douglas Crockford's cycle.js
export function decycle(object) {
  const objects = new WeakMap(); // object to path mappings

  return (function derez(value, path) {
    let oldPath; // The path of an earlier occurance of value
    let nu; // The new object or array

    if (
      typeof value === 'object' &&
      value !== null &&
      !(value instanceof Boolean) &&
      !(value instanceof Date) &&
      !(value instanceof Number) &&
      !(value instanceof RegExp) &&
      !(value instanceof String)
    ) {
      oldPath = objects.get(value);
      if (oldPath !== undefined) {
        return { $ref: oldPath };
      }

      objects.set(value, path);

      if (Array.isArray(value)) {
        nu = [];
        value.forEach((element, i) => {
          nu[i] = derez(element, `${path}[${i}]`);
        });
      } else {
        nu = {};
        Object.keys(value).forEach((name) => {
          nu[name] = derez(value[name], `${path}[${JSON.stringify(name)}]`);
        });
      }
      return nu;
    }
    return value;
  })(object, '$');
}
