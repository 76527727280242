import {
  IN_CALL_MODAL_ACTION_CLOSE,
  IN_CALL_MODAL_ACTION_OPEN,
  DESKTOP_REQUEST_MINIMIZE,
  DESKTOP_SEND_MINIMIZE
} from 'constants/InCallModal';

export function closeInCallModal(params) {
  return {
    type: IN_CALL_MODAL_ACTION_CLOSE,
    payload: params
  };
}

export function openInCallModal(subComponentType, props = {}) {
  return {
    type: IN_CALL_MODAL_ACTION_OPEN,
    payload: {
      subComponentType,
      props
    }
  };
}

export function electronRequestMinimize() {
  return {
    type: DESKTOP_REQUEST_MINIMIZE
  };
}

export function electronSendMinimize() {
  return {
    type: DESKTOP_SEND_MINIMIZE
  };
}

export default {
  closeInCallModal,
  openInCallModal,
  electronSendMinimize
};
