import { createSelector } from 'reselect';
import _get from 'lodash/get';

const getMiniplayerMode = (state) => _get(state, 'app.inCallVideo.miniplayerMode');
export const isMiniPlayerOpenSelector = (state) => _get(state, 'app.miniPlayer.isMiniPlayerOpen');

const miniPlayer = createSelector(
  [isMiniPlayerOpenSelector, getMiniplayerMode],
  (isMiniPlayerOpen, miniplayerMode) => ({
    miniplayerMode,
    isMiniPlayerOpen
  })
);

export default miniPlayer;
