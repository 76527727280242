import { callActions, chatActions, chatSelectors, nucleusConstants, servicesActions } from '@lifesize/nucleus';
import { signaling, ObservableEvent } from '@lifesize/clients.sdk';
import { Analytics } from '@lifesize/web-clients-core';
import { selectConversationContactDialString, setChatMode } from 'actions/Chat';
import { vbbManager } from 'utils/vbbManager';
import get from 'lodash/get';
import { TrackEvents } from 'utils/analytics';
import { gqlChatAvailableForCall, gqlChatContactForCall } from 'utils/chat';
import { readFromLocalStorage } from 'utils/localStorage/vbbImages';
import { LsKey } from 'utils/localStorageUtils';
import { closeFullScreenModal } from 'actions/FullScreenModal';
import { closeInCallModal } from 'actions/InCallModal';
import { featureSelectors } from '@lifesize/nucleus';
import { FLAG_VIRTUAL_BACKGROUND } from 'constants/FeatureFlags';
import { selectRecheckinAfterCall } from 'selectors/auth';
import { RECHECKIN_AFTER_CALL } from 'constants/Auth';
import { isMobile, isChrome } from 'utils/browser-utils';

// closing tab or navigating away (beforeunload > pagehide > unload). 'pagehide' is valid for IE11+
// eslint-disable-next-line no-restricted-globals
const terminationEvent = 'onpagehide' in self ? 'pagehide' : 'unload';

const onUnload = () => {
  window.lifesize.store.dispatch(callActions.end());
  // track closing of window before call gracefully terminates
  Analytics.track(TrackEvents.CallEnded, { reason: 'terminated' });
};

const callConnectedHandler = (store) => async (nextState) => {
  const state = store.getState();

  const { enabled: vbbEnabled, selection: bgSelection } = get(state, 'app.vbbSettings');
  const mediaSettings = get(state, 'app.mediaSettings');
  const bgSelectionId = get(bgSelection, 'id');
  const localVideoMuted = get(signaling.selectors.localPrimaryStream(), 'videoMuted');
  const { [LsKey.VbbDefault]: bgDefault } = readFromLocalStorage();
  const hasDefaultVbbBg = !!bgDefault; // the default background id is saved in localStorage
  const hasBackgroundSelected = bgSelectionId && bgSelectionId !== 'none';
  const vbbFeatureEnabled = featureSelectors.getFeatureFlag(state)(FLAG_VIRTUAL_BACKGROUND);
  // NOTE: we can have a non-'none' bgSelectionId but still have vbb disabled, e.g. if a default bg id is present in localStorage
  // If we have default background selected and it's non-none, enable vbb full tilt.
  // If we do not have default background selected but vbb is enabled and we have non-none bg, enable it.
  const useVbb = (hasDefaultVbbBg && hasBackgroundSelected) || (vbbEnabled && hasBackgroundSelected);

  if (nextState) {
    window.addEventListener(terminationEvent, onUnload, { capture: true });

    // When joining a call, check the background selection to see if we should enablble VBB
    if (vbbFeatureEnabled && useVbb && !localVideoMuted && !isMobile() && isChrome()) {
      await vbbManager.enableVbb(store.dispatch, mediaSettings, bgSelection);
    }
  } else {
    // It's OK to use same vbb check as enabling, because if video is muted then we've already paused vbb
    if (vbbFeatureEnabled && useVbb) {
      // If we have a default bg id saved in local storage, pause vbb. Otherwise, disable vbb.
      if (hasDefaultVbbBg) {
        // MediaSettings value will be ignored since we will not re-acquire camera per second param
        await vbbManager.pauseVbb(mediaSettings, false);
      } else {
        // This sets the "enabled" part of vbb to false, along with pausing the actual processing.
        // We know that we do not have a default vbb background stored.
        await vbbManager.disableVbb(store.dispatch, mediaSettings, false);
      }
    }

    window.removeEventListener(terminationEvent, onUnload, { capture: true });
  }
};

function joinChatOnConferenceInitiated() {
  const conferenceInfo = signaling.selectors.conferenceInfo();
  const participants = signaling.selectors.participants();
  const state = window.lifesize.store.getState();
  const dispatch = window.lifesize.store.dispatch;
  if (!gqlChatAvailableForCall(state, conferenceInfo, participants)) return;
  const chatContact = gqlChatContactForCall(state, conferenceInfo, participants);
  if (!chatContact) return;

  // check if we've already joined the conversation
  const conversation = chatSelectors.getChatForWebGenerator(state)(chatContact);
  if (conversation) return;

  // if we got here, we need to try to join the chat.
  // perform the webapp incantation
  dispatch(setChatMode());
  dispatch(chatActions.enterConversation(chatContact));
  if (chatContact.dialString) dispatch(selectConversationContactDialString(chatContact.dialString));
}

const cleanUpOnCallDisconnection = (store) => async (payload) => {
  const dispatch = store.dispatch;
  const state = store.getState();
  const { enabled: vbbEnabled } = get(state, 'app.vbbSettings');
  const mediaSettings = get(state, 'app.mediaSettings');
  const vbbFeatureEnabled = featureSelectors.getFeatureFlag(state)(FLAG_VIRTUAL_BACKGROUND);

  // If VBB is enabled, disable so that it doesn't attempt to start on next local
  // stream update but will wait for the call to be connected
  if (vbbFeatureEnabled && vbbEnabled) {
    await vbbManager.pauseVbb(mediaSettings, false);
  }

  dispatch(closeFullScreenModal());
  dispatch(closeInCallModal());

  const conferenceUuid = get(payload, 'conferenceInfo.id');

  // non-P2P should leave chat on call end, but only if non-owner... moderator support tbd.
  const nonAdhocCallLeave =
    conferenceUuid &&
    payload?.conferenceInfo?.type !== nucleusConstants.GALAXY_CONFERENCE_TYPE_ADHOC &&
    payload?.conferenceInfo?.ownerUuid &&
    payload?.conferenceInfo?.ownerUuid !== signaling.selectors.currentUser()?.uuid;

  // CA-2360: If Adhoc, only leave chat if it was an escalated call.
  const escalatedCallLeave =
    payload?.conferenceInfo?.type === nucleusConstants.GALAXY_CONFERENCE_TYPE_ADHOC &&
    payload?.conferenceInfo?.state === 'escalated';

  if (nonAdhocCallLeave || escalatedCallLeave) {
    await dispatch(chatActions.leaveConversation({ uuid: conferenceUuid }));
  }

  Analytics.track(TrackEvents.CallEnded, { reason: payload.reason });

  // CA-2092: Re-checkin if needed on call-end
  if (selectRecheckinAfterCall(state)) {
    // Clear recheckin flag, then dispatch checkin
    dispatch({ type: RECHECKIN_AFTER_CALL, payload: false });
    await dispatch(servicesActions.accountRecheckin());
  }
};

function conferenceJoined() {
  const conferenceInfo = signaling.selectors.conferenceInfo();

  // track 'conference-joined'
  Analytics.track(TrackEvents.EnterCall, { type: conferenceInfo.type });
  joinChatOnConferenceInitiated();
}

// one time conference observers
// right now, just for auto-joining chat, but could be hooked into by other things as needed during refactors
function conferenceJoinedHandler(store) {
  signaling.addObserver({ id: 'conference-joined', eventType: ObservableEvent.ConferenceJoined }, conferenceJoined);
  signaling.addObserver(
    { id: 'conference-escalated', eventType: ObservableEvent.ConferenceEscalated },
    joinChatOnConferenceInitiated
  );
  signaling.addObserver(
    { id: 'call-disconnected', eventType: ObservableEvent.CallDisconnected },
    cleanUpOnCallDisconnection(store)
  );
}

export default function enableCallObservers(store) {
  signaling.subscribeToCallConnected(callConnectedHandler(store));
  conferenceJoinedHandler(store);
}
