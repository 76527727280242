import { directoryActions } from '@lifesize/nucleus';

import { ADD_PARTICIPANT_FORM } from 'constants/AddParticipantDrawer';
import { CHAT_SEARCH_FORM } from 'constants/Chat';
import { FAVORITES_FORM } from 'constants/Favorites';
import { GLOBAL_SEARCH_FORM } from 'constants/Header';
import { LECTURER_SEARCH_FORM, MODERATOR_SEARCH_FORM } from 'constants/ContactModal';
import { SCHEDULE_FORM } from 'constants/ScheduleModal';
import { START_CALL_FORM } from 'constants/StartCallModal';

const REDUX_FORM_CHANGE = '@@redux-form/CHANGE';
const REDUX_FORM_RESET = '@@redux-form/RESET';
const REDUX_FORM_DESTROY = '@@redux-form/DESTROY';

const searchFormList = [
  ADD_PARTICIPANT_FORM,
  CHAT_SEARCH_FORM,
  FAVORITES_FORM,
  GLOBAL_SEARCH_FORM,
  LECTURER_SEARCH_FORM,
  MODERATOR_SEARCH_FORM,
  SCHEDULE_FORM,
  START_CALL_FORM
];

const searchStringMiddleware = (store) => (next) => (action) => {
  if (action.meta && searchFormList.indexOf(action.meta.form) !== -1) {
    switch (action.type) {
      case REDUX_FORM_CHANGE:
        store.dispatch(directoryActions.search(action.payload));
        break;
      case REDUX_FORM_RESET:
      case REDUX_FORM_DESTROY:
        store.dispatch(directoryActions.search(''));
        break;
      default:
    }
  }
  return next(action);
};

export default searchStringMiddleware;
