import { defineMessages } from 'react-intl';
import _get from 'lodash/get';
import { currentUserSelectors, nucleusConstants as c } from '@lifesize/nucleus';
import { setPillNotificationWithTimeout } from '../actions/Notification';
import { messagesSelector } from '../observers/utils/intl';
import { audioUnmuteByUser } from '../actions/Call';
import { selectors } from '@lifesize/clients.sdk';

const messages = defineMessages({
  allModeratorsMuted: {
    id: 'notification.allModeratorsMuted',
    defaultMessage: 'A moderator has muted all participants. As a moderator, you may unmute yourself.'
  },
  moderatorMutedByModerator: {
    id: 'notification.moderatorMutedByModerator',
    defaultMessage: 'Your microphone has been muted. As a moderator, you may unmute yourself.'
  },
  unlockAll: {
    id: 'notification.unlockAll',
    defaultMessage: 'The moderator has unmuted your microphone.'
  }
});

const conferenceAudio = (store) => (next) => (action) => {
  const state = store.getState();
  const serverAudioMuteState = selectors.serverAudioMuteState();
  const isModerator = selectors.isCurrentUserModerator();
  const myUuid = currentUserSelectors.getUserUUID(state);
  const payload = action.payload;
  const initiatedByUuid = _get(payload, 'initiatedBy.uuid');
  const isAudioUnmutedByUser = _get(state, 'app.call.audioUnmuteByUser');
  const pillMessagesSelector = (state) => messagesSelector(state, messages);
  const pillMessages = pillMessagesSelector(state);

  switch (action.type) {
    case c.GALAXY_ACTION_CONFERENCE_MUTED:
      // don't show any notification, if current user has initiated the server mute action,
      if (myUuid === initiatedByUuid || !isModerator) return next(action);
      /*
         when muteAll action is fired, two notifications will be fired.
         one from this middleware and other from [muteNotificationHandler](src/observers/notification.js #121).
         this notification will be dispatched with timeout in order to make sure that other moderator sees moderator name who initiated the mute all action.
      */
      setTimeout(() => {
        store.dispatch(setPillNotificationWithTimeout(pillMessages.allModeratorsMuted));
      }, 50);
      break;
    case c.GALAXY_ACTION_PARTICIPANT_MUTED:
      // check if current user is moderator and compare participant uuid against current user uuid.
      if (isModerator && payload.participant.uuid === myUuid) {
        // make sure local state is update whenever participant is muted.
        // store.dispatch(streamActions.setAudioMuteState(true));
        store.dispatch(setPillNotificationWithTimeout(pillMessages.moderatorMutedByModerator));
      }
      if (isAudioUnmutedByUser) store.dispatch(audioUnmuteByUser(false));
      break;
    case c.GALAXY_ACTION_CONFERENCE_UNMUTED:
      if (myUuid === initiatedByUuid || !isModerator || !serverAudioMuteState) return next(action);
      store.dispatch(setPillNotificationWithTimeout(pillMessages.unlockAll));
      break;
    case c.GALAXY_ACTION_PARTICIPANT_UNMUTED:
      // if(!serverAudioMuteState && isModerator) return next(action);
      if (payload.participant.uuid === myUuid && !isAudioUnmutedByUser) {
        store.dispatch(setPillNotificationWithTimeout(pillMessages.unlockAll));
      }
      break;
    default:
      break;
  }
  return next(action);
};

export default conferenceAudio;
