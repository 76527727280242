import { currentUserSelectors, authSelectors } from '@lifesize/nucleus';
import { getEcommerceAPIUrl } from 'utils/UrlUtil';
import { logger } from 'utils/logger';
import { Ecommerce } from '@lifesize/types';

const displayUpgradeButton = async (state) => {
  const myInfo = currentUserSelectors.myInfo(state);
  const accountStatus = state?.nucleus?.account?.status;
  const isSuperUser = !!myInfo.isSuperUser;
  if (!isSuperUser || accountStatus === 'TRIAL') {
    return false;
  }
  const jwt = authSelectors.getPrimaryJwt(state);
  const url = getEcommerceAPIUrl(jwt);
  try {
    const response = await fetch(url, { credentials: 'include' });
    const account: Ecommerce.Account = await response.json();
    return account.isEcommerceSupported;
  } catch (error) {
    logger.warn('Failed GET for ecommerce plan API: ', error.message);
    return false;
  }
};

export default { displayUpgradeButton };
