import { createSelector } from 'reselect';
import { selectors } from '@lifesize/clients.sdk';
import _get from 'lodash/get';

export const mediaSelector = createSelector(
  (state) => _get(state, 'app.media', {}),
  (mediaState) => mediaState
);

export const vbbSettingsSelector = createSelector(
  (state) => _get(state, 'app.vbbSettings', {}),
  (vbbSettings) => vbbSettings
);

export const isAcquiringSelector = createSelector(
  () => selectors.media(),
  mediaSelector,
  (sdkMediaState, mediaState) => sdkMediaState.acquiring || (mediaState.isAcquiring)
);
