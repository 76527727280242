import { openInCallModal } from 'actions/InCallModal';
import { AV_SETTINGS } from 'constants/AvSettingsModal';
import {
  IN_CALL_DRAWER_ACTION_CLOSE,
  IN_CALL_DRAWER_ACTION_OPEN,
  IN_CALL_DRAWER_ACTION_TOGGLE,
  IN_CALL_DRAWER_CHAT_SCROLL_POSITION,
  REMOTE_GROUP_UUID_REQUEST,
  REMOTE_GROUP_UUID_SUCCESS,
  REMOTE_GROUP_UUID_FAILURE
} from 'constants/InCall/InCallDrawer';

/**
 * Function that will create an action to set the chatScrollBookmark property.
 * Previous in-call chat bookmarks are discarded on each new call connection
 * @return {Object}
 */
export function bookmarkChatScrollPosition(conversationContactDialString, settings) {
  return {
    type: IN_CALL_DRAWER_CHAT_SCROLL_POSITION,
    payload: {
      conversationContactDialString,
      settings
    }
  };
}
/**
 * Function that will create an action to set the subComponentType property to null.
 * @return {Object}  Action object with type of IN_CALL_DRAWER_ACTION_CLOSE.
 */
export function closeInCallDrawer() {
  return {
    type: IN_CALL_DRAWER_ACTION_CLOSE
  };
}
/**
 * This action will toggle the visibleComponent state with the subComponentType.
 * @param  {String} subComponentType The type of subComponent to be put in the
 * incall drawer container.
 * @param {Object} drawerProps The properties to be passed in to the interior drawer component
 * @return {Object}                  Action object with a type of
 * IN_CALL_DRAWER_ACTION_TOGGLE and payload of the passed in drawerType.
 */
export function toggleInCallDrawer(subComponentType, drawerProps = null) {
  return {
    type: IN_CALL_DRAWER_ACTION_TOGGLE,
    payload: {
      subComponentType,
      drawerProps
    }
  };
}

/**
 * This action will open the visibleComponent state with the subComponentType.
 * @param  {String} subComponentType The type of subComponent to be put in the
 * incall drawer container.
 * @param {Object} drawerProps The properties to be passed in to the interior drawer component
 * @return {Object}                  Action object with a type of
 * IN_CALL_DRAWER_ACTION_OPEN and payload of the passed in drawerType.
 */
export function openInCallDrawer(subComponentType, drawerProps = null) {
  return {
    type: IN_CALL_DRAWER_ACTION_OPEN,
    payload: {
      subComponentType,
      drawerProps
    }
  };
}

export function openAVSettingsModal() {
  return openInCallModal(AV_SETTINGS);
}

export function requestRemoteGroupUuid(extension) {
  return {
    type: REMOTE_GROUP_UUID_REQUEST,
    payload: extension
  };
}

export function resetRemoteGroupUuid() {
  return {
    type: REMOTE_GROUP_UUID_SUCCESS,
    payload: ''
  };
}

export function setRemoteGroupUuid(extension) {
  return {
    type: REMOTE_GROUP_UUID_SUCCESS,
    payload: extension
  };
}

export function failRemoteGroupUuid(error) {
  return {
    type: REMOTE_GROUP_UUID_FAILURE,
    payload: error
  };
}

export default {
  bookmarkChatScrollPosition,
  closeInCallDrawer,
  openInCallDrawer,
  toggleInCallDrawer,
  openAVSettingsModal,
  requestRemoteGroupUuid,
  resetRemoteGroupUuid,
  setRemoteGroupUuid
};
