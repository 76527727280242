import { FULL_SCREEN_MODAL_ACTION_OPEN, FULL_SCREEN_MODAL_ACTION_CLOSE } from 'constants/FullScreenModal';

/**
 * Function that will create an action to set the visibleComponent property to false.
 * @return {Object}  Action object with type of FULL_SCREEN_MODAL_ACTION_CLOSE.
 */
export function closeFullScreenModal() {
  return {
    type: FULL_SCREEN_MODAL_ACTION_CLOSE
  };
}
/**
 * This action will toggle isVisible and also populate which subComponentType
 * should be held inside of the modal.
 * @param  {String} subComponentType The type of subComponent to be put in the
 * fullscreen modal container.
 * @param {Object} modalProps The properties to be passed in to the interior modal component
 * @return {Object}                  Action object with a type of
 * FULL_SCREEN_MODAL_ACTION_OPEN and payload of the passed in subComponent type.
 */
export function openFullScreenModal(subComponentType, modalProps = {}) {
  return {
    type: FULL_SCREEN_MODAL_ACTION_OPEN,
    payload: {
      subComponentType,
      modalProps
    }
  };
}

export default {
  closeFullScreenModal,
  openFullScreenModal
};
