import googleApi from 'api/google-api';
import { environmentSelectors, featureSelectors } from '@lifesize/nucleus';
import { calendarApiError } from 'actions/Calendar';
import {
  calendars,
  googleDesktopClientId,
  googleWebClientId,
  CALENDAR_READONLY_SCOPE,
  CALENDAR_EVENTS_SCOPE
} from 'constants/Calendar';
import { calendarListEvents, calendarSignedIn, calendarUser, calendarSignedOut } from 'actions/Calendar';
import { FLAG_GOOGLE_SCHEDULE_INTEGRATION } from 'constants/FeatureFlags';

export async function initializeGoogleApp(store) {
  const state = store.getState();
  const dispatch = store.dispatch;

  const isDesktop = environmentSelectors.platform(state).isDesktop;
  const clientId = isDesktop ? googleDesktopClientId : googleWebClientId;

  const googleIntegrationFeatureFlag = featureSelectors.getFeatureFlag(state)(FLAG_GOOGLE_SCHEDULE_INTEGRATION);
  const scopes = [CALENDAR_READONLY_SCOPE];

  if (googleIntegrationFeatureFlag) {
    scopes.push(CALENDAR_EVENTS_SCOPE);
  }

  try {
    await googleApi.initializeGoogleApi({
      clientId,
      scope: scopes.join(' ')
    });
    googleApi.observeAuthStatus((isSignedIn) => {
      handleAuthChange(isSignedIn, store);
    });
  } catch (error) {
    if (error !== 'initialized') {
      dispatch(calendarApiError(error, calendars.google));
    }
  }
}

export async function googleListEventsAndDispatch(store) {
  const state = store.getState();
  const isDesktop = environmentSelectors.platform(state).isDesktop;

  if (isDesktop) {
    window.desktopApp.calendarListEvents(calendars.google);
    return;
  }

  try {
    const results = await googleApi.listEvents();
    store.dispatch(calendarListEvents(results, calendars.google));
  } catch (error) {
    store.dispatch(calendarApiError(error, calendars.google));
  }
}

export const handleAuthChange = async (isSignedIn, store) => {
  const dispatch = store.dispatch;
  const state = store.getState();
  if (isSignedIn) {
    dispatch(calendarSignedIn(null, calendars.google));
    await googleListEventsAndDispatch(store);
    const user = googleApi.getEmail();
    if (user) {
      dispatch(calendarUser(user, calendars.google));
    }
  } else {
    const isDesktop = environmentSelectors.platform(state).isDesktop;
    if (!isDesktop) {
      // Desktop manages auth state on its own, and this will report a false positive
      dispatch(calendarSignedOut(calendars.google));
    }
  }
};
