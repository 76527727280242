import { FULL_SCREEN_MODAL_ACTION_OPEN } from 'constants/FullScreenModal';
import {
  SCHEDULE_MODAL,
  SCHEDULE_SELECT,
  SCHEDULE_RESET,
  SCHEDULE_INCLUDE_MESSAGE,
  SCHEDULE_FORM_FOCUSED,
  SAVED_SCHEDULE_TOGGLE,
  SAVED_SCHEDULE_MESSAGE,
  EXTENSION_NOT_FOUND,
  SCHEDULE_ONE_TIME
} from 'constants/ScheduleModal';
import _get from 'lodash/get';

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [FULL_SCREEN_MODAL_ACTION_OPEN]: (state, action) => {
    const nextState = Object.assign({}, state);
    const subComponentType = _get(action, 'payload.subComponentType');
    if (!subComponentType || subComponentType !== SCHEDULE_MODAL) return nextState;
    nextState.contactId = action.payload.modalProps;
    nextState.scheduleOneTime = false;
    return nextState;
  },
  [SCHEDULE_SELECT]: (state, action) => {
    const nextState = Object.assign({}, state);
    nextState.contactId = action.payload;
    nextState.isFocused = false;
    nextState.scheduleOneTime = false;
    return nextState;
  },
  [SCHEDULE_RESET]: (state, action) => {
    const nextState = Object.assign({}, state);
    nextState.contactId = action.payload;
    if (!action.payload) {
      window.location.hash = `${window.location.hash.split('?')[0]}?extension=${EXTENSION_NOT_FOUND}`;
      nextState.isFocused = false;
    }
    nextState.scheduleOneTime = false;
    return nextState;
  },
  [SCHEDULE_FORM_FOCUSED]: (state, action) => {
    const nextState = Object.assign({}, state);
    nextState.isFocused = action.payload;
    return nextState;
  },
  [SCHEDULE_INCLUDE_MESSAGE]: (state, action) => {
    const nextState = Object.assign({}, state);
    nextState.includeMessage = typeof action.payload === 'boolean' ? action.payload : !state.includeMessage;
    localStorage.setItem(SAVED_SCHEDULE_TOGGLE, nextState.includeMessage);
    return nextState;
  },
  [SCHEDULE_ONE_TIME]: (state) => {
    const nextState = Object.assign({}, state);
    nextState.scheduleOneTime = true;
    return nextState;
  }
};

// ------------------------------------
// Reducer
// ------------------------------------
export const initialState = {
  contactId: null,
  isFocused: false,
  includeMessage: localStorage.getItem(SAVED_SCHEDULE_TOGGLE) === 'true' || false,
  scheduleOneTime: false,
  savedMessage: localStorage.getItem(SAVED_SCHEDULE_MESSAGE) || null
};

export default function scheduleModalReducer(state = initialState, action = {}) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
