import azureApi from 'api/azure-api';
import { environmentSelectors } from '@lifesize/nucleus';
import { calendarSignedIn, calendarApiError, outlookListEventsAndDispatch } from 'actions/Calendar';
import _get from 'lodash/get';
import { calendars } from 'constants/Calendar';

// The azure API needs to be initialized on load to handle redirect actions
export async function initializeAzureApp(store) {
  const state = store.getState();
  const dispatch = store.dispatch;

  const isDesktop = environmentSelectors.platform(state).isDesktop;

  if (isDesktop) {
    return;
  }

  try {
    const auth = await azureApi.getAccessToken();
    const token = _get(auth, 'accessToken');
    if (token) {
      dispatch(calendarSignedIn(token, calendars.outlook));
      dispatch(outlookListEventsAndDispatch(token));
    }
  } catch (error) {
    dispatch(calendarApiError(error, calendars.outlook));
  }
}
