import {
  LONG_TIMEOUT,
  VERY_LONG_TIMEOUT,
  SET_PILL_NOTIFICATION,
  DELETE_PILL_NOTIFICATION,
  SET_TOOLTIP_NOTIFICATION,
  CLEAR_TOOLTIP_NOTIFICATION,
  SET_INCALL_NOTIFICATION,
  CLEAR_INCALL_NOTIFICATION
} from 'constants/Notification';

/**
 * Remove the pill notification message
 * @return {Object} Action object with a type of DELETE_PILL_NOTIFICATION
 */
export function deletePillNotification() {
  return {
    type: DELETE_PILL_NOTIFICATION
  };
}

/**
 * Add a pill notification message
 * @param {String} message The string to be stored as the pill notification message.
 * @return {Object} Action object with a type of SET_PILL_NOTIFICATION
 * and payload of message string.
 */
export function setPillNotification(message) {
  return {
    type: SET_PILL_NOTIFICATION,
    payload: {
      message
    }
  };
}

/**
 * A convenience function that will set and then delete the pill notification message
 * after a set time. Times are constants of SHORT_TIMEOUT, MEDIUM_TIMEOUT, LONG_TIMEOUT, and
 * VERY_LONG_TIMEOUT.
 * @param {String} message The string to be stored as the pill notification message.
 * @param {number} timeout Default value is VERY_LONG_TIMEOUT. Constant number value in millisec.
 */
let deletePillTimeout = null;
export function setPillNotificationWithTimeout(message, timeout = VERY_LONG_TIMEOUT) {
  return (dispatch) => {
    if (deletePillTimeout) {
      // if pill notification already displayed, clear the timeout so that the new
      // notification is displayed for the full length of time.
      clearTimeout(deletePillTimeout);
    }
    dispatch(setPillNotification(message));
    deletePillTimeout = setTimeout(() => {
      dispatch(deletePillNotification());
      deletePillTimeout = null;
    }, timeout);
  };
}

const tooltipNotificationTimeout = {};

export const setTooltipNotification = (tooltipId, messageObj, timeout = LONG_TIMEOUT) => {
  return (dispatch) => {
    clearTimeout(tooltipNotificationTimeout[tooltipId]);
    dispatch({
      type: SET_TOOLTIP_NOTIFICATION,
      payload: {
        tooltipId,
        messageObj
      }
    });
    tooltipNotificationTimeout[tooltipId] = setTimeout(() => {
      dispatch(clearTooltipNotification(tooltipId));
    }, timeout);
  };
};

export const clearTooltipNotification = (tooltipId) => {
  clearTimeout(tooltipNotificationTimeout[tooltipId]); // in the event clearTooltipNotification is called before the timeout
  return {
    type: CLEAR_TOOLTIP_NOTIFICATION,
    payload: {
      tooltipId
    }
  };
};

/**
 * close the inCall notification message
 * @return {Object} Action object with a type of CLEAR_INCALL_NOTIFICATION
 */
export const clearInCallNotification = () => {
  return {
    type: CLEAR_INCALL_NOTIFICATION
  };
};

/**
 * open the inCall notification message
 * @param {String} message The string to be stored as the inCall notification message.
 * @return {Object} Action object with a type of SET_INCALL_NOTIFICATION
 * and payload of message string.
 */
export const setInCallNotification = (message) => {
  return {
    type: SET_INCALL_NOTIFICATION,
    payload: {
      message
    }
  };
};

/**
 * A convenience function that will set and then delete the inCall notification message
 * after a set time. Times are constants of SHORT_TIMEOUT, MEDIUM_TIMEOUT, LONG_TIMEOUT, and
 * VERY_LONG_TIMEOUT.
 * @param {String} message The string to be stored as the inCall notification message.
 * @param {number} timeout Default value is VERY_LONG_TIMEOUT. Constant number value in millisec.
 */
let inCallNotificationTimeout = null;
export const setInCallNotificationWithTimeout = (message, timeout = VERY_LONG_TIMEOUT) => {
  return (dispatch) => {
    if (inCallNotificationTimeout) {
      // if an inCall notification is already displayed, clear the timeout so that the new
      // notification is displayed for the full length of time.
      clearTimeout(inCallNotificationTimeout);
    }
    dispatch(setInCallNotification(message));
    inCallNotificationTimeout = setTimeout(() => {
      dispatch(clearInCallNotification());
      inCallNotificationTimeout = null;
    }, timeout);
  };
};
